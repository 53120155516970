
.meta-settings {
    display: flex;
    background-color: #161616;
    min-height: 100vh;
}




.settings-container {
    display: flex;
    background-color: #1e1e1e;
    color: white;
    width: 100%; /* Use 100% width for full responsiveness */
    height: 100%;
    max-width: 900px;
    margin: 3% auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0.5% 1% rgba(0, 0, 0, 0.1);
    border: 1px solid #3e3e3e;
}

.settings-sidebar {
    width: 25%; /* Adjust if necessary for smaller screens */
    background-color: #222;
    padding: 20px;
}

.sidebar-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar-item.active {
    background-color: #555;
    font-weight: bold;
    box-shadow: 0 0.5% 1% rgba(0, 0, 0, 0.1);
}

.settings-content {
    flex: 1; /* Allows content to fill the remaining space */
    padding: 2%;
    overflow-y: auto;
}

.sidebar-icon {
    margin-right: 6px;
    width: 35px;
    height: 35px;
}

/* Profile and User details layout */

    /* GENERAL SECTION */
    /* GENERAL SECTION */
    /* GENERAL SECTION */
    /* GENERAL SECTION */

    
  .general-content {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  gap: 30px;
}

.profile-section {
    flex: 0 0 auto;
    min-width: 170px; /* Minimum width before wrapping */
    position: relative;
    padding-right: 5px; /* Decreased padding to reduce space */
    margin-left: 20px;
}

.profile-image-container {
    position: relative;

}

.profile-image {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border: 3px solid #fff;

}

.update-button-uploading {
    position: absolute;
    bottom: 5px; /* Position from the bottom of the profile image container */
    right: 3px; /* Position from the right of the profile image container */
    background-color: #0e0e0e;
    border: none;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for transform effects */
    padding: 8px;
    border-radius: 50%;
    opacity: 0.78;
}

.update-button-uploading:hover {
    transform: scale(1.05); /* Increase size by 10% */
    opacity: 1.0;

}

.button-image {
    width: 18px; /* Width of the button image */
    height: 100%; /* Height of the button image */
    /* If you need to center the image within the button or have specific alignment, add here */

}


.user-details {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align all children to the left */
  min-width: 250px; /* Keep the same minimum width */
  width: 100%; /* Ensure it takes full width available */
  gap: 22px;
}

  .email-section-wrapper {
    width: 100%; /* Adjust the width */
  }

.email-section {
  border-radius: 5px;
  padding: 10px;
  background-color: #1e1e1e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05);
  border: 1px solid #3e3e3e;
  display: flex;
  align-items: center;
}


.email-text {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.email-bumpups-logo {
  height: 22px; /* Adjust the height as needed */
  margin-right: 7px;
  width: auto;
  vertical-align: middle; /* Aligns the logo vertically with the text */
  flex-shrink: 0; /* Prevent the logo from shrinking */
}

.email-text-wrapper {
  max-width: 300px; /* Set a maximum width to constrain the text */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow */
  white-space: nowrap; /* Prevent the text from breaking to the next line */
  display: flex;
  justify-content: center;
}


.plan-container {
  display: flex;
  flex-direction: row; /* Stack elements horizontally */
  align-items: center; /* Center align vertically */
  justify-content: flex-start; /* Aligns the content to the left */
  gap: 16px; /* Space between elements */
}


.main-plan-text {
  display: flex;
  align-items: center; /* Aligns the text and icon vertically */
  font-weight: bold;
  font-size: 18px;
}

  .plan-icon {
    width: 32px; /* Example size, adjust as needed */
    height: 32px; /* Example size, adjust as needed */
    margin-right: 7px;
  }

  .plan-button {
    border-radius: 8px;
    padding: 10px 15px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); /* subtle shadow */
    border: 0.5px solid #3e3e3e;

}

  .plan-button:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* slightly more pronounced shadow on hover */
  }


  .default-lang-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center vertically */
    justify-content: flex-start; /* Align items to the left */
    gap: 16px; /* Space between the text/icon and the dropdown */
  }
  
  .default-lang-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically align the icon and text */
  }
  
  
  
  .language-icon {
    width: 34px;
    height: 34px;
    margin-right: 7px;
  }

  .lang-dropdown-save-wrapper {
    display: flex;
    align-items: center; /* Align vertically centered */
    gap: 10px; /* Space between the dropdown and button */
}

  .selected-language-text {
    font-weight: bold;
    font-size: 18px;
    text-align: left;

  }



  .manage-lang-dropdown {
    padding: 10px 15px;
    background-color: #373737;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border: 0.5px solid #3e3e3e;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }


    .save-lang-button {
      border-radius: 8px;
      padding: 10px 15px;
      background-color: #272727;
      color: white;
      border: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
      border: 0.5px solid #3e3e3e;
  }

  .save-lang-button:hover {
      background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
  }


  .save-lang-button:disabled {
      opacity: 0.5;
      cursor:default;
      pointer-events: none;
  }

  .save-lang-button:hover:not(:disabled) {
    background-color: #777777; /* Adjust hover color to match UI */
  }
  
  .mainsettings-save-lang-wrapper {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between the button and info icon */
  }
  
  
  .mainsettings-info-logo {
    width: 20px; /* Adjust the size of the info icon */
    height: 20px;
    cursor: pointer;
  }
  .mainsettings-info-logo:hover {
    transform: scale(1.2); /* Slightly increase size on hover */
}

    /* CREATOR SECTION */
    /* CREATOR SECTION */
    /* CREATOR SECTION */
    
    .settings-action {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start; /* Align items to the start */
        padding: 15px; 
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(63, 65, 67); 
      }
      
      .settings-action:last-child {
        border-bottom: none;
      }
      
      
      .icon-text-container {
        display: flex;
        align-items: center;
        margin-right: auto; /* Pushes all elements to the right */
      }

      .action-text-wrapper {
        display: flex;
        align-items: center; /* Vertically align the text and icon */
    }
    
      .action-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      .settings-utube-connect-button {
        background-color: #53565b; /* Neutral color suitable for 'connect' action */
        padding: 12px 15px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.0rem;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
      }
      
      .settings-utube-connect-button.connected {
        cursor: default;
        background-color: #ffffff; /* Dark gray background */
    } 

      .settings-utube-connect-button:hover {
 
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

        }
        .settings-create-connectyoutube {
          width: 32px;
          height: 23px;
        }



      .manage-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #181818; /* Dark color for a neutral look */
        padding: 10px 10px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.0rem;
        cursor: pointer;
        margin-right: 5px; /* Adjust the spacing as needed */
        transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover and shadow */
        width: 40px;
        height: 40px;
      }
      
      .manage-button img {
        max-width: 30px;
        max-height: 30px;
    }
    

      .manage-button:hover {
        background-color: #272727; /* Slightly lighter for hover effect */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* More pronounced shadow on hover */
      }
      
      .manage-button:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); /* Soft white ring for focus */
      }
      
      .manage-button:active {
        background-color: #0f0f0f; /* Even darker for a pressed effect */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Revert to subtle shadow */
      }
      

      

      /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 52px;  /* Adjust width as needed */
    height: 26px;  /* Adjust height as needed */
    margin-left: 10px;
    
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ccc; /* Slider off color */
    transition: .4s;
    border-radius: 26px; /* Circular edges */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;  /* Adjust knob size as needed */
    width: 22px;  /* Adjust knob size as needed */
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;  /* Circular knob */
  }
  
  input:checked + .slider {
    background-color: #4add80; /* Slider on color */
  }
  
  input:checked + .slider:before {
    transform: translateX(26px); /* Adjust translation based on the size of the switch */
  }


  
  .disabled-section {
    opacity: 0.5; /* Grey out the section */
}

.disabled-section > *:not(.switch) {
    pointer-events: none; /* Disable interactions for all children except switch */
}

  
  .slider-tooltip-container {
    position: relative;
    display: inline-block;
}

.slider-tooltip-text {
    visibility: hidden;
    width: 230px;
    background-color: #181818;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-80%);
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: bold;
    font-size: .9rem;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);

}

.tooltip-icon {
    width: 20px;  /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    vertical-align: middle; /* Aligns the icon with the text */
}

.switch:hover .slider-tooltip-text {
    visibility: visible;
    opacity: 1;
}
  
.connect-text-utube {
    font-weight: bold;
    font-size: 15px;
  }
.connected-button {
    background-color: #40cc74;
}

.connected-button:hover {
    background-color: #278047; /* Slightly darker than #53565b */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  }

  .revoke-button {
    background-color: #181818;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .revoke-button img {
    height: 20px; /* Adjust size as needed */
    width: auto;
  }
  
  .revoke-button:hover {
    background-color: #272727; /* Slightly lighter for hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
  }
  
  .revoke-button:active {
    background-color: #0f0f0f; /* Even darker for active effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.); /* Subtle shadow for active state */
  }



    /* TIMESTAMP SECTION */
    /* TIMESTAMP SECTION */
    /* TIMESTAMP SECTION */

    .timestamp-length-buttons {
      display: flex;
      align-items: center;
      background-color: #2c2c2c; /* Light background to contrast the buttons */
      padding: 5px; /* Padding around buttons for spacing */
      border-radius: 5px; /* Rounded corners for the container */
    }
    
    .timestamp-button {
      padding: 12px 20px; /* Comfortable padding */
      font-size: 0.95em; /* Optimal font size for readability */
      font-weight: 600; /* Bold font for clarity */
      border: none; /* Remove borders */
      background-color: #272727; /* Neutral button color */
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for interactive feedback */
      margin: 0 2px; /* Spacing between buttons */
      border-radius: 5px; /* Rounded buttons */
      outline: none; /* Remove focus outline */
      color: #333; /* Dark text for contrast */
      opacity: 0.6;
      color: white;
    }
    
    .timestamp-button:hover {
      background-color: #d1d1d1; /* Slightly darker on hover */
      transform: translateY(-2px); /* Subtle lift effect on hover */
    }
    
    .timestamp-button.selected {
      background: #ffffff; /* Gradient based on base color #0ca37e */
      color: #161616; /* White text for contrast */
      box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Shadow for depth, indicating selection */
      opacity: 1;
      border: 1px solid #3e3e3e;
    }
    
    
    
    .info-icon {
      width: 24px;
      height: 24px;
      transition: transform 0.2s; /* Animation effect */
      margin-left: 5px;
    }
    
    .info-icon:hover {
      transform: scale(1.1); /* Increase the size by 10% on hover */
    }

    .action-text {
      font-weight: bold;
      font-size: 16px;
      text-align: left;
    }

    /* BUMPSETTINGS SECTION */

    .editBump-button-group {
      display: flex;
      gap: 10px; /* Adjust the spacing between buttons as needed */
      margin-top: 20px; /* Adjust the spacing above the button group as needed */
    }
    
    .seetingBump-section-input-group {
      margin-bottom: 30px;
      width: 100%;
    }

    .settingBump-section-description-label {
      font-size: 20px;
      font-weight: bold;
      text-align: left;
    }


/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {

    .meta-settings {
        display: flex;
        flex-direction: row;
      }
    
      .settings-container {
        width: 80%;
        margin: 20px auto; 
        flex-direction: column;

      }
    
      .settings-sidebar {
        width: 100%;
        padding: 2%;
      }
    
      .sidebar-item {
        padding: 8px;
      }
    
      .settings-content {
        width: 100%;
        margin: 0px;
      }
    
      .sidebar-icon {
        width: 25px;
        height: 25px;
      }
    
      .general-content {
        flex-direction: column;
      }
    
      .profile-section {
        margin-top: 20px;
        margin-left: 0; /* Remove left margin */
        margin-bottom: 10px; /* Add bottom margin for spacing */
    }

    .profile-image-container {
        width: auto; /* Allow the container to resize */
    }

    .update-button-uploading {
        bottom: 0px; /* Adjust position for smaller screens */
        right: 0px; /* Adjust position for smaller screens */
    }
      .email-section {

          width: 90%;
      }
      
      .button-image {
        width: 15px;
        height: 15px;
      }
    
  

      .plan-container {

        padding: 5px;
      }

      .main-plan-text
       {
        font-size: 16px;
      }

      .plan-button
      {
       font-size: 18px;
     }
    
      .email-text,
      .connect-text-utube,
      .action-icon,
      .connect-button,
      .manage-button,
      .slider-label {
        font-size: 17px;
      }
    
      .manage-button {
        padding: 5px;

      }


      .settings-action {
        padding: 10px 30px 10px 10px;
      }
    
      
      .plan-icon {
        width: 24px;
        height: 24px;
      }
  

      .connect-button,
      .revoke-button {
        padding: 10px 15px;
        font-size: 0.7rem;
      }


      .tooltip-icon {
        width: 15px;
        height: 15px;
      }
    
  
      .settings-utube-connect-button,
      .settings-utube-connect-button.connected 
      {

        padding: 9px 9px;

      }

        .settings-create-connectyoutube {
          width: 26px;
          height: 19px;
        }

        
      .timestamp-length-buttons {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center; /* Center-align buttons */
      }
    
      .timestamp-button {
        width: 100%; /* Increase button width to take up more space */
        margin: 5px 0; /* Adjust margin for vertical stacking */
        font-size: 12px; /* Optimal font size for readability */
      }
      .action-text {
        
        font-size: 14px;
      }
      .manage-dropdown {
        font-size: 14px;
      }

      .info-icon {
        width: 16px;
        height: 16px;
      }



        /* I will need to make CSS speicifc classes for the settings of this so doesnt conflict */

      .editBump-button-group {
        flex-direction: column;
        margin-top: 20px; /* Adjust the spacing above the button group as needed */
      }
      
      .settingBump-section-input-group {
        width: 95%;
        margin-bottom: 20px;
        margin-top: 5px;
      }

      .settingBump-section-description-label {
        font-size: 16px;
        margin-left: 5px;
      }

      .selected-language-text {
        font-size: 13px;
      }

      .manage-lang-dropdown {
        padding: 10px 13px;
        font-size: 13px;
      }
    
    
        .save-lang-button {
          padding: 10px 13px;
          font-size: 13px;
      }

      .mainsettings-tooltip-text {
        width: 130px;
        font-size: 13px;
        bottom: 145%; /* Position above the info icon */
        left: 50%;
        transform: translateX(-70%);
      }
      .user-details {
        align-items: center;
      }

}



@media (min-width: 769px) and (max-width: 1280px) {

  .meta-settings {
    display: flex;
    flex-direction: row;
  }

  .settings-container {
    width: 80%;
    margin: 20px auto; 
    flex-direction: column;

  }

  .settings-sidebar {
    width: 100%;
    padding: 2%;
  }

  .sidebar-item {
    padding: 8px;
  }

  .settings-content {
    width: 100%;
    margin: 0px;
  }

  .sidebar-icon {
    width: 25px;
    height: 25px;
  }

  .general-content {
    flex-direction: column;
  }

  .profile-section {
    margin-top: 20px;
    margin-left: 0; /* Remove left margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
}

.profile-image-container {
    width: auto; /* Allow the container to resize */
}

.update-button-uploading {
    bottom: 0px; /* Adjust position for smaller screens */
    right: 0px; /* Adjust position for smaller screens */
}
.email-section-wrapper {
  width: 380px; /* Adjust the width */
}



  .button-image {
    width: 15px;
    height: 15px;
  }

  .user-details {
    align-items: center;
  }

  .plan-container {
    padding: 5px;

  }

  .main-plan-text
   {
    font-size: 16px;
  }

  .plan-button
  {
   font-size: 18px;
 }

  .email-text,
  .connect-text-utube,
  .action-icon,
  .connect-button,
  .manage-button,
  .slider-label {
    font-size: 17px;
  }

  .manage-button {
    padding: 5px;

  }


  .settings-action {
    padding: 10px 30px 10px 10px;
  }

  
  .plan-icon {
    width: 24px;
    height: 24px;
  }


  .connect-button,
  .revoke-button {
    padding: 10px 15px;
    font-size: 0.7rem;
  }


  .tooltip-icon {
    width: 15px;
    height: 15px;
  }


  .settings-utube-connect-button,
  .settings-utube-connect-button.connected 
  {

    padding: 9px 9px;

  }

    .settings-create-connectyoutube {
      width: 26px;
      height: 19px;
    }

    
  .timestamp-length-buttons {
    flex-direction: row; /* Stack buttons vertically on smaller screens */
    align-items: center; /* Center-align buttons */
  }

  .timestamp-button {
    width: 100%; /* Increase button width to take up more space */
    margin: 5px 0; /* Adjust margin for vertical stacking */
    font-size: 12px; /* Optimal font size for readability */
  }
  .action-text {
    
    font-size: 14px;
  }
  .manage-dropdown {
    font-size: 14px;
  }

  .info-icon {
    width: 16px;
    height: 16px;
  }



    /* I will need to make CSS speicifc classes for the settings of this so doesnt conflict */

  .editBump-button-group {
    flex-direction: column;
    margin-top: 20px; /* Adjust the spacing above the button group as needed */
  }
  
  .settingBump-section-input-group {
    width: 95%;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .settingBump-section-description-label {
    font-size: 16px;
    margin-left: 5px;
  }


}