/* SIDEBAR CSS */
.sidebar {
  max-width: 320px;
  background-color: #161616; /* Dark background */
  color: #fff; /* White text */
  padding: 20px;
  box-sizing: border-box;
  border-right: 0.5px solid #3e3e3e;
  width: 100%;
}

.sidebar a {
  display: flex;
  align-items: center; /* Vertically centers the content in the line */
  justify-content: flex-start; /* Aligns items to the start of the container */
  color: #fff; /* White text */
  padding: 10px 15px; /* Add padding as needed */
  text-decoration: none;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.sidebar a:hover {
  background-color: rgba(74, 221, 128, 0.4); /* Green with 10% opacity */
}

/* Highlight for the active link, replace .active with the class you use for indicating the active page */
.sidebar .active {
  background-color: rgba(74, 221, 128, 0.4); /* Green with 10% opacity */
  border-radius: 5px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust the spacing as needed */
  font-size: 1.1em;
  font-weight: bold;
}

.link-icon {
  margin-right: 10px; /* Adds some space between the icon and text */
  width: 35px; /* Or any size that fits your design */
  height: 35px; /* Or any size that fits your design */
}

.link-icon.active-icon {
  width: 35px;
  height: 35px;
}
.sidebar .plan-status {
  margin-top: 10px;
  border-top: 0.5px solid #3e3e3e;
  padding-top: 12px;
  text-align: left;
}

.sidebar .plan-status span {
  display: block; /* Each stat on new line */
  color: #aaa; /* Subdued text color */
  margin-bottom: 5px; /* Spacing between stats */
}




.bumpupload-button {
  background-color: #1e1e1e; /* Dark background */
  color: #f3f5f7; /* White text */
  padding: 16px 24px; /* Increased padding for larger button */
  border: none; /* Remove default border */
  border-radius: 8px; /* Slightly more rounded corners */
  margin-bottom: 20px; /* Spacing after the button */
  text-align: center; /* Center text and icon horizontally */
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  text-decoration: none; /* Remove underline from links */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Slightly larger shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  cursor: pointer; /* Pointer cursor on hover */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; /* Apple-like font */
}

.bumpupload-button:hover {
  background-color: #2c2f33; /* Slightly lighter dark on hover */
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.bumpupload-button:active {
  background-color: rgba(74, 221, 128, 0.4); /* Green with 40% opacity */
  transform: translateY(-3px); /* Maintain lift on active */
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5); /* Maintain shadow on active */
}

/* Active Class for Selected State */
.bumpupload-button.active {
  background-color: rgba(74, 221, 128, 0.4); /* Green with 20% opacity for selected state */
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5); /* Distinct shadow to indicate active state */
}

/* Ensure the button retains dark background when active */
.bumpupload-button.active .bumpbutton-text {
  color: #f3f5f7; /* White text */
}

.bumpupload-content {
  display: flex;
  align-items: center; /* Center vertically */
}

.bumpbutton-text {
  font-weight: 600; /* Increased font weight for better visibility */
  font-size: 1.25em; /* Increased font size */
  margin-left: 12px; /* Space between icon and text */
  color: #f3f5f7; /* Ensure text color consistency */
  transition: color 0.3s ease; /* Smooth color transition */
}

.link-icon-bump {
  width: 32px; /* Increased icon size */
  height: 32px;
  display: block;
  margin: 0 auto; /* Center icon */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth scaling and filter transitions */
}

/* Ensure the white logo remains visible on dark background */
.bumpupload-button .link-icon-bump {
  filter: brightness(0) invert(1); /* Invert colors to make white logo visible */
}

.bumpupload-button:hover .link-icon-bump {
  transform: scale(1.1); /* Slightly enlarge icon on hover */
}

.bumpupload-button.active .link-icon-bump {
  transform: scale(1.05); /* Slightly enlarge icon to indicate active state */
}




.plan-section {
  margin-top: 50px; /* Adjust the value as needed */
}


/* Style for the section titles like 'Zaps', 'Tables', etc. */
.sidebar .section-title .languagedefault-title {
  color: #aaa; /* Subdued text color */
  margin-top: 30px; /* Spacing above the title */
  margin-bottom: 10px; /* Spacing below the title */
  font-weight: bold;
}

.section-title {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.languagedefault-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: normal; /* Normal weight by default */
  transition: font-weight 0.2s ease; /* Smooth transition for font weight change */
  cursor: pointer; /* Make it look clickable */
}

/* Hover effect to make text bold */
.languagedefault-title:hover {
  text-decoration: underline;
}




.language-link:hover {
  font-weight: bold; /* Make text bold on hover */
  text-decoration: none; /* Ensure no underline on hover */
}

.sidebar-footer-details {
  display: flex; /* Ensure flexbox is used */
  flex-direction: row; /* Align children in a row */
  justify-content: space-between; /* Adjust spacing between elements */
  align-items: center; /* Center elements vertically */

}

.sidebar .manage-plan {
  text-decoration: none; /* Remove underline by default */
  cursor: pointer;
  display: inline;
  transition: text-decoration 0.3s ease; /* Smooth transition for underline on hover */
  margin-left: 0; /* Ensures there's no margin pushing it from the left */
  padding-left: 0;
  font-size: 15px;
  color: #ffffff;
}

.sidebar .manage-plan:hover {
  text-decoration: underline; /* Underline only on hover */
  background-color: transparent; /* Override the global hover effect */
  box-shadow: none; /* Remove if there's any box-shadow applied */
}


.dividersides {
  border-bottom: 0.5px solid #3e3e3e;
  margin: 10px 0;
}

.section-icon {
  width: 25px; /* Adjust to match your design */
  height: 25px; /* Adjust to match your design */
  margin-right: 2px; /* Space between icon and title text */
  margin-left: -2px;
}

.plan-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.included-time-label {
  font-weight: bold;
}

.progress-bar {
  background-color: #333;
  border-radius: 4px;
  overflow: hidden; /* Makes sure the inner progress doesn't overflow */
  position: relative; /* Needed to position the inner progress element */
  height: 7px;
  margin-bottom: 30px;
}

.progress {
  background-color: #4add80;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.5s ease; /* Smooth transition for the progress bar */
}


/* Dropdown Toggle Styles */
.side-dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-left 0.3s;
  position: relative; /* For arrow positioning */
  border-left: 4px solid transparent; /* Default border */
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.1em;

}

/* Hover Effect */
.side-dropdown-toggle:hover {
  border-left: 4px solid #4add80; /* Adds a green left border */
  background-color: #1e1e1e; /* Slightly different background */
}

/* Active State Styles */
.side-dropdown-toggle.active {
  border-left: 4px solid #4add80; /* Adds a green left border */
  background-color: #1e1e1e; /* Slightly different background */
}

/* Arrow Icon Styles */
.dropdown-arrow {
  margin-left: auto; /* Align to the right */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff; /* White arrow */
  transition: transform 0.3s ease; /* Smooth rotation */
}

.dropdown-arrow.open {
  transform: rotate(180deg); /* Rotate when open */
}

/* Dropdown Content Styles */
.side-dropdown-content {
  display: none;
  flex-direction: column;
  margin-left: 20px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.side-dropdown-content.open {
  display: flex;
  opacity: 1;
  transform: scaleY(1);
}

