/* Container Styles */
.ex-carousel-container {
  text-align: center;
  background-color: #161616; /* Matches your site's background */
  color: #ffffff;
  margin-bottom: 10px;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.ex-carousel-title {
  font-size: 1.6em;
  margin-bottom: 30px;
  font-weight: bold;
}

.ex-highlight {
  color: #4add80;
}

/* Carousel Styles */
.ex-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

/* Carousel Inner Wrapper */
.ex-carousel-inner {
  position: relative;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  display: flex;
}

/* Carousel Items Container */
.ex-carousel-items {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Individual Carousel Item */
.ex-carousel-item {
  box-sizing: border-box;
  padding: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none; /* Ensure no border affects hover */
  outline: none;
}

/* Title Styles - Moved to Top */
.ex-item-title {
  font-size: 1em;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

/* Main Image */
.ex-item-image {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 250px;
  object-fit: cover;
  flex-shrink: 0;
  margin: 0; /* Remove margin for cleaner alignment */
  border: none; /* Ensure no border affects hover */
  /* box-shadow: 0 8px 32px rgba(0, 0, 0, 0.8); */

}

/* Overlay Image - Positioned Below the Main Image */
.ex-overlay-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 0 0 8px 8px;
  margin: 0; /* Ensure no unwanted margin */
  border: none; /* Remove any border */
  image-rendering: -webkit-optimize-contrast; /* Improve scaling rendering */
}
/* Container for the Overlay Image */
.ex-overlay-image-container {
  position: relative;
  width: 100%;
}

/* Gradient Overlay */
.ex-overlay-image-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(to top, rgba(22, 22, 22, 0.8), transparent);
  pointer-events: none;
  border-radius: 0 0 8px 8px;
}

/* Hover Effect for Carousel Item */
.ex-carousel-item:hover {
  transform: scale(1.05);
  transform-origin: center; /* Ensure scaling from the center */
  z-index: 2;
}

/* Gradient Overlays */
.ex-carousel-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  pointer-events: none;
  z-index: 3;
}

.ex-carousel-gradient-left {
  left: 0;
  background: linear-gradient(to right, #161616, rgba(22, 22, 22, 0));
}

.ex-carousel-gradient-right {
  right: 0;
  background: linear-gradient(to left, #161616, rgba(22, 22, 22, 0));
}

/* Arrow Buttons */
.ex-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.ex-arrow svg {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.ex-arrow:hover svg {
  fill: #4ADD80;
}

.ex-left-arrow {
  left: 10px;
}

.ex-right-arrow {
  right: 10px;
}

.ex-arrow:disabled {
  opacity: 0.3;
  cursor: default;
}

.ex-arrow:focus {
  outline: none;
  box-shadow: none;
}

/* Responsive Styles */

/* Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  .ex-carousel-container {
    display: none;
  }
}

/* Tablet Devices (min-width: 769px) and (max-width: 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .ex-carousel-container {
    padding: 18px;
  }

  .ex-carousel-title {
    font-size: 1.5em;
    margin-bottom: 25px;
  }

  .ex-arrow {
    padding: 9px;
  }

  .ex-arrow svg {
    width: 22px;
    height: 22px;
  }

  .ex-carousel-gradient {
    width: 50px;
  }

  .ex-item-title {
    font-size: 0.95em;
    margin-top: 9px;
    margin-bottom: 8px;
  }

  .ex-carousel-item {
    padding: 9px;
  }
}
