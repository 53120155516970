.close-sidebar {
    max-width: 80px;
    background-color: #161616; /* Dark background */
    color: #fff;
    padding: 20px 0px 20px 0px; /* Adjusted padding for consistent spacing */
    box-sizing: border-box;
    border-right: 0.5px solid #3e3e3e;
    text-align: center;
    width: 100%;
}

.close-create-button {
    background-color: #1e1e1e;
    color: #fff;
    padding: 0;
    text-align: center;
    display: block;
    border-radius: 5px;
    font-size: 1.5em;
    font-weight: bold;
    width: 50px;
    height: 50px;
    line-height: 35px;
    text-decoration: none;
    margin: 0 auto 10px; /* Auto margin for horizontal centering */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Slightly larger shadow for depth */

  }
  
  .close-create-button:hover {
    background-color: #2c2f33; /* Slightly lighter dark on hover */
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
    }

    
  
  .link-icon-bump-close {
    margin-top: 3px;
    width: 40px; /* Or any size that fits your design */
    height: 40px; /* Or any size that fits your design */
  }

.close-dividersides {
    border-bottom: 0.5px solid #3e3e3e;
    margin: 20px 0; /* Increased margin to match the top and bottom padding of sidebar */
}

.close-sidebar-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    background-color: transparent;
    width: 100%;
    height: 50px; /* Adjust height as needed */
    padding-left: 0; /* Make sure padding does not cause issues */
    padding-right: 0; /* Make sure padding does not cause issues */
}

.close-link-icon {
    transition: background-color 0.2s;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.close-link-selected {
    background-color: rgba(0, 0, 0, 0.4); /* Darkened background for the whole link */
    
}

.close-link-selected::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #45c072; /* Your green color */
}

.close-language {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  height: 100%;            /* Ensure the div takes full height for vertical centering */
}

.language-emoji {
  font-size: 2.0rem;      /* Adjust size to make the emoji larger */
  line-height: 1;         /* Adjust line height for emoji alignment */
}

.close-sidebar-link {
  text-decoration: none; /* Remove underline from all sidebar links */
}

.close-sidebar-link:hover {
  text-decoration: none; /* Ensure hover doesn't re-add underline */
}



  @media (max-width: 1280px) {
    .close-sidebar {
        width: 100px; /* Narrower sidebar for smaller screens */
        padding: 15px 0px; /* Adjust padding */
        width: 100%;
    }

    .close-create-button {
        width: 40px;
        height: 40px;
      }

      .link-icon-bump-close {
        width: 35px; /* Or any size that fits your design */
        height: 35px; /* Or any size that fits your design */
      }

      

    .close-dividersides {
        margin: 15px 0; /* Adjusted margin */
    }

    .close-sidebar-link {
        height: 40px; /* Adjust height */
    }

    .language-emoji {
      font-size: 1.8rem;      /* Adjust size to make the emoji larger */
      line-height: 1;         /* Adjust line height for emoji alignment */
    }

    /* ... other responsive adjustments ... */
}


@media (max-width: 768px) {
    .close-sidebar {
        width: 70px; /* Narrower sidebar for smaller screens */
        padding: 15px 0px; /* Adjust padding */
    
    }

    .close-create-button {
        width: 37px;
        height: 37px;
      }

      .link-icon-bump-close {
        width: 32px; /* Or any size that fits your design */
        height: 32px; /* Or any size that fits your design */
      }

      .language-emoji {
        font-size: 1.8rem;      /* Adjust size to make the emoji larger */
        line-height: 1;         /* Adjust line height for emoji alignment */
      }


  }