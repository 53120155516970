/* src/components/UserPlanPopup.css */

/* Define CSS variables for dynamic theming based on selected plan */
.base-plan {
  --amount-color: #ffbf00;
  --button-color: #ffbf00;
  --button-hover-color: #e0a800;
  --button-active-color: #c69500;
  --button-color-rgb: 255, 191, 0;
}

.bump-plan {
  --amount-color: #4add80;
  --button-color: #4add80;
  --button-hover-color: #3eb06b;
  --button-active-color: #369f68;
  --button-color-rgb: 74, 221, 128;
}

/* Overlay to darken the background */
.userplan-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup container styling */
.userplan-popup-content {
  display: flex;
  background-color: #1e1e1e;
  border-radius: 14px; /* Reduced from 16px */
  max-width: 700px; /* Further reduced from 800px */
  width: 85%; /* Reduced from 90% */
  min-height: 35vh; /* Further reduced from 40vh */
  padding: 15px; /* Further reduced from 20px */
  color: #ffffff;
  overflow: hidden;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.5); /* Slight adjustment */
  position: relative;
  flex-direction: row;
  margin: auto;
}

/* Webkit Browsers */
.userplan-popup-content::-webkit-scrollbar {
  width: 6px; /* Reduced from 8px */
}

.userplan-popup-content::-webkit-scrollbar-track {
  background: #2c2c2c;
  border-radius: 3px; /* Reduced from 4px */
}

.userplan-popup-content::-webkit-scrollbar-thumb {
  background-color: var(--button-color);
  border-radius: 3px; /* Reduced from 4px */
  border: 1px solid #2c2c2c; /* Adjusted */
}

/* Firefox */
.userplan-popup-content {
  scrollbar-width: thin;
  scrollbar-color: var(--button-color) #2c2c2c;
}

/* Close Button Styling */
.userplan-close-button {
  position: absolute;
  top: 12px; /* Reduced from 16px */
  right: 12px; /* Reduced from 16px */
  background: transparent;
  border: none;
  font-size: 24px; /* Reduced from 28px */
  color: #ffffff;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.2s ease, transform 0.2s ease;
  z-index: 1001;
}

.userplan-close-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

.userplan-close-button:focus {
  outline: none;
}

/* Left content with plans */
.userplan-left-content {
  width: 100%;
  padding: 15px; /* Further reduced from 20px */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header with title and toggle buttons */
.userplan-popup-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px; /* Further reduced from 15px */
}

.userplan-title {
  margin-bottom: 12px; /* Further reduced from 15px */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 28px; /* Further reduced from 32px */
  font-weight: bolder;
}

/* Payment Type Buttons */
.userplan-toggle-container {
  display: flex;
  align-items: center;
  gap: 15px; /* Reduced from 20px */
  width: 100%;
}

/* Styling the Discount Badge */
.userplan-payment-type-badge {
  position: absolute;
  top: -10px; /* Adjusted */
  right: -6px; /* Adjusted */
  background-color: #aa64fe;
  color: #ffffff;
  padding: 1px 6px; /* Reduced from 2px 8px */
  border-radius: 10px; /* Reduced from 12px */
  font-size: 10px; /* Reduced from 11.5px */
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 10;
  opacity: 0.9;
}

/* Base Styling for Payment Type Buttons */
.userplan-payment-type-btn {
  align-self: center;
  padding: 12px 24px; /* Reduced from 14px 28px */
  background-color: #2c2c2c;
  color: #ffffff;
  border: 2px solid #2c2c2c;
  border-radius: 6px; /* Reduced from 8px */
  cursor: pointer;
  font-size: 0.95em; /* Reduced from 1.05em */
  font-weight: 600;
  box-shadow: none;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  margin-top: 6px; /* Reduced from 8px */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.4;
  position: relative;
}

.userplan-payment-type-btn.active {
  background-color: #1e1e1e;
  color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  opacity: 1;
}

.userplan-payment-type-btn:hover {
  background-color: #3a3a3a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Original Price Styling */
.userplan-original-price {
  font-size: 18px; /* Further reduced from 20px */
  color: #888888;
  text-decoration: line-through;
  margin-bottom: 0.5px; /* Further reduced from 1px */
  text-align: left;
}

/* Upload Minutes styling */
.userplan-upload-minutes {
  font-size: 14px; /* Reduced from 16px */
  font-weight: bold;
  margin-top: 4px; /* Reduced from 5px */
  color: var(--amount-color);
  text-align: left;
  margin-bottom: 6px; /* Reduced from 8px */
}

/* Plans container */
.userplan-plans-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px; /* Reduced from 30px */
  gap: 15px; /* Reduced from 20px */
}

/* Plan card styling */
.userplan-plan-card {
  background-color: #282828;
  border-radius: 6px; /* Further reduced from 8px */
  width: 100%;
  padding: 18px; /* Further reduced from 20px */
  cursor: pointer;
  transition: border 0.3s ease, background-color 0.3s ease, transform 0.2s ease;
  border: 2px solid #888888;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.userplan-plan-card:hover {
  transform: translateY(-2px); /* Reduced from -3px */
  background-color: #333333;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.userplan-plan-card.selected {
  border: 2px solid var(--button-color);
  background-color: #1e1e1e;
  transform: translateY(-4px); /* Reduced from -5px */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.userplan-plan-card.unselected {
  opacity: 0.35; /* Further reduced from 0.4 */
  transition: opacity 0.3s ease;
}

.userplan-plan-header {
  display: flex;
  align-items: center;
  gap: 8px; /* Reduced from 10px */
}

.userplan-plan-header h3 {
  font-size: 20px; /* Further reduced from 22px */
  margin: 0;
}

.userplan-price {
  font-size: 24px; /* Further reduced from 28px */
  font-weight: bold;
  margin: 12px 0 8px; /* Reduced from 15px 0 10px */
  text-align: left;
}

.userplan-price span {
  font-size: 14px; /* Reduced from 18px */
  color: #888888;
}

.userplan-billing-info {
  color: #888888;
  font-size: 12px; /* Reduced from 14px */
  margin-bottom: 12px; /* Reduced from 15px */
}

.userplan-features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #cccccc;
  font-size: 14px; /* Reduced from 16px */
}

.userplan-features-list li {
  margin-bottom: 10px; /* Reduced from 12px */
  display: flex;
  align-items: center;
}

.feature-checkmark-icon {
  width: 16px; /* Reduced from 20px */
  height: 16px; /* Reduced from 20px */
  margin-right: 8px; /* Reduced from 10px */
  animation: fadeIn 0.5s ease;
}

.userplan-plan-logo {
  width: 32px; /* Reduced from 40px */
  height: 32px; /* Reduced from 40px */
  opacity: 0.95;
}

/* Discount Badge Styling */
.userplan-discount-badge {
  position: absolute;
  top: 12px; /* Adjusted */
  right: 12px; /* Adjusted */
  background-color: #aa64fe;
  color: #ffffff;
  padding: 4px 8px; /* Reduced from 5px 10px */
  border-radius: 4px; /* Reduced from 5px */
  font-size: 0.8rem; /* Reduced from 0.9rem */
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
  opacity: 0.9;
}

/* Credit Selection */
.userplan-credit-selection {
  margin-top: 15px; /* Further reduced from 15px */
}

.userplan-amount-label {
  display: block;
  margin-bottom: 15px; /* Reduced from 18px */
  color: #888888;
  font-size: 14px; /* Reduced from 16px */
  text-align: left;
}

.userplan-amount-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr)); /* Further reduced from 80px */
  gap: 6px; /* Reduced from 8px */
  justify-items: center;
}

.userplan-amount-btn {
  padding: 8px 0; /* Further reduced from 10px */
  font-size: 0.85rem; /* Reduced from 0.9rem */
  width: 100%;
  max-width: 100px; /* Further reduced from 120px */
  border: 2px solid var(--button-color);
  border-radius: 30px; /* Reduced from 35px */
  background-color: #2c2c2c;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  text-align: center;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
}

.userplan-amount-btn:hover {
  background-color: #3a3a3a;
  transform: translateY(-2px);
}

.userplan-amount-btn.selected {
  background-color: rgba(var(--button-color-rgb), 0.15);
  color: #ffffff;
  border-color: var(--button-color);
  transform: scale(1.02);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.userplan-amount-btn::after {
  display: none;
}

/* Total Price Display */
.userplan-total-price {
  font-size: 20px; /* Further reduced from 24px */
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  margin-top: 15px; /* Reduced from 20px */
}

/* Proceed Button */
.userplan-proceed-button {
  padding: 12px 14px; /* Further reduced from 14px 16px */
  background-color: rgba(var(--button-color-rgb), 0.15);
  color: var(--button-color);
  border: 2px solid var(--button-color);
  border-radius: 6px; /* Reduced from 8px */
  font-weight: bold;
  font-size: 0.95rem; /* Further reduced from 1rem */
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  margin-top: 20px; /* Kept from previous */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.userplan-proceed-button.active {
  background-color: rgba(var(--button-color-rgb), 0.15);
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.userplan-proceed-button:hover {
  background-color: rgba(var(--button-color-rgb), 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.userplan-proceed-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(var(--amount-color-rgb), 0.4), transparent);
  transition: left 0.5s;
}

.userplan-proceed-button:hover::after {
  left: 100%;
}

/* Disabled state */
.userplan-proceed-button:disabled,
.userplan-proceed-button[aria-disabled="true"] {
  cursor: not-allowed;
  background-color: #1e1e1e;
  color: #999999;
  box-shadow: none;
}

.userplan-proceed-button:disabled::after,
.userplan-proceed-button[aria-disabled="true"]::after {
  display: none;
}

/* Background image styling */
.userplan-image-wrapper {
  display: none; /* Already hidden */
}

.userplan-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  object-position: left;
}

.userplan-image-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30, 30, 30, 0.6);
}

/* Adjustments to text alignment */
.userplan-left-content *,
.userplan-left-content h2,
.userplan-left-content h3,
.userplan-left-content p,
.userplan-left-content span,
.userplan-left-content li {
  text-align: left;
}

/* Animate the checkmark icons */
.feature-checkmark-icon {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Adjust shadows */
.userplan-plan-card {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.userplan-plan-card:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

/* Smooth transitions */
* {
  transition: all 0.3s ease;
}

/* Responsive Adjustments */

/* Mobile Devices (Max-width: 768px) */
@media (max-width: 768px) {
  .userplan-popup-overlay {
    overflow-y: auto;
    padding: 0px;
  }

  .userplan-popup-content {
    flex-direction: column;
    max-height: 75vh; /* Further reduced from 80vh */
    overflow-y: auto;
    padding: 12px; /* Further reduced from 15px */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    width: 90%; /* Further adjusted from 85% */
  }

  .userplan-left-content {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px; /* Further reduced from 15px */
  }

  .userplan-title {
    font-size: 22px; /* Further reduced from 24px */
    text-align: center;
    margin-top: 18px; /* Reduced from 20px */
    margin-bottom: 18px; /* Reduced from 20px */
  }

  .userplan-payment-type-btn {
    font-size: 0.9rem; /* Further reduced from 0.95rem */
    padding: 10px 16px; /* Reduced from 12px 18px */
    margin: 0 4px; /* Further reduced from 5px */
  }

  .userplan-toggle-container {
    justify-content: center;
    gap: 4px; /* Reduced from 5px */
  }

  .userplan-plans-container {
    flex-direction: column;
    align-items: center;
    gap: 8px; /* Further reduced from 10px */
  }

  .userplan-plan-card {
    width: 100%;
    max-width: 100%;
    padding: 18px; /* Further reduced from 20px */
    border-radius: 6px; /* Further reduced from 8px */
    margin-bottom: 4px; /* Reduced from 5px */
  }

  .userplan-plan-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 6px; /* Reduced from 8px */
  }

  .userplan-plan-header h3 {
    font-size: 18px; /* Further reduced from 20px */
    font-weight: bold;
    margin: 8px 0; /* Reduced from 10px 0 */
  }

  .userplan-price {
    font-size: 20px; /* Further reduced from 22px */
    font-weight: bold;
    text-align: center;
    margin: 4px 0; /* Reduced from 5px 0 */
  }

  .userplan-upload-minutes {
    font-size: 14px; /* Reduced from 16px */
    font-weight: bold;
    color: var(--amount-color);
    text-align: center;
    margin: 4px 0; /* Reduced from 5px 0 */
  }

  .userplan-billing-info {
    font-size: 12px;
    color: #888888;
    text-align: center;
    margin-top: 4px; /* Reduced from 5px */
  }

  .userplan-features-list {
    display: none;
  }

  .userplan-credit-selection {
    text-align: center;
    margin: 8px 0; /* Further reduced from 10px */
  }

  .userplan-amount-label {
    font-size: 12px; /* Reduced from 14px */
    color: #888888;
    margin-bottom: 0px;
    display: none;
  }

  .userplan-amount-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 6px; /* Reduced from 8px */
    justify-content: center;
  }

  .userplan-amount-btn {
    padding: 8px 0;
    font-size: 0.85rem;
    border-radius: 20px;
    width: 60px; /* Further reduced from 70px */
    text-align: center;
  }

  .userplan-proceed-button {
    font-size: 0.95rem;
    padding: 10px 0; /* Further reduced from 12px 0 */
    border-radius: 6px; /* Further reduced from 8px */
    margin-top: 18px; /* Reduced from 20px */
    max-width: 95%; /* Adjusted */
    font-weight: bold;
  }
}

/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .userplan-image-wrapper {
    display: none;
  }

  .userplan-left-content {
    width: 95%;
    padding: 12px; /* Further reduced from 15px */
    /* Optional: Adjust padding for better spacing on tablets */
  }

  .userplan-popup-content {
    flex-direction: column;
    height: auto;
    max-width: 70%; /* Further reduced from 75% */
    border-radius: 12px; /* Reduced from 14px */
    padding: 12px; /* Further reduced from 15px */
  }

  .userplan-title {
    font-size: 26px; /* Reduced from 28px */
  }

  .userplan-payment-type-btn {
    font-size: 0.9rem; /* Further reduced from 0.95rem */
    padding: 10px 18px; /* Reduced from 12px 20px */
  }

  .userplan-plan-header h3 {
    font-size: 18px; /* Further reduced from 20px */
  }

  .userplan-price {
    font-size: 22px; /* Reduced from 24px */
  }

  .userplan-proceed-button {
    font-size: 0.95rem;
    padding: 12px 0; /* Further reduced from 14px */
  }
}
