.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4; /* Adjust to your theme's background color */
    color: #333; /* Adjust to your theme's text color */
    font-family: 'Arial', sans-serif; /* Adjust to your theme's font */
  }
  
  .maintenance-header {
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fade-in-out 3s infinite;
  }

  @keyframes fade-in-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  .maintenance-text {
    font-size: 1.2rem;
    text-align: center;
    max-width: 80%;
    margin-bottom: 2rem;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .maintenance-button {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  .follow-button,
.support-button {
  transition: all 0.3s ease; /* Smooth transition */
}
  
  .follow-button {
    background-color: #1DA1F2; /* Twitter Blue */
  }
  
  .support-button {
    background-color: #333; /* Your theme color */
  }
  .follow-button:hover {
    background-color: #fff; /* White background */
    color: #1DA1F2; /* Black text */
    border: 2px solid #1DA1F2; /* Twitter Blue border */
  }
  
  .support-button:hover {
    background-color: #fff; /* White background */
    color: #333; /* Black text */
    border: 2px solid #333; /* Your theme color border */
  }

  .maintenance-subtitle {
    font-size: 1rem;  /* Smaller text */
    color: #888;
    margin-top: 0.5rem;  /* Less spacing */
  }
  