/* src/components/UploadWorkspace/UploadWorkspace.css */

/* Container for the entire upload dashboard */
.worklink-uploaddashboard {
    background-color: #161616; /* Dark background */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
  }
  
  /* Container to stack LinkWorkspace and LocalWorkspace vertically */
  .upload-workspace-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:40px;
    /* height: 600px; */
  }
  
  @media (max-width: 768px) { 

    .upload-workspace-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap:10px;
      /* height: 600px; */
      margin-bottom: 20px;
    }

    .worklink-uploaddashboard {
      width: 100%;

    }


  }

  @media (min-width: 769px) and (max-width: 1280px) {

    .upload-workspace-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap:40px;
      /* height: 600px; */
      margin-bottom: 20px;
    }

  }