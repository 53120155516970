

.localwork-upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.localwork-file-drop-area {
    position: relative;
    border: 2px dashed #555;
    border-radius: 4px;
    padding: 20px 40px;
    text-align: center;
    background-color: #161616;
    transition: border-color 0.3s ease;
    width: 500px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.localwork-upload-circle {
    width: 150px;
    height: 150px;
    background-color: #1e1e1e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.localwork-upload-logo {
    width: 50px;
    height: 50px;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.localwork-file-drop-area:hover .localwork-upload-logo,
.localwork-file-drop-area.drag-over .localwork-upload-logo {
    opacity: 1.0;
}

.localwork-upload-instruction {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
}

.localwork-upload-subtext {
    color: #888;
    font-size: 14px;
    margin-bottom: 20px;
}

.localwork-select-files-button {
    border-radius: 8px;
    padding: 13px 18px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.localwork-select-files-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.localwork-select-files-button .localwork-icon {
    width: 19px; /* Adjust the size as needed */
    height: auto;
    margin-right: 8px; /* Space between icon and text */
    vertical-align: middle; /* Align the icon with the text */
    margin-bottom: 2px;
}

.localwork-upload-area:hover .localwork-file-drop-area,
.localwork-file-drop-area.drag-over {
    border-color: #ffffff;
}

/* New conditional CSS */
.localwork-upload-area.disabled:hover .localwork-file-drop-area,
.localwork-upload-area.disabled .localwork-file-drop-area.drag-over {
    border-color: #555; /* or a different color if needed */
    cursor: default; /* Optional: change cursor to indicate it's disabled */
}


.localwork-upload-section:hover {
    opacity: 1.0;
}

/* UPLOAD POPUP */
.localwork-upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 92, 81, 0.04); /* Very light green background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.localwork-upload-popup-content {
    background: #1e1e1e;
    padding: 24px;
    color: white;
    max-width: 600px;
    width: 100%;
    position: relative;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 5px;
    border: 1px solid #3e3e3e;
}

.localwork-upload-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.localwork-prevprofile-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
}

.localwork-upload-close-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 23px;
    cursor: pointer;
}

.localwork-upload-content-wrapper {
    width: 100%;
}

.localwork-upload-video {
    width: 100%;
    height: auto;
    max-height: 400px;
    margin: 12px 0;
    border-radius: 10px;
}

.localwork-upload-info-wrapper {
    background: rgba(22, 195, 145, 0.7); /* Light green background with opacity */
    padding: 20px;
    border-radius: 5px;
}

.localwork-upload-status-wrapper {
    background: rgba(22, 195, 145, 0.9); /* Light green background with opacity */
    padding: 14px; /* Updated padding */
    border-radius: 5px;
    margin-top: 20px;
}

.localwork-upload-status-wrapper.error {
    background: rgba(255, 0, 0, 0.6); /* Light red background with opacity */
    padding: 14px;
    border-radius: 5px;
    margin-top: 20px;
}

.localwork-upload-info {
    text-align: left;
    color: #fff;
    font-weight: 500;
}

.localwork-upload-message {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 10px; /* Add space below the message */
}

.localwork-learn-more-link {
    color: inherit;            /* Inherit text color from surrounding element */
    text-decoration: underline; /* Underline the hyperlink */
    cursor: pointer;
    font-weight: bold;
  }
  
  .localwork-learn-more-link:hover {
    color: inherit;            /* Prevent color change on hover */
  }
  

.localwork-upload-progress {
    margin: 0;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
}

.localwork-upload-status {
    text-align: left;
}

.localwork-divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
}

.localwork-upload-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.localwork-buttons-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

/* Styling for the bump icon */
.localwork-bump-icon {
    width: 35px; /* Adjust size as needed */
    height: 35px;
    cursor: default; /* If you want it to appear clickable */
    transition: transform 0.3s ease;
}

.localwork-bump-icon:hover {
    transform: scale(1.02); /* Slightly enlarge on hover */
}


/* CANCEL UPLOAD POPUP */
.localwork-cancel-upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100; /* Ensure it is above the upload popup */
}

.localwork-cancel-upload-popup-content {
    background: #1e1e1e;
    padding: 20px;
    color: white;
    max-width: 250px;
    width: 100%;
    text-align: left;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 10px;
    border: 1px solid #3e3e3e;
    opacity: 1.0;
}

.localwork-cancel-upload-title {
    font-weight: bold;
    font-size: 24px;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
}

.localwork-cancel-upload-message {
    color: #999;
    font-size: 14px;
    margin-top: 10px; /* Add some margin below the title */
}

.localwork-cancel-upload-popup-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.localwork-confirm-button, .localwork-cancel-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
    font-size: 16px;
    width: 100%;
}

.localwork-confirm-button {
    background: #0e0e0e;
    color: #16c391;
    border: 2px solid #16c391;
}

.localwork-confirm-button:hover {
    background: rgba(22, 195, 145, 0.2);
}

.localwork-cancel-button {
    background: #16c391;
    color: #fff;
    border: none;
}

.localwork-cancel-button:hover {
    background: #13a378;
}

/* Loading message styles */
.localwork-loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.localwork-loading-logo {
    width: 120px; /* Adjust size as needed */
    height: 120px; /* Adjust size as needed */
    margin-bottom: 10px;
}

.localwork-loading-text {
    font-weight: bold;
    font-size: 24px;
    color: white;
}

/* JUST UPLOADED POPUP */
.localwork-just-uploaded-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.04); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100; /* Ensure it is above the upload popup */
}

.localwork-just-uploaded-popup-content {
    background: #0e0e0e;
    padding: 5px;
    color: white;
    max-width: 250px;
    width: 100%;
    text-align: left;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 5px;
    border: 1px solid #3e3e3e;
    opacity: 0.9;
}

.localwork-uploading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.localwork-uploading-logo {
    width: 120px; /* Adjust size as needed */
    height: 120px; /* Adjust size as needed */
}

.localwork-uploading-text {
    font-weight: bold;
    font-size: 24px;
    color: white;
}


.bump-credit-usage {
    display: inline-flex; /* Keep everything on the same line */
    align-items: center; /* Aligns vertically in the middle */
    gap: 8px; /* Adds space between elements */
    font-size: 1em; /* Adjusts overall text size */
    white-space: nowrap; /* Prevent text wrapping */
}

.bump-base {
    background-color: rgba(74, 221, 128, 0.15); /* Subtle green background */
    color: #4add80;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    white-space: nowrap;
    text-decoration: none;
}

.credit-cost {
    color: #ffffff; /* White text */
    font-weight: bold;
}


/* LocalVideoDisplay.css */

/* LocalVideoDisplay.css */

/* Add this to enforce a 16:9 aspect ratio */
.localworks-video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 600px; /* Ensures the container doesn't exceed 600px */
}

.localworks-video-preview {
    width: 100%;
    /* Enforce a 16:9 aspect ratio */
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (9/16 = 0.5625) */
    background-color: #000; /* Fallback background color */
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.localworks-video-preview img,
.localworks-video-preview video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the media covers the container without distortion */
}

.localworks-video-info {
    color: #ffffff;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1d1d1d;
    border: 0.5px solid #3e3e3e;
    padding: 20px 0;
    border-radius: 0 0 5px 5px;
}

.localworks-filename-container {
    text-align: left;
    margin-left: 20px;
}

.localworks-filename-label {
    font-size: 15px;
    color: #a9a9a9;
    margin-bottom: 3px;
    text-align: left;
}

.editBump-section-filename { /* Ensure this class is correctly named in CSS */
    font-size: 18px;
    color: #ffffff;
    text-align: left;
}

.localworks-quality-container {
    margin-right: 20px;
}

.localworks-quality {
    font-size: 16px;
    color: #ffffff;
}


.localworks-logo-wrapper {
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 7px; /* Adjust the spacing between the elements */
    margin-left:20px;
}

.localworks-bumpups-logo {
    height: 20px;
    width: auto;
    opacity: 0.7;
}

.localworks-bumping-time {
    font-size: 16px;
    color: #aaaaaa;
    text-align: left;
  animation: fadeIn 0.3s ease-in-out;
}

/* Optional animation for the bumping-time text */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




/* Mobile Optimization */
@media (max-width: 768px) {


    .localwork-file-drop-area {
        width: 250px; /* Adjust width for mobile */
        height: 250px; /* Adjust height for mobile */
        padding: 15px 30px;
        border-radius: 0px 0px 5px 5px;
    }

    .localwork-upload-circle {
        width: 80px; /* Smaller circle for mobile */
        height: 80px;
        margin-bottom: 15px;
    }

    .localwork-upload-logo {
        width: 40px; /* Smaller logo for mobile */
        height: 40px;
    }

    .localwork-upload-instruction {
        font-size: 15px; /* Smaller font size for mobile */
        margin-bottom: 8px;
    }

    .localwork-upload-subtext {
        font-size: 12px; /* Smaller font size for mobile */
        margin-bottom: 15px;
    }

    .localwork-select-files-button {
        font-size: 14px; /* Smaller font size for mobile */
        padding: 10px 15px;
    }

    .localwork-upload-popup {
        width: 90%;
        padding: 20px;
    }

    .localwork-upload-message {
        font-size: 14px;
    }

    .localwork-upload-progress {
        font-size: 16px;
    }



    .localworks-video-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
      }
    
      /* Video preview styling with a 16:9 aspect ratio */
      .localworks-video-preview {
        width: 100%;
        background-color: #000;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        position: relative;
      }
    
      /* Styles for both image and video within the preview */
      .localworks-video-preview img,
      .localworks-video-preview video {
        width: 100%;
        height: 100%;
        object-fit: contain; /* Prevents cropping by fitting the entire media */
        position: absolute;
        top: 0;
        left: 0;
      }
      .localworks-quality {
        display: none;
    }
    

}

/* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1280px) {
    .localwork-dashboard-main {
        flex-direction: column;
        margin-top: 10px;
    }
    .localwork-dashboard-contentgeneral {
        flex-direction: column;
        gap: 20px; /* Adjust gap between elements */
        padding: 10px;
        width: 100%; /* Ensure full width */
    }
    
    .localwork-file-drop-area {
        width: 400px; /* Adjust width for tablet */
        height: 300px; /* Adjust height for tablet */
        padding: 18px 35px;
    }

    .localwork-upload-circle {
        width: 120px; /* Adjust circle size for tablet */
        height: 120px;
        margin-bottom: 18px;
    }

    .localwork-upload-logo {
        width: 45px; /* Adjust logo size for tablet */
        height: 45px;
    }

    .localwork-upload-instruction {
        font-size: 17px; /* Adjust font size for tablet */
        margin-bottom: 9px;
    }

    .localwork-upload-subtext {
        font-size: 13px; /* Adjust font size for tablet */
        margin-bottom: 18px;
    }

    .localwork-select-files-button {
        font-size: 15px; /* Adjust font size for tablet */
        padding: 12px 17px;
    }

    .localworks-video-section {
        width: 550px; /* Ensures the container doesn't exceed 600px */
    }
}

/* Screen between 1281px and 1800px */
@media (min-width: 1281px) and (max-width: 1800px) {
    .localwork-file-drop-area {
        width: 420px; /* Adjust width for this screen range */
        height: 320px; /* Adjust height for this screen range */
        padding: 20px 38px;
    }

    .localwork-upload-circle {
        width: 140px; /* Adjust circle size for this screen range */
        height: 140px;
        margin-bottom: 19px;
    }

    .localwork-upload-logo {
        width: 48px; /* Adjust logo size for this screen range */
        height: 48px;
    }

    .localwork-upload-instruction {
        font-size: 17px; /* Adjust font size for this screen range */
        margin-bottom: 9px;
    }

    .localwork-upload-subtext {
        font-size: 13px; /* Adjust font size for this screen range */
        margin-bottom: 18px;
    }

    .localwork-select-files-button {
        font-size: 15px; /* Adjust font size for this screen range */
        padding: 12px 18px;
    }
}
