.btable-container {
    background-color: #161616; /* Dark background for the entire table */
    color: #FFFFFF;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    margin-top: 40px;
    width: 1200px;
  }
  
  .btable-title {
    text-align: left; /* Left align the title */
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  
  .btable-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure columns are evenly distributed */
    background-color: #161616; /* Apply the same dark background for the table */
  }
  
  .btable-header {
    font-size: 1.3rem;
    color: #aaa;
    padding: 15px;
    background-color: #161616; /* Dark background for the header */
    text-align: center; /* Align the header text in the center */
    border-bottom: 2px solid #3e3e3e; /* Divider line below header */
  }
  
  .btable-category {
    background-color: #161616; /* Dark background for the category row */
    font-weight: bold;
    font-size: 1.0rem; /* Make the text smaller */
    padding: 10px;
    border-top: none; /* No border above category */
    text-transform: uppercase;
    text-align: left; /* Left-align the category text */
    color: #aaa; /* Apply a lighter text color */
  }
  .btable-socialmedia-icon {
    width: 24px; /* Set the desired width for the icon */
    height: 24px; /* Set the desired height for the icon */
    vertical-align: middle; /* Align the icon vertically with the text */
    opacity: 0.8;
}

  
  .btable-feature {
    padding: 10px;
    background-color: #161616; /* Dark background for the feature rows */
    text-align: left; /* Left-align the feature text */
    font-weight: bold;
    opacity: 1;
    color: #e1e3e7;
  }
  
  .btable-center-text {
    color: #FFFFFF;
    font-size: 16px;
    text-align: center; /* Center-align specific table data */
  }
  
  .btable-feature-row {
    margin-bottom: 15px; /* Add space between feature rows */
  }
  
  .btable-feature-row td {
    border-top: none; /* Remove top border from feature rows */
    border-bottom: none; /* Remove bottom border from feature rows */
  }
  
  .btable-table td {
    padding: 10px;
    background-color: #161616; /* Ensure the background is consistent across cells */
  }
  
  .btable-checkmark-icon {
    width: 18px;
    height: 18px;
    opacity: 1;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .btable-table tr:nth-child(even) .btable-feature,
  .btable-table tr:nth-child(odd) .btable-feature {
    background-color: #161616; /* Ensure consistent background color for all rows */
  }
  

  /* Mobile Optimization */
@media (max-width: 768px) {
  
    .btable-container {
    display: none;

    }
  }



  /* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1280px) {
    .btable-container {
        width: 80%;
        }

}