


.dashboard {
  display: flex;
  background-color: #161616;
  min-height: 100vh;
  color: white;
}


.dashboard-main {
  margin-top: 20px;
  flex: 1; /* Take the remaining space after sidebar */
  display: flex;
  flex-direction: column; /* Stack vertically */
}
.dashboard-content-wrapper {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  width: 100%; /* Fill the remaining space */
  
}

.dashboard-contentgeneral {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 20px;
}

.dashboard-right {
  flex: 0 0 calc(45% - 50px); /* Adjust the subtraction value to manage the width */
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}


/* VIDEOSTATS CSS */
/* VIDEOSTATS CSS */
/* VIDEOSTATS CSS */
/* VIDEOSTATS CSS */


.tubestamp-user-stats {
  background: #2c2c2c;
  color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  width: 50%; /* Adjust the width to make the component thinner */
  border: 1px solid #3e3e3e;
  flex: 0 0 45%; /* This prevents the box from growing or shrinking */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

  
}
.tubestamp-user-stats, .tubestamp-header, .tubestamp-current-subscribers, .tubestamp-summary, .tubestamp-stat, .tubestamp-top-videos, .tubestamp-button {
  text-align: left; /* Aligns text to the left */
}
.tubestamp-section {
  margin-bottom: 20px;
}
.tubestamp-header h2 {
  margin-top: 0; /* Remove top margin from the title */
  /* other styles */
}

.tubestamp-subscribers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.tubestamp-subscriber-label {
  margin-bottom: 6px; /* Add some space below the label */
}

.tubestamp-number {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 6px; /* Add some space below the label */
}

.tubestamp-change {
  color: #76c89b;
  font-size: 16px;
  
}

.tubestamp-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.tubestamp-divider {
  border: 0;
  height: 1px;
  background: #333;
  margin: 20px 0;
}

.tubestamp-top-videos ul {
  list-style-type: none;
  padding: 0;
}

.tubestamp-top-videos li {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.video-thumbnail {
  width: 20px;
  margin-right: 10px;
}

.tubestamp-button {
  margin-top: 30px;
  background-color: transparent; /* Removes the background color */
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold; /* Makes the text bold */
  flex-grow: 1; /* This will push the pagination to the right */
  text-align: left;
  display: block;
  width: 100%;
}



.tubestamp-timeframe {
  display: block; /* Ensures that the span takes up the full width for alignment */
  text-align: left; /* Aligns the text to the left */
  margin-bottom: 10px; /* Adds some space below the text for separation */
  font-weight: bold;
}



/* END VIDEOSTATS CSS */
/* END VIDEOSTATS CSS */
/* END VIDEOSTATS CSS */
/* END VIDEOSTATS CSS */
/* END VIDEOSTATS CSS */


/* RECENT BUMP BUMP CSS */
/* RECENT BUMP BUMP CSS */
/* RECENT BUMP BUMP CSS */
/* RECENT BUMP BUMP CSS */
/* RECENT BUMP BUMP CSS */
/* RECENT BUMP BUMP CSS */

.recent-bump-box {
  background: #1e1e1e;
  color: #fff;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #3e3e3e;
  max-width: 350px; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

.recent-bump-box h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin: 0 0 1rem 0; /* Remove top margin */
  text-align: left;

}

.recent-bump-content {
  display: flex;
  align-items: center; /* Vertically centers content */
  justify-content: space-between;
}

.recent-bump-thumbnail {
  width: 160px; 
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin-right: 20px; 
}

.recent-bump-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensure vertical centering */
  height: 100%; /* Takes up the full height of the parent */
  margin: 0; /* Remove any potential margin */
}

.recent-bump-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0; /* Remove margin to eliminate space above the title */
  padding: 0; /* Ensure no padding adds extra space */
  text-align: left;
}

.recent-bump-info {
  display: flex;
  align-items: center; /* Vertically center duration and quality */
  gap: 10px; /* Space between duration and quality */
  margin-top: 10px; /* Small margin to separate from the title */
}

.recent-bump-duration,
.recent-bump-meta {
  font-size: 1rem;
  text-align: left;
  margin: 0; /* Remove any margin to keep them aligned */
}
.recent-bump-thumbnail,
.recent-bump-title {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.recent-bump-title:hover {
  text-decoration: underline; /* Add underline on hover */
}

.recent-bump-thumbnail:hover,
.recent-bump-title:hover {
  opacity: 0.9; /* Optional: Add a slight hover effect */
}

.no-recent-bump {
  color: #fff; /* White text color */
  border-radius: 5px; /* Rounded corners */
  text-align: left; /* Center the text */
}

.no-recent-bump a {
  color: #4add80; /* Link color (blue) */
  text-decoration: none; /* Remove underline on hover */
  margin-left: 5px; /* Add space before the link */
}

.no-recent-bump a:hover {
  text-decoration: underline; /* Underline the link */

}



/* END RECENT BUMP BUMP CSS */
/* END RECENT BUMP BUMP CSS */
/* END RECENT BUMP BUMP CSS */
/* END RECENT BUMP BUMP CSS */
/* END RECENT BUMP BUMP CSS */
/* END RECENT BUMP BUMP CSS */


/* ACHIEVEMENT CSS */
/* ACHIEVEMENT CSS */
/* ACHIEVEMENT CSS */
/* ACHIEVEMENT CSS */
/* ACHIEVEMENT CSS */


.achievement-box {
  background: #1e1e1e;
  color: #fff;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px; 
  border: 1px solid #3e3e3e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

}

.achievement-box h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin: 0 0 1rem 0; /* Remove top margin */
}

.achievement-content {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.achievement-icon {
  width: 60px; 
  border-radius: 10px;
  margin-right: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

}

.achievement-title {
  font-size: 1.1rem;
  margin-bottom: 10px;
  text-align: left;

}

.achievement-description {
  font-size: 1rem;
  margin: 0;
  text-align: left;
}

.view1mindemo-btn {
  background-color: transparent; /* Removes the background color */
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold; /* Makes the text bold */
  flex-grow: 1; /* This will push the pagination to the right */
  text-align: left;
  display: block;
  width: 100%;
  margin-top: 10px;

}

.view1mindemo-btn:hover {
text-decoration: underline;
}

.requestfeature-btn {
  background-color: transparent; /* Removes the background color */
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold; /* Makes the text bold */
  flex-grow: 1; /* This will push the pagination to the right */
  text-align: left;
  display: block;
  width: 100%;
  margin-top: 10px;
  text-decoration: none;
}

.requestfeature-btn:hover {
text-decoration: underline;
}

/* Trailer Popup Styles */
/* Trailer Popup Styles */
.trailer-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for focus */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.trailer-popup-content {
  position: relative;
  max-width: 50%; /* Reduced max width for a smaller popup */
  width: 100%; /* Adjusted to make it responsive */
  max-height: 70%; /* Limit the height for smaller view */
  padding: 0;
  text-align: center;
}

.trailer-video-player {
  width: 100%;
  height: auto; /* Maintains the aspect ratio */
  max-height: 100%; /* Ensures video doesn’t exceed content container */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border: none;
  aspect-ratio: 16 / 9; /* Consistent aspect ratio */
}






/* END ACHIEVEMENT CSS */
/* END ACHIEVEMENT CSS */
/* END ACHIEVEMENT CSS */
/* END ACHIEVEMENT CSS */
/* END ACHIEVEMENT CSS */
/* END ACHIEVEMENT CSS */


/* SUGGESTIONS CSS */
/* SUGGESTIONS CSS */
/* SUGGESTIONS CSS */
/* SUGGESTIONS CSS */
/* SUGGESTIONS CSS */

.suggestions-container {
  background-color: #1e1e1e;
  color: white;
  border-radius: 5px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px; 
  border: 1px solid #3e3e3e;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

}
.suggestions-content {
  text-align: left;
}
.suggestions-card {
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestions-content h3 {
  margin-top: 0;
  font-size: 1rem;
  color: #aaa;
}

.suggestions-content h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.suggestions-content p {
  font-size: 0.9rem;
  color: #ddd;
}

.suggestions-icon img {
  margin-top: 40px;
  width: 90px; /* Adjust the size as needed */
  height: auto;
  padding: 0px; /* Adjust the padding to scale the circle size */
}

.suggestions-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suggestions-start-btn {
  background-color: transparent; /* Removes the background color */
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold; /* Makes the text bold */
  flex-grow: 1; /* This will push the pagination to the right */
  text-align: left;
  display: block;
  width: 100%;
  margin-top: 20px;

}

.suggestions-start-btn:hover {
text-decoration: underline;
}


.suggestions-pagination {
  color: #aaa;
  font-size: 0.9rem;
}

.suggestions-pagination {
  display: flex;
  align-items: center;
  justify-content: center; /* Center pagination in the container */
  top: 20px; /* Adjust these values based on your layout */
  right: 20px;
  color: #aaa;
  font-size: 0.9rem;
  position: absolute;
  font-weight: bold;
}

.pagination-arrowbox {
  cursor: pointer;
  margin: 0 10px;
  user-select: none; /* Prevent text selection */
}

.pagination-arrowbox:hover {
  color: white; /* Highlight on hover */
}

/* Ensure the parent container of your component has a relative positioning */
.dashboard-right {
  position: relative;
}



/* END SUGGESTIONS CSS */
/* END SUGGESTIONS CSS */
/* END SUGGESTIONS CSS */
/* END SUGGESTIONS CSS */
/* END SUGGESTIONS CSS */


/* GUIDE CSS */
/* GUIDE CSS */
/* GUIDE CSS */
/* GUIDE CSS */

.guides-container {
  background-color: #1e1e1e;
  color: white;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align children to the start (left) */
  max-width: 400px; 
  border: 1px solid #3e3e3e;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.guides-title {
  text-align: left;
  font-size: 1rem;
  color: #fff;
  margin-top: 0; /* Adjust as needed */
  margin-bottom: 20px; /* Space below the title */
  width: 100%; /* Ensure the title spans the full width */
}

.guides-thumbnail img {
  width: 100%; /* Size as needed */
  height: auto;
  border-radius: 10px;
  background-color: #0e0e0e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  cursor: pointer;
}


.language-settingdash img {
  width: 90%; /* Size as needed */
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: block; /* Ensures the image takes full width and aligns properly */
  margin-left: 0; /* Ensures no additional centering or margin is applied */
  margin-bottom: 10px;
}

.no-underline {
  text-decoration: none;
}


.guides-content {
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure the content spans the full width */
}

.guides-action-btn {
  margin-top: 30px;
  background-color: transparent; /* Removes the background color */
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold; /* Makes the text bold */
  flex-grow: 1; /* This will push the pagination to the right */
  text-align: left;
  display: block;
  width: 100%;
}

/* Hover state for the button */
.guides-action-btn:hover {
  text-decoration: underline;
}



/* END GUIDE CSS */
/* END GUIDE CSS */
/* END GUIDE CSS */
/* END GUIDE CSS */
/* END GUIDE CSS */


.dashboard-main {
  flex-grow: 1;
  padding: 20px;
}

.header {
  width: 100%; /* Full width */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Spacing below the header */
}
.header h1 {
  font-size: 2.9em; /* Large, bold font */
  color: #fff;
  margin-bottom: 10px; /* Spacing below the heading */
}

.header .account-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}

.header .account-links a:hover {
  text-decoration: underline;
}

.video-stats {
  flex-basis: 50%;
  background-color: #202020;
  padding: 20px;
  border-radius: 4px;
  
}

/* RECENT VIDEOS CSS */
/* RECENT VIDEOS CSS */
/* RECENT VIDEOS CSS */
/* RECENT VIDEOS CSS */

.recent-videos-wrapper {
  background: #2c2c2c;
  padding: 30px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #3e3e3e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  margin-bottom: 30px; /* Adjust as needed for spacing */
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;}

.recent-videos-container {
  flex: 0 0 35%; /* This prevents the box from growing or shrinking */
  width: 100%;
  
}

.recent-videos-header {
  color: #fff;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: left;
}

.recent-videos-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0; /* Top, Right, Bottom, Left */
  align-items: flex-start; /* Aligns items to the start of the cross axis */
}

.recent-video-item {
  padding-left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start; /* Aligns items to the start of the cross axis */
  justify-content: flex-start; /* This will align the flex items to the start of the container */
  margin-bottom: 10px;
  padding: 6px;
  cursor: pointer; /* Optional: Changes the cursor on hover */

}

.recent-video-item:hover {
  background-color: #444;
}

.recent-video-thumbnail {
  width: 70px;
  height: auto;
  margin-right: 15px;
  border-radius: 4px;
}

.recent-video-title {
  color: #fff;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  flex-grow: 1;
  text-align: left;
  width: 100%;
}
.recent-video-details {
  display: flex;
  align-items: center; /* Align the icon and duration vertically */
  margin-top: 4px; /* Space between title and details */
}

.recent-video-creator-icon {
  width: 20px; /* Adjust as needed */
  height: auto;
  margin-right: 5px; /* Space between icon and duration */
}

.recent-video-duration {
  color: #fff;
  font-size: 12px; /* Adjust as needed */
  /* Add other styling as needed */
}

@media (max-width: 768px) {
  
  .dashboard-main {
    
    margin-top: 0; /* Remove margin-top if needed */
    margin: 10px 10px 10px 10px;
  }

  .dashboard-content-wrapper {
    flex-direction: column; 
    /* Stack content and right column vertically */
  }

  .dashboard-right {
    width: 100%; /* Each section takes full width of the screen */
    max-width: 100%; /* Override any max-width */
    flex: none; /* Disable flex grow, shrink, and basis */
    margin-bottom: 20px; /* Add some space between the sections */
  }

  .dashboard-contentgeneral {
    margin-left: 10px;
    width: 100%; /* Each section takes full width of the screen */
    max-width: 100%; /* Override any max-width */
    flex: none; /* Disable flex grow, shrink, and basis */
  }


  .guides-title {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .guides-thumbnail img {
    margin-bottom: 5px;
  }

  .guides-action-btn {
    margin-top: 20px;
    font-size: 0.8rem;
  }





  .achievement-box {
    /* If the box should be a certain width, you can specify it and use margin to center it */
    margin: 0 auto; /* Auto margins on the sides will center the box */
    margin-top: 20px;
  }

  .achievement-box h3 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  .recent-bump-box {
    /* If the box should be a certain width, you can specify it and use margin to center it */
    margin: 0 auto; /* Auto margins on the sides will center the box */
    margin-top: 20px;
  }

  .recent-bump-box h3 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }



  .achievement-content {
    margin-bottom: 1rem;
  }

  .achievement-icon {
    width: 50px;
    margin-right: 0.8rem;
  }

  .achievement-title {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  .achievement-description {
    font-size: 0.8rem;
  }

  .view-analytics-btn {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .suggestions-container {
    margin: 0 auto; /* Auto margins on the sides will center the box */
    margin-bottom: 10px;
  }

  .suggestions-content h3 {
    font-size: 0.9rem;
  }

  .suggestions-content h2 {
    font-size: 1.2rem;
    margin: 8px 0;
  }

  .suggestions-content p {
    font-size: 0.8rem;
  }

  .suggestions-icon img {
    margin-top: 30px;
    width: 70px;
  }

  .suggestions-start-btn {
    font-size: 0.8rem;
    margin-top: 15px;
  }

  .suggestions-pagination {
    font-size: 0.8rem;
    position: static; /* If you want to keep pagination at the bottom of the content instead of absolute positioning */
    margin-top: 10px; /* Adjust this if you change the position to static */
  }

  .pagination-arrowbox {
    margin: 0 5px;
  }

  .guides-wrapper {
    margin-right: 20px;
  }

  .trailer-popup-content {
    max-width: 75%; /* Reduced max width for a smaller popup */
  }

}

@media (min-width: 769px) and (max-width: 1280px) {


}