/* ./homeComponent/socialprofileCarousel.css */

/* Scrolling bar section */
.spc-scrolling-bar-container-wrapper {
    width: 60%;
    margin: 0 auto;
  }
  
  .spc-scrolling-bar-container {
    margin-top: 10px;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
  
  .spc-scrolling-bar {
    display: flex;
    gap: 100px;
    padding: 20px 0;
    width: max-content;
    animation: spc-scroll-left 200s linear infinite;
  }
  
  .spc-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  
  .spc-profile-img-wrapper {
    position: relative;
  }
  
  .spc-profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  
  .spc-youtube-logo {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #000;
    border-radius: 50%;
  }
  
  .spc-profile-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .spc-profile-subscribers {
    font-size: 1rem;
    color: #bbb;
    margin-top: -10px;
  }
  
  .spc-scrolling-bar-container::before,
  .spc-scrolling-bar-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30%;
    pointer-events: none;
    z-index: 1;
  }
  
  .spc-scrolling-bar-container::before {
    left: 0;
    background: linear-gradient(to right, #161616, transparent);
  }
  
  .spc-scrolling-bar-container::after {
    right: 0;
    background: linear-gradient(to left, #161616, transparent);
  }
  
  @keyframes spc-scroll-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
  }
  
  /* Responsive Styles */
  
  /* Mobile Devices (up to 768px) */
  @media (max-width: 768px) {
    .spc-scrolling-bar-container-wrapper {
      width: 80%;
      margin: 0 auto;
      margin-bottom: -20px;
      margin-top: 10px;
    }
  
    .spc-profile-img {
      width: 60px;
      height: 60px;
    }
  
    .spc-youtube-logo {
      width: 15px;
      height: 15px;
    }
  
    .spc-profile-name {
      font-size: 1rem;
    }
  
    .spc-profile-subscribers {
      font-size: 0.9rem;
    }
  
    .spc-scrolling-bar {
      gap: 50px;
    }
  }
  
  /* Tablet Devices (769px to 1280px) */
  @media (min-width: 769px) and (max-width: 1280px) {
    .spc-scrolling-bar-container-wrapper {
      width: 80%;
      margin: 0 auto;
      margin-bottom: -20px;
      margin-top: 10px;
    }
  
    .spc-profile-img {
      width: 70px;
      height: 70px;
    }
  
    .spc-youtube-logo {
      width: 18px;
      height: 18px;
    }
  
    .spc-profile-name {
      font-size: 1.1rem;
    }
  
    .spc-profile-subscribers {
      font-size: 1rem;
    }
  
    .spc-scrolling-bar {
      gap: 75px;
    }
  }
  