.worklink-uploaddashboard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box; /* Ensure padding is included in the width */
  margin: 0 auto; /* Center the container horizontally */
}

.worklink-dashboard-container {
  width: 100%;
}

.worklink-urlinput-section {
  width: 100%;
}

.worklink-urlinput-container {
  display: flex;
  align-items: center;
  background-color: #2C2C2E; /* Dark background */
  padding: 15px 15px;
  border-radius: 10px;
  width: 480px;
  min-height: 55px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

.worklink-url-input-box {
  background-color: #2C2C2E; /* Same as the container for seamless integration */
  font-size: 17px;
  border: none;
  color: white; /* Text color */
  margin-right: 10px; /* Spacing between the input box and the button */
  padding: 10px;
  padding-left: 40px;
  border-radius: 10px; /* Slightly rounded corners for the input box */
  flex-grow: 1; /* Ensures that the input box takes up the available space */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  outline: none; 
}

.worklink-input-icon-wrapper-DONE {
  background-color: #2C2C2E; /* Same as the container for seamless integration */
  font-size: 17px;
  border: none;
  color: white; /* Text color */
  margin-right: 10px; /* Spacing between the input box and the button */
  padding: 10px;
  padding-left: 40px;
  border-radius: 10px; /* Slightly rounded corners for the input box */
  flex-grow: 1; /* Ensures that the input box takes up the available space */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  outline: none; 
  opacity: 0.5;
}

.worklink-url-input-box:focus {
   /* Brighter shade when input is focused */
  box-shadow: none; /* Remove any default browser styling like shadows or outlines */
}

.worklink-input-icon-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.disabled-input {
  opacity: 0.6; /* Makes the input section appear grayed out */
  pointer-events: none; /* Prevents any mouse interactions */
}

.worklink-url-input-icon {
  position: absolute;
  left: 2px; /* Or adjust as needed */
  width: 30px; /* Your desired icon size */
  height: auto;
}

.worklink-url-input-box::placeholder {
  color: #5C5C5E; /* Lighter color for placeholder text */
}

.worklink-url-input-button {
  background-color: #0e0e0e; /* Default button color */
  color: white; /* Button text color */
  border: none;
  padding: 15px 20px;
  border-radius: 5px; /* More rounded corners for the button */
  font-weight: bold;
  cursor: pointer; /* Changes cursor to pointer when hovering over the button */
  font-size: 16px;
  text-align: center;
}

.worklink-url-input-button .icon {
  width: 23px; /* Adjust the width as needed */
  height: auto;
  margin-right: 7px; /* Space between the icon and text */
  vertical-align: middle; /* Align the icon vertically */
  margin-bottom: 4px;
}

.worklink-url-input-button.disabled {
  opacity: 0.5; /* Style for disabled state */
  cursor: default; 
}

.worklink-url-input-button.videoDataReady {
  background-color: #45c072; /* Different color indicating processing completion */
  text-decoration: none; /* Removes the underline */
}

.worklink-url-input-button.processing {
  background-color: #1e1e1e; /* Indicates processing */
  opacity: .8;
}

.worklink-url-input-button.max-duration-warning {
  background-color: #ff8c00; /* Indicates duration warning */
  cursor: default;
}

.worklink-url-input-button.searching {
  background-color: #0e0e0e; /* Indicates searching in progress */
}

.worklink-url-input-button.timebank-over {
  text-decoration: none; /* Removes underline from the link */
  background-color: #40a465; /* Your existing background color */
  color: white;      
}

.worklink-url-input-button:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

.worklink-upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Spacing between elements */
}

.worklink-file-drop-area {
  position: relative;
  border: 2px dashed #555;
  border-radius: 10px;
  padding: 20px;
  width: 55%;
  text-align: center;
  background-color: #161616;
}

.worklink-file-input-label {
  display: block; /* Make the label behave like a container */
  margin-top: 2px; /* Adjust the spacing to match the button's position */
  cursor: pointer;
  color: #888; /* Color for the text inside the label */
}

.worklink-video-type-section {
  background-color: #0e0e0e; /* Background color for the video types section */
  padding: 25px;
  margin-right: auto; /* Centers the section */
  margin-left: auto; /* Centers the section */
  border-radius: 5px;
  text-align: left;
  width: 100%; /* Set width to enable horizontal centering */
  max-width: 420px;
  box-sizing: border-box; /* Ensures padding doesn't affect the total width */
  border: 1px solid #3e3e3e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

.worklink-video-type-info p {
  color: #AAAAAA;
  margin-bottom: 20px;
  margin-top: 6px;
  font-size: 15px;
}

.worklink-video-types {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.worklink-supported-types p,
.worklink-unsupported-types p {
  margin: 5px 0;
  color: #FFFFFF;
  font-size: 14px;
}

.worklink-supported-types p::before {
  content: '✔ ';
  color: #4CAF50;
}

.worklink-unsupported-types p::before {
  content: '✖ ';
  color: #F44336;
}

.worklink-settings-link {
  color: #4add80;
  text-decoration: none; /* Optional: to remove underline */
}

.worklink-settings-link:hover {
  text-decoration: underline; /* Optional: to add underline on hover */
}

/* VIDEO PREVIEW */

.worklink-workspace-video-details {
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: left;
  width: 100%; /* Full width to center content in the middle */
  box-sizing: border-box;
}

.worklink-video-card {
  border-radius: 15px;
  color: white;
  /* Set a max-width if you don't want it to stretch too wide */
  max-width: 400px;
  width: 100%; /* This will now take the max-width value */
  position: relative;
  overflow: hidden;
}

.worklink-video-thumbnail-container {
  position: relative;
  border-radius: 15px; /* Apply border-radius here */
  overflow: hidden; /* Add overflow hidden here to ensure child elements respect the border-radius */
}

.worklink-video-thumbnail {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  object-fit: cover; /* This will cover the area without stretching the image */
}

.worklink-video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 200, 148, 0.2); /* Orange color with 20% opacity */
  border-radius: inherit; /* Inherit border-radius from the parent, if any */
}

.worklink-error-video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 85, 85, 0.2);
  border-radius: inherit; /* Inherit border-radius from the parent, if any */
}

.worklink-error-message-box {
  position: absolute; /* Absolute positioning within the relative container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset by half of the element's width and height to truly center it */
  background-color: #0e0e0e;
  border-radius: 5px;
  padding: 17px;
  border: 2px solid #3e3e3e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%; /* Adjust based on design */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 3; /* Ensure it sits above other elements */
}

.worklink-error-message {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;  /* Adjust the margin as needed */
  text-align: center;
}

.worklink-retry-message {
  font-size: 14px;
}

.worklink-retry-message a{
  color: #4add80;
  text-decoration: underline;
}

.worklink-processing-gif {
  width: 100px; /* Adjust to fit within your thumbnail */
  height: auto; /* Maintain aspect ratio */
  /* Other styles remain the same */
}

.worklink-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Match the thumbnail's border-radius */
}

.worklink-video-quality {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ffcc00;
  padding: 5px;
  border-radius: 4px;
  /* ...other styles... */
}

.worklink-video-title-DONE {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0 0 10px;
}

.worklink-video-metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.worklink-video-caption, .worklink-video-credit-usage {
  padding: 5px;
  font-size: 17px;
  color: #9b9ea3;
  /* ...other styles... */
}

.worklink-video-caption b, .worklink-video-credit-usage b {
  color: white; /* Change the color of the text inside <b> tags to white */
}

.badge-base {
  background-color: #1e1e1e;
  color: #ffbf00;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: 600;
  white-space: nowrap;
}

.badge-overlay {
  position: absolute;
  top: -10px; /* Adjust to move badge vertically */
  left: -10px; /* Adjust to move badge horizontally */
  z-index: 1; /* Ensure badge is above other elements */
  /* Adjust size and spacing as needed */
}

/* Video Not Found */

.worklink-video-not-found {
  border-radius: 10px; /* More rounded corners */
  background-color: #1e1e1e;
  color: #FFFFFF; /* White text */
  padding: 24px; /* More padding for better spacing */
  text-align: center;
  margin: 10px auto; /* Centered horizontally */
  font-size: 18px; /* Font size */
  max-width: 400px; /* Max width to maintain readability */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4); /* Enhanced shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  border: 1px solid #3e3e3e;
  margin-top: 30px;
  /* Hover effect for interactivity */
}

.worklink-video-not-found:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-7px);
}

/* Main text styling */
.worklink-main-text {
  font-size: 20px;
  font-weight: 600; /* Bold */
  margin-bottom: 15px; /* Spacing between main text and small text */
}

/* Small text styling */
.worklink-small-text {
  font-size: 14px;
  line-height: 1.5; /* Improved readability */
  color: #B3B3B3; /* Lighter color for less emphasis */
}

.worklink-highlight-video-id {
  font-weight: bold;
  color: #3da6fe;
}

.worklink-example-url {
  font-size: 17px; /* Increased font size */
  display: block; /* Makes it a separate line */
  margin-top: 5px; /* Optional spacing */
  color: #ffffff;
}

/* TOGGLE BAR */

.worklink-toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center everything in the pill */
  gap: 10px; /* Add some space between the text and the switch */
  background-color: #0e0e0e; /* Background color for the toggle container */
  padding: 20px 20px; /* Adjust padding as needed */
  border-radius: 15px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  border: 1px solid #3e3e3e;
}

.worklink-toggle-switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.worklink-toggle-switch-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.worklink-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.worklink-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.worklink-toggle-switch-label input:checked + .worklink-toggle-slider {
  background-color: #4add80;
}

.worklink-toggle-switch-label input:focus + .worklink-toggle-slider {
  box-shadow: 0 0 1px #4add80;
}

.worklink-toggle-switch-label input:checked + .worklink-toggle-slider:before {
  transform: translateX(26px);
}

.worklink-toggle-switch-text {
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  text-align: left;
  color: white;
}

.worklink-exit-button{
  background: none;
  margin-left: auto;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: white;
}

.worklink-blur-overlay {
  filter: blur(2px);
  pointer-events: none; /* Prevent interaction with the blurred section */
}

@media (max-width: 768px) {
  /* Adjust dashboard container */
  .worklink-uploaddashboard {
    flex-direction: column;
    align-items: center;
    padding: 10px; /* Added padding for better spacing on mobile */
  }

  .worklink-dashboard-container {
    width: 100%;
  }

  .worklink-urlinput-section {
    width: 100%;
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .worklink-urlinput-container {
    flex-direction: column;
    align-items: stretch;
    width: 100%; /* Make it full width on mobile */
    padding: 10px; /* Reduce padding for smaller screens */
    box-shadow: none; /* Remove shadow to save space */
    background-color: #2C2C2E; /* Maintain dark background */
  }

  .worklink-input-icon-wrapper,
  .worklink-input-icon-wrapper-DONE {
    width: 100%;
    margin-right: 0;
  }

  .worklink-url-input-box,
  .worklink-input-icon-wrapper-DONE {
    width: 100%;
    padding: 12px 10px; /* Increase padding for better touch targets */
    font-size: 16px; /* Slightly reduce font size for better fit */
  }

  .worklink-url-input-icon {

    display: none;
  }
  
  .worklink-url-input-button,
  .worklink-url-input-button.videoDataReady {
    width: 100%; /* Ensure both buttons take full width */
    display: block; /* Ensure it behaves like a block element */
    padding: 12px 16px; /* Consistent padding for mobile and desktop */
    font-size: 16px;
    text-align: center; /* Center text within the button */
    box-sizing: border-box; /* Ensure padding is included in the element's width */
    border-radius: 0px 0px 5px 5px; /* More rounded corners for the button */

  }
  
  .worklink-urlinput-container {
    width: 100%; /* Ensure full width for the container */
    padding: 0; /* Remove any padding that could affect button sizing */
  }
  
  .worklink-url-input-box,
  .worklink-input-icon-wrapper-DONE {
    width: 100%; /* Ensure full width for the input */
    box-sizing: border-box; /* Include padding in width calculations */
  }
  
  
  
  .worklink-urlinput-container {
    width: 100%; /* Ensure full width for the container */
  }
  
  .worklink-input-icon-wrapper {
    width: 100%; /* Ensure full width for the input */
  }
  
  

 
  .worklink-workspace-video-details {
    flex-direction: column; /* Stack video details vertically */
    padding: 15px; /* Reduce padding for mobile */
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .worklink-video-card {
    max-width: 100%;
    margin-bottom: 15px; /* Add spacing between video cards */
  }

  .worklink-video-thumbnail-container {
    width: 100%; /* Make thumbnail full width on mobile */
  }

  .worklink-video-thumbnail {
    width: 100%;
    height: auto;
  }

  .worklink-error-message-box {
    width: 90%; /* Increase width for better readability */
    padding: 12px; /* Reduce padding */
  }

  .worklink-processing-gif {
    width: 60px; /* Reduce size on mobile */
  }

  .worklink-video-title-DONE {
    font-size: 1rem; /* Reduce font size for titles */
    margin: 15px 0 0 0; /* Adjust margin */
    text-align: center; /* Center titles on mobile */
  }

  .worklink-video-metadata {
    flex-direction: column; /* Stack metadata vertically */
    align-items: center; /* Center metadata items */
    margin-bottom: 15px;
  }

  .worklink-video-caption,
  .worklink-video-credit-usage {
    font-size: 16px; /* Slightly reduce font size */
    text-align: center; /* Center text on mobile */
  }

  .worklink-toggle-switch-container {
    flex-direction: column; /* Stack toggle switch vertically */
    padding: 10px 15px; /* Reduce padding */
    gap: 8px; /* Reduce gap between elements */
    max-width: 100%; /* Allow full width on mobile */
  }

  .worklink-toggle-switch-text {
    font-size: 16px; /* Slightly reduce font size */
    text-align: center; /* Center text */
  }

  .worklink-toggle-switch-label {
    width: 40px; /* Reduce switch width on mobile */
    height: 20px; /* Reduce switch height on mobile */
  }

  .worklink-toggle-slider:before {
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
  }

  .worklink-toggle-switch-label input:checked + .worklink-toggle-slider:before {
    transform: translateX(20px); /* Adjust translation for smaller switch */
  }

  .worklink-exit-button {
    font-size: 14px; /* Reduce font size */
    padding: 8px; /* Add padding for better touch targets */
  }

  .worklink-video-not-found {
    padding: 10px; /* Reduce padding for smaller screens */
    font-size: 16px; /* Slightly reduce font size */
    max-width: 100%; /* Ensure full width on mobile */
    word-wrap: break-word; /* Ensure the text doesn't overflow */
  }

  .worklink-main-text {
    font-size: 18px; /* Adjust the font size for the main text */
    margin-bottom: 10px; /* Adjust spacing between elements */
    text-align: center; /* Center text on mobile for better readability */
  }

  .worklink-small-text {
    font-size: 14px; /* Slightly reduce font size */
    line-height: 1.4; /* Improve readability on smaller screens */
    text-align: center; /* Center small text on mobile */
    word-break: break-all; /* Break long URLs to fit smaller screens */
  }

  .unauth-example-url {
    font-size: 14px; /* Reduce the font size for URLs */
    margin-top: 5px;
    display: block; /* Ensure it wraps correctly on small screens */
    overflow-wrap: break-word; /* Break long URLs */
  }
  
  .highlight-video-id {
    display: block; /* Ensure that the video ID wraps correctly */
    overflow-wrap: break-word; /* Prevent it from overflowing */
  }
}

/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .worklink-uploaddashboard {

    margin: 0 auto; /* Center the container */
  }
}
