/* General Styles */
.viewdonebump-uploaddashboard {
    display: flex;
    background-color: #161616;
    min-height: 100vh;
}

.viewdonebump-dashboard-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.viewdonebump-dashboard-content-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    flex-grow: 1; /* Allow it to grow */
}

.viewdonebump-dashboard-contentgeneral {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px;
    gap: 25px;
}

/* Mobile Styles */
@media (max-width: 768px) {
 
    .viewdonebump-dashboard-content-wrapper {
        flex-direction: column;

    }

    .viewdonebump-dashboard-contentgeneral {
        padding: 0px;
    }
}