.bp-pricing-page {
  background-color: #161616; /* Background color matches the overall application theme */
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bp-pricing-header {
  margin-bottom: 20px;
}

.bp-pricing-header h1 {
  font-size: 4rem; /* Matching font size with the reference */
  margin-bottom: 50px;
}

.bp-billing-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262626;
  border-radius: 25px;
  padding: 5px;
  width: 100%;
  max-width: 300px; /* Added max-width for better responsiveness */
  border: 1px solid #3e3e3e;
  gap:10px;
}

.bp-toggle-option {
  flex: 1;
  padding: 10px;
  font-size: 18px;
  cursor: pointer; /* Indicates interactivity */
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px; /* Added border-radius to keep it rounded */
}

.bp-toggle-option:hover {
  background-color: #3e3e3e;
  border-radius: 20px; /* Ensure border-radius on hover */
}

.bp-active {
  font-weight: bold;
  color: #4add80; /* Highlighted color for active selection */
  background-color: #3e3e3e;
  border-radius: 20px;
}


.bp-discount-label-bump {
  font-size: 0.8rem;
  color: #4add80;
  margin-left: 5px;
}

.bp-discount-label {
  font-size: 0.8rem;
  color: #ffbf00;
  margin-left: 5px;
}

.bp-pricing-tiers {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap; /* Wraps tiers on smaller screens */
}

.bp-tier {
  background-color: #1e1e1e; /* Keep this background color as requested */
  border-radius: 8px;
  padding: 30px;
  width: 320px;
  position: relative;
  text-align: left;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  border: 1px solid #3e3e3e; /* Border to match the reference */
}

.bp-plan-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Reduced margin for better spacing */
}

.bp-plan-logo {
  width: 26px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px;
  opacity: 0.9;
}

.bp-plan-title-card {
  font-size: 2.1rem; /* Adjust font size as needed */
  font-weight: bold; /* Add any additional styling needed to replace the h2 */
}

.bp-billing-info {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #aaa;
  text-align: left;
}

.bp-price {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 25px;
  color: #ffffff; /* Ensure the text color stands out */
  display: flex;
  align-items: center;
}

.bp-upload-text {
  margin: 10px 0;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.bump-plan-text {
  color: #4add80;
}

.base-plan-text {
  color: #ffbf00;
}

.bp-select-wrapper {
  position: relative;
  width: 100%;
}

.bp-minutes-select {
  /* Remove default arrow */
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* Standard */

  /* Existing styles */
  margin: 15px 0;
  padding: 10px;
  padding-right: 10px; /* Reduced padding-right since arrow is removed */
  background-color: #0e0e0e;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  font-size: 1rem;
  outline: none;

  /* Optional: Add a custom arrow if desired (currently removed) */
  /* background-image: none; */
}

.bp-cta-button {
  padding: 15px 25px;
  background-color: #181818;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  border: 0.5px solid #3e3e3e;
}

.bp-cta-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.bp-features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.bp-features-list li {
  padding: 10px 0;
  border-top: 1px solid #333;
  display: flex;
  align-items: center;
}

.bp-features-list li:first-child {
  border-top: none;
}

.bp-checkmark-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  opacity: 0.8;
}

.bp-enterprise-tier {
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.bp-enterprise-content {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  flex-wrap: nowrap; /* Keeps items on the same row */
}

.bp-enterprise-badge {
  background-color: #16c391;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 15px;
  font-size: 1rem;
}

.bp-enterprise-text {
  font-size: 1rem;
  color: #aaa;
}

.bp-enterprise-contact {
  color: #ffffff;
  text-decoration: underline;
  margin-left: 8px;
}

.bp-enterprise-contact:hover {
  text-decoration: none;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .bp-billing-toggle {
    width: 90%;
  }

  .bp-tier {
    width: 80%;
    max-width: 350px; /* Ensure the tier boxes don't get too wide */
  }

  .bp-pricing-tiers {
    flex-direction: column; /* Stack the tiers vertically on small screens */
    align-items: center;
  }

  .bp-enterprise-badge {
    background-color: #16c391;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 15px;
    font-size: 0.9rem;
  }

  .bp-enterprise-text {
    font-size: 0.8rem;
    color: #aaa;
  }

  .bp-enterprise-content {
    flex-direction: column; /* Stack the tiers vertically on small screens */
    align-items: center;
    gap: 25px;
  }

  .bp-pricing-header h1 {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }

  .bp-billing-toggle span {
    font-size: 16px;
    padding: 8px;
  }

  .bp-plan-title-card {
    font-size: 1.8rem; /* Adjust font size as needed */
  }

  .bp-price {
    font-size: 1.8rem;
  }

  .bp-upload-text {
    font-size: 1.2rem;
  }

  .bp-cta-button {
    padding: 12px 20px;
    font-size: 0.9rem;
  }

  .bp-minutes-select {
    font-size: 0.9rem;
  }
}

/* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1280px) {
  .bp-enterprise-text {
    font-size: 0.9rem;
  }

  .bp-enterprise-content {
    flex-direction: column; /* Stack the tiers vertically on small screens */
    align-items: center;
    gap: 25px;
  }

  .bp-plan-title-card {
    font-size: 1.9rem;
  }

  .bp-price {
    font-size: 1.9rem;
  }

  .bp-upload-text {
    font-size: 1.3rem;
  }

  .bp-cta-button {
    font-size: 0.95rem;
  }

  .bp-minutes-select {
    font-size: 0.95rem;
  }
}
