/* src/components/podcastUseCase.css */

/* Container for the Podcast Use Case Section */
.podcast-use-case-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 100px 0; /* Optional padding for spacing */
    background-color: #1e1e1e;
    margin-top: 20px;
}

/* Title and Subtitle Styles */
.podcast-use-case-header {
    text-align: center;
    margin-bottom: 40px; /* Space between header and content */
}

.podcast-use-case-title {
    font-size: 2.5rem; /* Adjust as needed */
    font-weight: bold;
    color: #fff; /* White color to match dark theme */
    margin: 0;
    margin-bottom: 20px;
}

.podcast-use-case-subtitle {
    font-size: 1.8rem; /* Adjust as needed */
    color: #ccc; /* Light gray for subtitle */
    margin-top: 10px;
    max-width: 800px; /* Limit width for better readability */
    text-align: center; /* Center the subtitle text */
}

.ex-highlight-click {
    color: #4add80;
    text-decoration: none;
    font-weight: bold;
  }


.ex-highlight-click:hover {
    text-decoration: underline;

}
  

/* Wrapper Styles */
.hp-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%; /* Adjust based on your design */
    margin-top: 70px;
    margin-bottom: 90px;
}

.hp-image-section {
    width: 100%;
}

.hp-homepage-image {
    width: 90%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Trailer Button Styles */
.hp-trailer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e0e0e; /* Black background */
    padding: 20px 25px; /* Increased padding */
    border-radius: 5px; /* Adjusted border radius for larger button */
    position: absolute; /* To overlay the button on top of the image */
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%); /* Center the button */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8); /* Increased shadow for larger button */
    cursor: pointer;
    border: 0.5px solid #3e3e3e;
}

.hp-trailer-button:hover {
    background-color: #1f1f1f; /* Darker background on hover */
}

.hp-trailer-play-icon {
    width: 65px; /* Increased size */
    height: 65px; /* Increased size */
    margin-right: 8px; /* Increased space between icon and text */
}

.hp-trailer-text {
    color: #fff;
    text-align: left;
}

.hp-trailer-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem; /* Increased font size */
    font-weight: bold;
    margin: 0;
    margin-bottom: 3px;
}

.hp-trailer-duration {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem; /* Increased font size */
    margin: 0;
    color: #4add80;
}

/* Trailer Popup Styles */
.trailer-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it overlays everything */
}

.trailer-popup-content {
    position: relative;
    padding: 20px;
    max-width: 75%; /* Make the popup wider */
    width: 75%; /* Use most of the screen width */
    text-align: center;
}

.trailer-video-player {
    width: 100%; /* Make video take full width */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Info Box Styles */
.hp-info-box {
    background-color: #161616; /* Match info box background color */
    border-radius: 5px;
    padding: 10px 20px 20px 20px;
    text-align: left;
    position: absolute;
    width: 290px;
    max-height: 200px;
    overflow: hidden; /* Prevent tags from overflowing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    color: #fff; /* Ensures text is visible on dark background */
    border: 0.5px solid #3e3e3e;
}

.hp-info-box p {
    margin: 5px 0;
}

.hp-video-tags {
    top: 10px;
    left: 10px;
    transform: translate(-50%, -50%);
}

.hp-key-takeaways {
    top: 10px;
    right: 10px;
    transform: translate(50%, -50%);
}

.hp-description {
    bottom: 10px;
    left: 10px;
    transform: translate(-50%, 50%);
}

.hp-timestamps {
    bottom: 10px;
    right: 10px;
    transform: translate(50%, 50%);
}

/* Video Tags CSS */
.hp-video-tags-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0px; /* Add margin to the bottom */
    text-align: left; /* Align text to the left */
}

.video-tags-content {
    display: flex;
    flex-wrap: wrap; /* Allows tags to wrap to the next line */
    gap: 10px; /* Space between tags */
    overflow: hidden; /* Prevent overflow */
    margin-top: 20px;
}

.video-tag {
    background: #2c2c2c;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-tag:hover {
    background: #3e3e3e;
    cursor: pointer;
}

.star-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.highlighted {
    background-color: #0e0e0e;
    font-weight: bold; /* Make the font bold */
}

/* Key Takeaways */
.hp-key-takeaway-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0; /* Match existing styles */
    text-align: left;
}

.key-takeaway-content {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.key-takeaway-icon {
    margin-right: 15px; /* Space between icon and text */
    font-size: 2rem; /* Adjust size based on design */
}

.key-takeaway-text {
    font-size: 0.9rem; /* Smaller text size */
}

/* Description CSS */
.hp-description-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    text-align: left;
}

.hp-description-wrapper {
    margin-top: 20px; /* Add separation */
}

.hp-description-content {
    color: #fff; /* Adjust color based on your design */
}

/* Timestamps CSS */
.hp-timestamps-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    text-align: left;
}

.hp-timestamps-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.hp-timestamp-item {
    display: flex;
    align-items: center;
}

.hp-timestamp-time {
    color: #4add80; /* Adjust color based on your design */
    font-weight: bold;
    margin-right: 5px;
}

.hp-timestamp-description {
    color: #fff; /* Adjust color based on your design */
    white-space: pre;
}

/* Fade In Out Animation */
@keyframes hp-fade-in-out {
    0% { opacity: 0; transform: translateY(20px); }
    10% { opacity: 1; transform: translateY(0); }
    90% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}

.hp-fade-in-out {
    animation: hp-fade-in-out 10s ease-in-out infinite;
}

/* Responsive Styles */

/* Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
    .podcast-use-case-container {
        padding: 30px 0;
    }

    .podcast-use-case-title {
        font-size: 1.8rem;
        text-align: center; /* Center text */

    }

    .podcast-use-case-subtitle {
        font-size: 1rem;
        max-width: 90%;
        text-align: center; /* Center text */
        padding: 0px 20px 0px 20px;
    }

    .hp-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0px;
    }

    .hp-image-section {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: -40px;
    }

    .hp-homepage-image {
        width: 350px;
    }

    .hp-info-box {
        width: 50%;
        margin: 5px 0;
        padding: 8px 10px;
        font-size: 0.8rem;
        text-align: center;
        display: none;
    }

   
}

/* Tablets and Small Desktops (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
    .podcast-use-case-container {
        padding: 60px 0;
        margin-bottom: 30px;
    }

    .podcast-use-case-title {
        font-size: 2.8rem;
    }

    .podcast-use-case-subtitle {
        font-size: 1.6rem; /* Smaller subtitle */
        text-align: center; /* Center text */
        padding: 0px 20px 0px 20px;
    }

    .hp-wrapper {
        width: 80%; /* Keep increased width */
        display: flex;
        flex-wrap: wrap; /* Allow wrapping of boxes */
        justify-content: center; /* Center align all boxes */
        margin-bottom: 0px;
    }
    .hp-homepage-image {
        margin-top: -60px;
    }
 

    /* Modify hp-info-box to be more responsive */
    .hp-info-box {
        display: none;
    }

}




/* Large Desktops (1281px to 1800px) */
@media (min-width: 1281px) and (max-width: 1800px) {
    .podcast-use-case-container {
        padding: 40px 0;
    }

    .podcast-use-case-title {
        font-size: 2.8rem;
    }

    .podcast-use-case-subtitle {
        font-size: 1.3rem;
    }

    .hp-wrapper {
        width: 50%;
        margin-top: 80px;
    }

    .hp-info-box {
        width: 270px;
        padding: 15px 20px;
        font-size: 1rem;
    }

   
}