/* src/pages/SubscriptionPage.css */

.metasub-container {
  display: flex;
  background-color: #161616;
  min-height: 100vh;
}

/* Subscription Page Container */
.subscription-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  color: #FFFFFF;
  background-color: #161616;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  margin: 1% auto;
  max-width: 1000px;
  width: 100%; /* Ensure it takes full width of the parent */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.subscription-page-container h1 {
  font-size: 2.5rem;
  margin-bottom: 50px;
}

/* Container for SubPlanSection and OneTimeSection */
.subscription-top-sections {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensures both sections stretch to the full height */
  gap: 30px;
  margin-bottom: 30px;
}


.subscription-top-sections > * {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column; /* Allow each section to grow */
  justify-content: center; /* Ensure content inside is centered vertically */
}


/* PurchaseLog styling */
.purchase-log-wrapper {
  width: 100%;
  /* Add additional styling as needed */
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
  .subscription-top-sections {
    flex-direction: row; /* Keep row layout */
    flex-wrap: wrap; /* Allow wrapping if necessary */
  }
}

@media only screen and (max-width: 768px) {

  .subscription-page-container {
    width: 95%; 
    margin-bottom: 20px;
    padding: 20px;
  }

  .subscription-top-sections {
    flex-direction: column; /* Stack vertically on small screens */
    align-items: center;
    gap: 20px; /* Add gap between stacked sections */
    margin-bottom: 30px; /* Increased bottom margin */
  }

  .subscription-top-sections > * {
    max-width: 100%; /* Full width on smaller screens */
    flex: 1 1 100%; /* Ensure full width */
    padding: 10px; /* Add padding inside each section */
    box-sizing: border-box; /* Ensure padding is included in width */
    margin-bottom: 20px; /* Add bottom margin to each section */
  }

  .purchase-log-wrapper {
    width: 100%;
    padding: 10px; /* Add padding for better spacing */
    box-sizing: border-box;
    margin-bottom: 20px; /* Add bottom margin */
  }

  /* Additional spacing for all child components */
  .subscription-top-sections > *:not(:last-child),
  .purchase-log-wrapper {
    margin-bottom: 20px; /* Consistent spacing between sections */
  }
}
