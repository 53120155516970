/* Container Styling */
.shortcuts-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the workspace and processing sections */
    box-sizing: border-box; /* Ensure padding is included in the width */
    max-width: 1200px; /* Optional: Limit the maximum width */
    margin: 0 auto; /* Center the container horizontally */
}



.shortcuts-workspace-container {
    max-width: 500px; /* Maintain compact width */
    padding: 16px;
    background-color: #1e1e1e; /* Dark background */
    color: #f0f0f0; /* Light text for contrast */
    border-radius: 6px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 16px; /* Reduced space between sections */
    border: 0.5px solid #3e3e3e;

}

/* Hover Effect */
.shortcuts-workspace-container:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

/* Title and Badge Container */
.shortcuts-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between title and badge */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Title Styling */
.shortcuts-title {
    text-align: center;
    margin-bottom: 12px;
    font-size: 1.6em;
    font-weight: 700;
    color: #ffffff;
    margin-top: 12px;
}

/* Badge Styling */
.shortcuts-badge {
    background-color: #555; /* Match toggle highlight color */
    color: #fff; /* Dark text for contrast */
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    font-weight: 600;
    white-space: nowrap;
}

/* Description Styling */
.shortcuts-description {
    font-size: 0.85em;
    line-height: 1.5;
    color: #cccccc;
    margin-bottom: 8px;
}


/* Switches Container */
.shortcuts-switches-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Responsive grid */
    gap: 12px; /* Reduced gap between grid items */
}

/* Individual Switch Item */
.shortcuts-switch-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    border: 1px solid #333333;
    border-radius: 4px;
    background-color: #2a2a2a;
}

/* Icon Styling */
.shortcuts-icon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    object-fit: contain;
    flex-shrink: 0;
}

/* Label Styling */
.shortcuts-label {
    font-size: 0.9em;
    color: #f0f0f0;
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

/* Hide the default checkbox */
/* Hide the default checkbox */
/* Hide the default checkbox */
/* Hide the default checkbox */

.shortcuts-toggle {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

/* Custom Toggle Switch */
.shortcuts-toggle-label {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    background-color: #555555;
    border-radius: 34px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    flex-shrink: 0;
}

.shortcuts-toggle-label::before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #1e1e1e;
    border-radius: 50%;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.shortcuts-toggle:checked + .shortcuts-toggle-label {
    background-color: #4add80; /* Highlight color */
}

.shortcuts-toggle:checked + .shortcuts-toggle-label::before {
    transform: translateX(16px);
    background-color: #ffffff;
}

/* Disabled Toggle States */

/* Disabled and Checked */
.shortcuts-toggle:disabled:checked + .shortcuts-toggle-label {
    background-color: #7de6a3; /* Existing color for disabled & checked */
}

.shortcuts-toggle:disabled:checked + .shortcuts-toggle-label::before {
    background-color: #666666; /* Gray knob for the toggle */
}

/* Disabled and Unchecked */
.shortcuts-toggle:disabled:not(:checked) + .shortcuts-toggle-label {
    background-color: grey; /* Grey background for disabled & unchecked */
}

.shortcuts-toggle:disabled:not(:checked) + .shortcuts-toggle-label::before {
    background-color: #666666; /* Gray knob for the toggle */
}

/* Optional: Further Styling for Disabled Elements */
.shortcuts-switch-item:has(input:disabled) .shortcuts-label {
    color: #888888; /* Gray out the label text */
}

/* Remove or Adjust the :has() Selector if Not Needed */
.shortcuts-switch-item:has(input:disabled) {
    /* You can keep this if you want to apply general styles to disabled items */
    opacity: 0.6; /* Reduce opacity to indicate disabled state */
    pointer-events: none; /* Prevent any interaction */
}



/* Submit Button Styling */
/* Submit Button Styling */
.shortcuts-submit-button {
    align-self: center; /* Center the button horizontally */
    padding: 14px 28px; /* Increased padding for better touch targets */
    background-color: rgba(74, 221, 128, 0.15); /* Subtle green background with reduced opacity */
    color: #4add80; /* Green accent text color */
    border: 2px solid #4add80; /* Green border to define the button */
    border-radius: 8px; /* Slightly rounded corners */
    cursor: pointer;
    font-size: 1.05em; /* Slightly larger font for better readability */
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Moderate shadow for depth */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    margin-top: 8px; /* Increased space above the button */
    width: 100%; /* Full width for better accessibility */
    max-width: 480px; /* Prevent excessive width on large screens */
    display: flex; /* Enable flex layout */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    text-transform: uppercase; /* Uppercase text for emphasis */
}

/* Icon Styling */
.shortcuts-submit-button .shortcuts-icon {
    width: 24px; /* Adjust icon size as needed */
    height: 24px;
    object-fit: contain; /* Ensure the icon fits well */
    opacity: 0.9;
}

/* Hover State */
.shortcuts-submit-button:hover {
    background-color: rgba(74, 221, 128, 0.25); /* Slightly more opaque green on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
    border-color: #4add80; /* Maintain border color */
}

/* Active State */
.shortcuts-submit-button:active {
    transform: scale(0.98); /* Slight shrink to indicate click */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
    background-color: rgba(74, 221, 128, 0.35); /* More opaque on active */
}

/* Disabled State */
.shortcuts-submit-button:disabled {
    background-color: rgba(74, 221, 128, 0.05); /* Very subtle green background */
    color: rgba(74, 221, 128, 0.6); /* Muted green text */
    border-color: rgba(74, 221, 128, 0.6); /* Muted green border */
    cursor: not-allowed;
    opacity: 0.7;
    box-shadow: none; /* Remove shadow when disabled */
    transform: none; /* No scaling when disabled */
}

/* Focus State for Accessibility */
.shortcuts-submit-button:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 3px rgba(74, 221, 128, 0.5); /* Custom focus ring */
}



/* New Shortcuts Processing Section */

/* Container Styling */
.shortcuts-processing-container {
    background-color: #1e1e1e;
    color: #f0f0f0;
    border-radius: 6px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #3e3e3e;
    box-shadow: 0px 4px 12px rgba(0,0,0,0.1);
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow: hidden; /* Ensure content doesn't overflow */
}

.shortcuts-link-no-underline {
    text-decoration: none;
}

/* Processing Card */
.shortcuts-processing-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

/* Content Styling */
.shortcuts-processing-content {
    text-align: left;
    flex: 1;
}

/* Title Section Styling */
.shortcuts-title-section {
    font-size: 1rem;
    margin: 5px 0;
    font-weight: 600;
}

/* Description Section Styling */
.shortcuts-description-section {
    font-size: 0.85rem;
    color: #ddd;
    margin-bottom: 8px;
}

/* Button Styling */
.shortcuts-processing-start-btn {
    background-color: transparent;
    color: #4add80;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 600;
    text-align: left;
    display: inline-block;
    border-radius: 4px;
    transition: background-color 0.3s ease, text-decoration 0.3s ease;
}

.shortcuts-processing-start-btn:hover {
    text-decoration: underline;
}



.shortcuts-upload-language {
    width: 36px; /* Adjusted for compactness */
    height: 36px; /* Adjusted for compactness */
    object-fit: contain;
}

/* Responsive Design */
@media (max-width: 768px) {
    .shortcuts-main-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 350px; /* Maintain compact width */

    }

    .shortcuts-description {
        margin-top: -10px;
        font-size: 0.7em;
    }

    .shortcuts-processing-container {
        display: none;    
        }
    
}


@media (min-width: 769px) and (max-width: 1280px) {


}