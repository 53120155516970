/* Purchase Log Box Styles */
.purlog-purchase-log-box {
    margin: auto;
    background-color: #0e0e0e;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
    border: 0.5px solid #3e3e3e;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Title Styles */
.purlog-title {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #ffffff;
    text-align: left;
}

/* Header Row Styles */
.purlog-header-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #3e3e3e;
    padding-bottom: 10px;
    font-weight: 600;
    color: #ffffff;
}

/* Header Column Styles */
.purlog-header-col {
    flex: 1;
    text-align: center;
}

/* Data Row Styles */
.purlog-data-row {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center all row items */
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #3e3e3e;
}

/* Data Column Styles */
.purlog-data-col {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    color: #ffffff;
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
}

/* Credits Styles */
.purlog-credits {
    font-weight: 600;
}

/* Tier Column Styles */
.purlog-tier {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Badge Styles */

/* Base Badge */
.purlog-badge-base {
    background-color: #1e1e1e;
    color: #ffbf00;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    white-space: nowrap;
}

/* Bump Badge */
.purlog-badge-bump {
    background-color: #1e1e1e;
    color: #4add80;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    white-space: nowrap;
}

/* Grey Badge for Type Column */
.purlog-badge-type-grey {
    background-color: #4a4a4a;
    color: #e0e0e0;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    white-space: nowrap;
}

/* Optional: Default Badge for unforeseen tiers */
.purlog-badge-default {
    background-color: #1e1e1e;
    color: #ffffff;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    white-space: nowrap;
}

/* Credits Color Styles */

/* Base Credits */
.purlog-credits-base {
    color: #ffbf00;
}

/* Bump Credits */
.purlog-credits-bump {
    color: #4add80;
}

/* Optional: Default Credits for unforeseen tiers */
.purlog-credits-default {
    color: #ffffff;
}

/* Date Styles */
.purlog-date {
    font-weight: 600;
}

/* Pagination Controls */
.purlog-pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.purlog-pagination-info {
    color: #fff;
    font-size: 18px;
    margin-right: 16px;
}

.purlog-pagination-button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 8px;
    margin: 0 4px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.purlog-pagination-button:disabled {
    color: #555;
    opacity: 0.5;
    cursor: default;
}

.purlog-pagination-button:hover:not(:disabled) {
    color: #ccc;
}

.purlog-arrow {
    border: solid #fff;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 3px;
}

.purlog-outside-arrow {
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
}

/* Arrow pointing left */
.purlog-arrow.purlog-left,
.purlog-outside-arrow.purlog-double-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

/* Arrow pointing right */
.purlog-arrow.purlog-right,
.purlog-outside-arrow.purlog-double-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .purlog-purchase-log-box {
        width: 90%;
        padding: 15px;
    }

    .purlog-header-row,
    .purlog-data-row {
        flex-direction: row;
        align-items: flex-start;
    }

    .purlog-header-col,
    .purlog-data-col {
        text-align: left;
        width: 100%;
    }

    .purlog-pagination-controls {
        flex-direction: column;
        gap: 10px;
    }

    /* Hide the "Date" header column */
    .purlog-date-col {
        display: none;
    }

    /* Hide the "Date" data columns */
    .purlog-data-col.purlog-date {
        display: none;
    }
}
