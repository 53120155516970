/* Scoped Box Sizing */
.workprompt-container *,
.workprompt-container *::before,
.workprompt-container *::after {
  box-sizing: border-box;
}


/* Container Styling */
.workprompt-container {
  background: #1c1c1e; /* Apple's dark background */
  color: #ffffff; /* White text */
  padding: 40px 20px; /* Reduced horizontal padding */
  border-radius: 5px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centered alignment */
  width: 100%; /* Adjusted width for better responsiveness */
  justify-content: center;
  border: 1px solid #3e3e3e;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Title Styling */
.workprompt-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.workprompt-beta-badge {
  background-color: rgba(74, 221, 128, 0.15); /* Subtle green background */
  color: #4add80; /* Green accent color */
  font-size: 0.9rem;
  font-weight: bold;
  padding: 4px 6px;
  border-radius: 4px;
  margin-left: 10px;
}

/* Introductory Text */
.workprompt-intro {
  font-size: 1.1rem;
  margin-bottom: 30px;
  line-height: 1.6;
  text-align: center;
  color: #8e8e93; /* Apple's label text color */
}

/* Tabs Navigation */
.workprompt-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #3a3a3c; /* Darker border for separation */
}

.workprompt-tabButton {
  background: none;
  border: none;
  color: #8e8e93; /* Inactive tab color */
  padding: 12px 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.workprompt-tabButton:hover {
  color: #ffffff; /* White text on hover */
}

.workprompt-tabButton.active {
  color: #4add80; /* Green accent color for active tab */
  border-bottom: 3px solid #4add80;
}

/* Content Area */
.workprompt-content {
  background-color: #1c1c1e; /* Updated Apple's secondary background */
  padding: 30px;
  border-radius: 12px;
  transition: opacity 0.3s ease;
  overflow: auto;
  width: 90%; /* Adjusted width for better responsiveness */
  color: #ffffff;
}

/* Prompt Box Styling */
.workprompt-promptBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; /* Ensure full width */
}

/* Prompt Box Inner for positioning loading overlay */
.workprompt-promptBoxInner {
  position: relative; /* To position the loading overlay absolutely within */
}

/* Input Container Styling */
.workprompt-input-container {
  display: flex;
  align-items: stretch; /* Change from center to stretch */
  background: #3a3a3c;
  border-radius: 8px;
  overflow: hidden;
}

/* Textarea Styling */
.workprompt-textarea {
  flex: 1;
  padding: 15px;
  background: #3a3a3c; /* Apple's tertiary background */
  color: #ffffff;
  border: none; /* Remove default borders */
  border-radius: 8px 0 0 8px; /* Rounded left corners */
  resize: none;
  font-size: 1rem;
  min-height: 40px; /* Minimum height */
  max-height: 150px; /* Maximum height before scrolling */
  overflow-y: hidden;
  outline: none;
}

.workprompt-textarea::placeholder {
  color: #6c6c70; /* Apple's placeholder text color */
}

.workprompt-textarea:focus {
  background: #48484a; /* Slightly lighter background on focus */
}

/* Submit Button Styling */
.workprompt-submitButton {
  background: #4add80; /* Green accent color */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px;
  border-radius: 0 8px 8px 0; /* Rounded right corners */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Fixed width for consistency */
  min-height: 90px;
}

.workprompt-submitButton:disabled {
  background: #545458; /* Disabled state */
  cursor: not-allowed;
}

.workprompt-submitButton:not(:disabled):hover {
  background: #3ac16e; /* Slightly darker green on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

/* Submit Button Icon Styling */
.submit-button-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  object-fit: contain;
}


/* Character Count Styling */
.workprompt-charCount {
  align-self: flex-end;
  font-size: 0.9rem;
  color: #8e8e93; /* Apple's label text color */
  margin-bottom: 5px;
  margin-top: 10px;
}

/* Examples Section */
.workprompt-examples {
  margin-top: 20px;
  text-align: center;
  background-color: #1c1c1e; /* Distinct background color */
  border-radius: 12px; /* Rounded corners */
  padding: 20px; /* Inner padding */
  color: #ffffff;
}

.workprompt-examples-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #ffffff;
}

.workprompt-example-text {
  margin-top: 20px;
  font-size: 1rem;
  color: #8e8e93; /* Label text color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.workprompt-example-badge {
  background-color: #48484a; /* Badge background */
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 8px;
}

/* Copy Icon Styling */
.workprompt-copyButton:focus {
  outline: none;
}

.workprompt-copyIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.workprompt-copyButton:hover .workprompt-copyIcon {
  transform: scale(1.1);
}

/* Checkmark Icon Styling */
.workprompt-checkmarkIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  animation: fadeInOut 3s forwards;
}

/* Animation for Checkmark */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

/* Read-Only Container Styling */
.workprompt-readOnlyContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Read-Only Input Styling */
.workprompt-readOnlyInput {
  display: flex;
  flex-direction: column;
}

/* Read-Only Content Styling */
.workprompt-readOnlyContent {
  position: relative; /* Allow absolute positioning within */
  background-color: #2c2c2e; /* Slightly different background */
  padding: 15px;
  padding-right: 50px; /* Increased padding to prevent overlap */
  border-radius: 8px;
  min-height: 40px; /* Minimum height */
}

/* Read-Only Text Styling */
.workprompt-readOnlyText {
  font-size: 1rem;
  color: #ffffff; /* Slightly lighter text color */
  white-space: pre-wrap; /* Preserve formatting */
  overflow-wrap: break-word; /* Allow breaking long words */
  /* Removed padding-right: 50px; as it's now handled by the parent */
}

/* Copy Button Styling within Read-Only Content */
.workprompt-readOnlyContent .workprompt-copyButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1; /* Ensure the button stays above the text */
}



/* Loading Overlay Styling */
.workprompt-loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 28, 30, 0.95); /* Semi-transparent dark overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 10; /* Ensure it sits above other elements */
}

/* Loading GIF Styling */
.workprompt-loadingGif {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

/* Loading Text Styling with Animated Dots */
.workprompt-loadingText {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.workprompt-loadingDots {
  display: inline-block;
  margin-left: 5px;
  animation: loadingDots 1s infinite;
}

@keyframes loadingDots {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '';
  }
}

/* Ensuring the loading text animates the dots */
.workprompt-loadingDots::after {
  content: '';
  display: inline-block;
  animation: dots 1s steps(3, end) infinite;
}

@keyframes dots {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}


/* Shortcut Output CSS */
/* Shortcut Output CSS */
/* Shortcut Output CSS */
/* Shortcut Output CSS */

/* Container for Details Tab */
.shortcutoutput-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* Top Section with Two Columns */
.shortcutoutput-top-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Container to center the button */
.ws-reload-button-container {
  display: flex;
  justify-content: center; /* Horizontally centers the button */
}

/* Button styling */
.ws-shortcut-reload-button {
  display: flex;
  align-items: center;
  background-color: #161616; /* Base color */
  color: #ffffff; /* Text color for contrast */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px; /* Ensures readability */
  font-family: Arial, sans-serif; /* Professional font */
  border: 0.5px solid #3e3e3e;
}

/* Hover effect for the button */
.ws-shortcut-reload-button:hover {
  background-color: #333333; /* Slightly lighter shade for subtlety */
}

/* Icon styling */
.ws-shortcut-reload-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Disabled state for the reload button */
.ws-shortcut-reload-button:disabled {
  background-color: #3e3e3e; /* Darker gray to indicate disabled state */
  color: #a0a0a0;            /* Lighter text color for contrast */
  cursor: not-allowed;       /* Changes cursor to indicate no action */
  opacity: 0.6;              /* Slightly transparent to emphasize disabled state */
  border: 0.5px solid #2e2e2e; /* Darker border to match disabled state */
}

/* Ensure no hover effects apply when the button is disabled */
.ws-shortcut-reload-button:disabled:hover {
  background-color: #3e3e3e; /* Maintains the disabled background color */
}

/* TIMESTAMP Output CSS */
/* TIMESTAMP Output CSS */
/* TIMESTAMP Output CSS */

.ws-shortcut-timestamps-box {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  max-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #3e3e3e;
}

.ws-shortcut-timestamps-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.ws-shortcut-timestamps-title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.ws-shortcut-copy-summary-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.ws-shortcut-copy-icon {
  width: 25px;
  height: 25px;
  opacity: 0.8;
}

.ws-shortcut-timestamps-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ws-shortcut-timestamp {
  background-color: rgba(51, 51, 51, 0.2);
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: transform 0.3s, background-color 0.3s;
}

.ws-shortcut-timestamp-number {
  font-weight: bold;
  color: #4add80;
  margin-right: 5px;
}

.ws-shortcut-timestamp-description {
  color: #fff;
  flex: 1;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.ws-shortcut-timestamp:hover {
  transform: scale(1.01);
  background-color: rgba(51, 51, 51, 0.4);
}

.ws-shortcut-timestamps-box::-webkit-scrollbar {
  width: 8px;
}

.ws-shortcut-timestamps-box::-webkit-scrollbar-track {
  background: #2c2c2c;
  border-radius: 5px;
}

.ws-shortcut-timestamps-box::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.5);
  border-radius: 5px;
}

.ws-shortcut-timestamps-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba(119, 119, 119, 0.5);
}

/* SUMMARY Output CSS */
/* SUMMARY Output CSS */
/* SUMMARY Output CSS */

.ws-shortcut-summary-box {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  border: 0.5px solid #3e3e3e;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ws-shortcut-summary-box::-webkit-scrollbar {
  width: 8px;
}

.ws-shortcut-summary-box::-webkit-scrollbar-track {
  background: #2c2c2c;
  border-radius: 5px;
}

.ws-shortcut-summary-box::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.5);
  border-radius: 5px;
}

.ws-shortcut-summary-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba(119, 119, 119, 0.5);
}

.ws-shortcut-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.ws-shortcut-summary-title-container {
  display: flex;
  align-items: center;
}

.ws-shortcut-summary-title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.ws-shortcut-action-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ws-shortcut-copy-summary-btn {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 5px;
  border-radius: 8px;
}

.ws-shortcut-copy-summary-btn:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.ws-shortcut-copy-summary-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ws-shortcut-copy-icon {
  width: 20px;
  height: 20px;
}

.ws-shortcut-summary-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgba(44, 44, 44, 0.4);
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  min-height: 100px;
  position: relative;
  width: 100%;
}

.ws-shortcut-summary {
  color: #fff;
  line-height: 1.5;
  text-align: left;
  transition: filter 0.3s;
  font-size: 16px;
  word-wrap: break-word;
}

.ws-shortcut-reload-logo {
  opacity: 0.8;
  width: 45px;
  height: 45px;
}

/* TAKEAWAY Output CSS */

.ws-shortcut-takeaways-box {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  border: 0.5px solid #3e3e3e;
}

.ws-shortcut-takeaways-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ws-shortcut-takeaways-title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.ws-shortcut-copy-summary-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.ws-shortcut-copy-icon {
  width: 20px;
  height: 20px;
}

.ws-shortcut-takeaways-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ws-shortcut-takeaway {
  display: flex;
  align-items: center;
  background-color: rgba(44, 44, 44, 0.4); /* Less opaque background for the content */
  border-radius: 5px;
  padding: 10px 15px;
}

.ws-shortcut-takeaway-emoji {
  font-size: 2.0em;
  margin-right: 18px;
}

.ws-shortcut-takeaway-text {
  color: #fff;
  line-height: 1.5;
  text-align: left;
}

.ws-shortcut-reload-logo {
  opacity: 0.8;
  width: 45px;
  height: 45px;
}

.ws-shortcut-titles-box {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  border: 0.5px solid #3e3e3e;
}

.ws-shortcut-titles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ws-shortcut-titles-title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.ws-shortcut-copy-summary-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.ws-shortcut-copy-icon {
  width: 20px;
  height: 20px;
}

.ws-shortcut-titles-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ws-shortcut-takeaway {
  display: flex;
  align-items: center;
  background-color: rgba(44, 44, 44, 0.4);
  border-radius: 5px;
  padding: 10px 15px;
}

.ws-shortcut-takeaway-text {
  color: #fff;
  line-height: 1.5;
  text-align: left;
}

.ws-shortcut-reload-logo {
  opacity: 0.8;
  width: 45px;
  height: 45px;
}

.ws-shortcut-hashtags-box {
  background: #161616;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  border: 0.5px solid #3e3e3e;
}

.ws-shortcut-hashtags-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.ws-shortcut-hashtags-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
}

.ws-shortcut-copy-section {
  display: flex;
  align-items: center;
}

.ws-shortcut-hashtags-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ws-shortcut-hashtag {
  background: #2c2c2c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ws-shortcut-hashtag:hover {
  background: #3e3e3e;
  cursor: pointer;
}

.ws-shortcut-copy-summary-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.ws-shortcut-copy-icon {
  width: 25px;
  height: 25px;
  opacity: 0.7;
}

.ws-shortcut-copy-summary-btn:hover .ws-shortcut-copy-icon {
  opacity: 1;
}

.highlighted {
  background-color: #0e0e0e;
  font-weight: bold;
}

.ws-shortcut-star-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.ws-shortcut-reload-logo {
  opacity: 0.8;
  width: 45px;
  height: 45px;
}


/* Disclaimer Styling */
.workprompt-disclaimer {
  font-size: 0.85rem; /* Slightly smaller font */
  color: #8e8e93; /* Apple's label text color */
  margin-top: 30px; /* Space above the disclaimer */
  text-align: center;
  opacity: 0.7; /* Light opacity for subtlety */
}




@media (max-width: 768px) {
  /* Container */
  .workprompt-container {
    border: 0.5px solid #3e3e3e;
    /* border-radius: px; */
    margin-bottom: 0px;
    padding: 0; /* Remove extra padding */
    width: 100vw; /* Full viewport width */
  }

  
  .workprompt-content {
    padding: 5px;
    width: 95%;
    height: auto;
  }
  
  /* Title */
  .workprompt-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  /* Tabs */
  .workprompt-tabs {
    flex-wrap: wrap;
  }

  .workprompt-tabButton {
    padding: 10px;
    font-size: 0.9rem;
  }

  /* Prompt Box */
  .workprompt-promptBox {
    width: 100%; /* Take full width on mobile */
    max-width: 100vw; /* Ensure it doesn't exceed viewport width */
  }

  .workprompt-promptBoxInner {
    width: 100%; /* Ensure inner box also takes full width */
    max-width: 100vw;
  }

  /* Text Area */
  .workprompt-textarea {
    padding: 12px;
    font-size: 0.9rem;
    width: calc(100% - 50px); /* Adjust to fit within the container with button */
  }

  /* Submit Button */
  .workprompt-submitButton {
    width: 45px;
    padding: 10px;
  }

  /* Read-Only Text */
  .workprompt-readOnlyText {
    font-size: 0.9rem;
    width: 100%; /* Full width for the read-only text */
  }

  /* Loading Overlay */
  .workprompt-loadingOverlay {
    padding: 15px;
  }

  .workprompt-examples,
  .workprompt-intro
  {
    display: none;
  }

}








@media (min-width: 769px) and (max-width: 1280px) {
  /* Container Adjustments */
  .workprompt-container {
    padding: 30px 20px;
    margin: 20px;
    border-radius: 10px;
  }

  /* Title Styling */
  .workprompt-title {
    font-size: 1.8rem;
    text-align: center;
  }

  .workprompt-beta-badge {
    font-size: 0.9rem;
    padding: 4px 6px;
  }

  /* Introductory Text */
  .workprompt-intro {
    font-size: 1.05rem;
    margin-bottom: 25px;
    line-height: 1.5;
  }

  /* Tabs Navigation */
  .workprompt-tabs {
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #3a3a3c;
  }

  .workprompt-tabButton {
    padding: 12px 18px;
    margin-right: 10px;
    font-size: 1rem;
  }

  .workprompt-tabButton.active {
    border-bottom: 3px solid #4add80;
  }

  /* Content Area */
  .workprompt-content {
    padding: 25px;
    width: 95%;
    height: auto;
  }

  /* Prompt Box Adjustments */
  .workprompt-promptBoxInner {
    position: relative;
  }

  /* Input Container */
  .workprompt-input-container {
    flex-direction: row;
  }

  .workprompt-textarea {
    padding: 14px;
    font-size: 1rem;
    min-height: 60px;
    max-height: 150px;
  }

  .workprompt-submitButton {
    width: 60px;
    padding: 12px;
  }

  /* Character Count */
  .workprompt-charCount {
    font-size: 0.9rem;
    margin-top: 10px;
  }

  /* Examples Section */
  .workprompt-examples {
    padding: 20px;
  }

  .workprompt-examples-title {
    font-size: 1.2rem;
  }

  .workprompt-example-text {
    font-size: 0.9rem;
  }

  .workprompt-example-badge {
    font-size: 0.8rem;
    padding: 4px 8px;
  }

  /* Loading Overlay */
  .workprompt-loadingOverlay {
    padding: 15px;
  }

  .workprompt-loadingGif {
    width: 60px;
    height: 60px;
  }

  .workprompt-loadingText {
    font-size: 1.1rem;
  }

  /* ShortCutsTab Adjustments */
  .shortcutoutput-details-container {
    gap: 20px;
  }

  .shortcutoutput-top-section {
    flex-direction: row;
    gap: 20px;
  }

  /* Reload Button */
  .ws-shortcut-reload-button {
    width: auto;
    padding: 10px 20px;
    font-size: 1rem;
  }

  /* Scrollbars */
  .ws-shortcut-timestamps-box,
  .ws-shortcut-summary-box,
  .ws-shortcut-takeaways-box,
  .ws-shortcut-titles-box,
  .ws-shortcut-hashtags-box {
    max-height: 350px;
  }

  /* Hashtags */
  .ws-shortcut-hashtags-content {
    justify-content: flex-start;
  }

  .ws-shortcut-hashtag {
    padding: 10px;
    font-size: 0.9rem;
  }

  /* Copy Icons */
  .workprompt-copyIcon,
  .workprompt-checkmarkIcon,
  .ws-shortcut-copy-icon {
    width: 22px;
    height: 22px;
  }

  /* Other Adjustments as Needed */
  /* Ensure all elements are proportionate and well-spaced */
}
