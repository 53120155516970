/* src/components/TemplateWorkspace/workspaceUploads/WorkspaceHeader.css */

/* Container for the header */
.workspace-header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px; /* Match the width of template-row-container */
  margin: 0 auto; /* Center the container */
  margin-bottom: 20px;
}

/* Container for Title Input and Save Button */
.title-save-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* CSS for aligning elements in a row within the title-input-wrapper */
.title-input-wrapper {
  display: flex; /* Enables Flexbox layout */
  flex-direction: row; /* Arranges children horizontally */
  align-items: center; /* Vertically centers the items */
  gap: 10px; /* Adds space between the input and the badge */
  width: 400px; /* Limit the input width */
}

/* Workspace Title Input */
.workspace-title-input {
  width: 100%; /* Allow input to take up available space up to max-width */
  padding: 10px 14px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #3a3a3a;
  color: #fff;
  font-size: 16px;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
  outline: none; /* Disable blue outline */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.workspace-title-input:focus {
  outline: none; /* Ensures no outline when focused */
  border-color: #4add80; /* Updated to green color */
  background-color: #3a3a3a; /* Slightly lighter background on focus */
  box-shadow: 0 0 8px rgba(74, 221, 128, 0.6); /* Updated to green shadow */
}

/* Placeholder text color */
.workspace-title-input::placeholder {
  color: #aaa;
}

/* Red outline when max length is reached */
.workspace-title-input.input-max {
  border-color: #ff3b30; /* iOS red color for errors */
}

/* General Badge Styles */
.badge {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #ffffff; /* Updated to white text */
  gap: 5px;
  margin-left: 6px;

  white-space: nowrap; /* Prevents text from wrapping */
  flex-wrap: nowrap;   /* Ensures flex items don't wrap */
}

a.badge {
  text-decoration: none;
}

/* Draft Badge Modifier */
.badge-draft {
  background-color: #4a4a4a; /* Dark background for the badge */
}

/* Need Title Badge Modifier */
.badge-need-title {
  background-color: #f66767; /* Softer red background color */
  opacity: 0.8;
}

/* Active Badge Modifier */
.badge-active {
  background-color: rgba(74, 221, 128, 0.15); /* Subtle green background */
  color: #4add80; /* Green accent color */
}

.badge-uploading {
  background-color: #1e1e1e;
  color: #ffbf00;
}

.badge-error {
  background-color: #f66767; /* Softer red background color */
  opacity: 0.8;
  font-weight: bold;
}



/* Icon inside the Need Title Badge */
.need-title-icon {
  width: 16px;
  height: 16px;
}

/* Icon inside the Active Badge */
.active-icon-workspace {
  width: 18px;
  height: 18px;
}

/* Draft Icon Styles */
.draft-icon {
  width: 16px;
  height: 16px;
}

/* Save Workspace Button */
/* Save Workspace Button */
.save-workspace-button {
  padding: 12px 24px;
  background-color: #2a2a2a; /* Darker button to match #161616 background */
  color: #f0f0f0; /* Light text for contrast */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.05em;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  height: 50px; /* Match input height */
}

/* Apply hover styles only when not disabled */
.save-workspace-button:not(:disabled):hover {
  background-color: #3a3a3a; /* Slightly lighter on hover */
}

/* Apply active styles only when not disabled */
.save-workspace-button:not(:disabled):active {
  background-color: #4a4a4a; /* Even lighter on active */
  transform: scale(0.98);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Disabled Save button styling */
.save-workspace-button:disabled,
.save-workspace-button[aria-disabled='true'] {
  background-color: #5a5a5a; /* Greyed out background */
  color: #7f7f7f; /* Greyed out text */
  cursor: default;
  box-shadow: none;
  transform: none; /* Prevent any transformation */
  /* Optionally, you can disable pointer events */
  pointer-events: none;
  /* Ensure no hover or active styles apply */
  /* Alternatively, you can reinforce by resetting properties */
}

/* Optional: Remove any transitions for disabled state */
.save-workspace-button:disabled,
.save-workspace-button[aria-disabled='true'] {
  transition: none;
}


/* Additional Styles for Enhanced Input Box */

/* Styles when the input has a title other than default */
.workspace-title-input.has-title {
  background-color: #2a2a2a; /* Slightly different background to indicate it's set */
  border-color: #6a6a6a; /* Slightly different border color */
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.05); /* Subtle inner shadow */
}

/* Styles when the input is focused */
.workspace-title-input.input-focused {
  border-color: #4add80; /* Updated to green color */
  background-color: #3a3a3a; /* Slightly lighter background on focus */
  box-shadow: 0 0 6px rgba(74, 221, 128, 0.4); /* Reduced intensity on the green shadow */
  transition: border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Combined styles when input has a title and is focused */
.workspace-title-input.has-title.input-focused {
  background-color: #4a4a4a; /* Even lighter when focused and has title */
  border-color: #4add80; /* Updated to green color */
  box-shadow: 0 0 8px rgba(74, 221, 128, 0.5); /* Softer green shadow */
}

/* Optional: Adjust placeholder color when input has a title */
.workspace-title-input.has-title::placeholder {
  color: #888; /* Darker placeholder text */
}

.workspace-title-input.input-error {
  border-color: #ff3b30; /* iOS red color for errors */
  background-color: #3a3a3a;
  box-shadow: 0 0 6px rgba(255, 59, 48, 0.5); /* Softer red shadow for emphasis */
}

/* Loading state styling */
.workspace-header-loading {
  /* Customize as needed */
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}


@media (max-width: 768px) {
  .workspace-header-container {
    width: 100%; /* Ensure it takes full width */
  }

  .title-save-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch; /* Ensure full width for input and button */
    gap: 15px; /* Add space between input and button */
  }

  .title-input-wrapper {
    width: 100%; /* Input takes full width */
  }

  .workspace-title-input {
    font-size: 14px; /* Reduce font size for smaller screens */
    padding: 8px 12px; /* Adjust padding for smaller input */
    height: 45px; /* Adjust height for consistency */
  }

  .save-workspace-button {
    width: 100%; /* Make button take full width */
    font-size: 1em; /* Adjust font size */
    padding: 12px; /* Ensure sufficient padding */
  }

  .badge {
    font-size: 12px; /* Smaller font for badges */
    padding: 4px 8px; /* Adjust padding */
  }

  .active-icon-workspace,
  .need-title-icon,
  .draft-icon {
    width: 14px; /* Reduce icon size slightly */
    height: 14px;
  }
}



@media (min-width: 769px) and (max-width: 1280px) {
  /* Container for the header */
 

  .workspace-header-container {
    max-width: 900px; /* Match the width of template-row-container */
  }
  /* Container for Title Input and Save Button */
  .title-save-container {
    flex-direction: row; /* Maintain horizontal layout */
    justify-content: space-between; /* Space between input and button */
    align-items: center;
    margin-bottom: 10px;

  }

  /* CSS for aligning elements in a row within the title-input-wrapper */
 
  /* Workspace Title Input */
  .workspace-title-input {
    width: 100%; /* Flexibly take available space */
    font-size: 16px; /* Maintain readable font size */
    padding: 10px 14px; /* Adjust padding */
    height: 45px; /* Slightly increased height */
  }

  /* Save Workspace Button */
  .save-workspace-button {
    width: auto; /* Adjust to content */
    font-size: 1.05em; /* Maintain font size */
    padding: 12px 24px; /* Maintain padding */
    height: 50px; /* Maintain height */
  }

  /* Badge Adjustments */
  .badge {
    font-size: 14px; /* Maintain text size */
    padding: 5px 10px; /* Maintain padding */
  }

  .active-icon-workspace,
  .need-title-icon,
  .draft-icon {
    width: 16px; /* Maintain icon size */
    height: 16px;
  }

  /* Optional: Adjust margins if necessary */
  .badge-need-title,
  .badge-draft,
  .badge-active {
    margin-left: 6px; /* Maintain left margin */
  }
}