/* src/components/WorkspaceDashboard/workspaceDashboard.css */

.workspace-dashboard-container {
    display: flex;
    background-color: #161616;
    min-height: 100vh;
}

.workspace-dashboard-header {
    padding: 20px 40px;
    background-color: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #333;
}

.workspace-dashboard-header h1 {
    margin: 0;
    font-size: 24px;
}

.workspace-dashboard-main {
    flex: 1;
}

.workspace-dashboard-footer {
    padding: 10px 40px;
    background-color: #1f1f1f;
    text-align: center;
    border-top: 1px solid #333;
}

.workspace-dashboard-footer p {
    margin: 0;
    font-size: 14px;
    color: #aaa;
}

/* Responsive Adjustments */

@media (max-width: 768px) {
    .workspace-dashboard-header, 
    .workspace-dashboard-main,
    .workspace-dashboard-footer {
        padding: 10px 20px;
    }

    .workspace-dashboard-header h1 {
        font-size: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .workspace-dashboard-header h1 {
        font-size: 22px;
    }
}

@media (min-width: 1281px) and (max-width: 1800px) {
    .workspace-dashboard-header h1 {
        font-size: 24px;
    }
}
