/* Prompt Output Container */
.prompt-output {
  text-align: left;
  background-color: #1c1c1e;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  position: relative;
}

/* Inner Container for Markdown */
.prompt-output-text {
  position: relative;
}

/* Copy Button Styling */
.prompt-copyButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.prompt-copyButton:hover .prompt-copyIcon,
.prompt-copyButton:hover .prompt-checkmarkIcon {
  opacity: 1;
}

.prompt-copyIcon,
.prompt-checkmarkIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.prompt-checkmarkIcon {
  animation: fadeInOut 3s forwards;
}

/* Animation for Checkmark */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

/* Markdown Styling */
.prompt-markdown {
  color: #d4d4d8;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

/* Headings */
.prompt-markdown h1,
.prompt-markdown h2,
.prompt-markdown h3,
.prompt-markdown h4,
.prompt-markdown h5,
.prompt-markdown h6 {
  color: #ffffff;
  margin-top: 1em;
  margin-bottom: 0.3em;
  font-weight: 600;
}

.prompt-markdown h1 {
  font-size: 2em;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 0.3em;
}

.prompt-markdown h2 {
  font-size: 1.75em;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 0.2em;
}

/* Code Blocks */
.prompt-markdown pre {
  background-color: #1e1e1e; /* VS Code-like background */
  color: #d4d4d8;
  padding: 10px;
  border-radius: 8px;
  white-space: pre-wrap !important; /* Ensures lines wrap */
  word-wrap: break-word !important; /* Break long words */
  overflow-x: hidden !important; /* Disable horizontal scrolling */
  max-width: 100% !important; /* Ensure pre blocks never exceed the container */
}

/* Ensure inner code in pre blocks wraps too */
.prompt-markdown pre code {
  background: none;
  color: inherit;
  white-space: pre-wrap !important; /* Ensures code lines wrap */
  word-wrap: break-word !important; /* Break long words */
}

/* Inline Code */
.prompt-markdown code {
  background-color: rgba(44, 44, 46, 0.15);
  color: #d4d4d8;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Source Code Pro', monospace;
  font-size: 0.95em;
  white-space: normal !important; /* Allow wrapping for inline code */
  word-wrap: break-word !important; /* Ensure long words wrap */
}

/* Color Differentiation for Syntax Highlighting */
.syntax-highlighter-code {
  background-color: #1e1e1e; /* Dark background similar to VS Code */
  color: #d4d4d8; /* Default code text color */
}

.syntax-highlighter-code .token.keyword {
  color: #c586c0; /* Purple for keywords */
}

.syntax-highlighter-code .token.string {
  color: #ce9178; /* Orange for strings */
}

.syntax-highlighter-code .token.function {
  color: #dcdcaa; /* Yellow for function names */
}

.syntax-highlighter-code .token.comment {
  color: #6a9955; /* Green for comments */
  font-style: italic;
}

.syntax-highlighter-code .token.variable {
  color: #9cdcfe; /* Light blue for variables */
}

.syntax-highlighter-code .token.number {
  color: #b5cea8; /* Light green for numbers */
}

/* Blockquotes */
.prompt-markdown blockquote {
  border-left: 4px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 8px 16px;
  margin: 1em 0;
  color: #c7c7cc;
  font-style: italic;
  border-radius: 5px;
}

/* Lists */
.prompt-markdown ul,
.prompt-markdown ol {
  margin: 0.8em 0;
  padding-left: 1.2em;
  color: #d4d4d8;
}

.prompt-markdown li {
  margin-bottom: 0.3em;
  line-height: 1.4;
}

/* Links */
.prompt-markdown a {
  color: #4add80;
  text-decoration: none;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.prompt-markdown a:hover {
  color: #6bf29a;
  text-decoration: underline;
}

/* Images */
.prompt-markdown img {
  display: none;
}

/* Horizontal Rules */
.prompt-markdown hr {
  border: none;
  border-top: 1px solid #ffffff;
  margin: 2em 0;
}


/* Table Styling */
.prompt-markdown table {
  width: 100%; /* Ensure the table takes up the full width of the container */
  table-layout: auto; /* Allow columns to adjust their width automatically */
  border-collapse: collapse; /* Make sure the table borders are collapsed */
  margin: 1em 0;
}

/* Table headers and cells */
.prompt-markdown th, 
.prompt-markdown td {
  border: 0.5px solid #ffffff; /* Light borders to match your dark theme */
  padding: 8px 12px;
  text-align: left;
  word-wrap: break-word; /* Enable word wrap inside table cells */
}

/* Header styling */
.prompt-markdown th {
  background-color: #2d2d2d; /* Dark background for table headers */
  color: #ffffff; /* White text for headers */
}

/* Cell styling */
.prompt-markdown td {
  background-color: #1e1e1e; /* Dark background for table cells */
  color: #d4d4d8; /* Light text for table cells */
}

/* Ensure the table does not overflow the container and wraps long content */
.prompt-markdown table {
  display: block;
  width: 100%;
  overflow-x: hidden; /* Disable horizontal scroll */
}


/* Mobile Responsive Styling */
@media (max-width: 768px) {

  /* Adjust padding for the prompt output container */
  .prompt-output {
    padding: 15px;
  }

  /* Adjust text size and padding for markdown content */
  .prompt-markdown {
    font-size: 14px;
    line-height: 1.4;
  }

  /* Adjust heading sizes */
  .prompt-markdown h1 {
    font-size: 1.6em;
  }

  .prompt-markdown h2 {
    font-size: 1.4em;
  }

  /* Adjust padding for code blocks */
  .prompt-markdown pre {
    padding: 8px;
    font-size: 14px;
  }

  /* Ensure the copy button is easier to tap */
  .prompt-copyButton {
    top: 10px;
    right: 10px;
  }

  /* Make the icons slightly smaller */
  .prompt-copyIcon,
  .prompt-checkmarkIcon {
    width: 20px;
    height: 20px;
  }

  /* Responsive table styling */
  .prompt-markdown table {
    display: block;
    overflow-x: auto;
    width: 100%; /* Ensure tables scroll horizontally if they are too wide */
  }

  .prompt-markdown th,
  .prompt-markdown td {
    padding: 6px 8px;
    font-size: 13px;
  }

  /* Adjust list margin */
  .prompt-markdown ul,
  .prompt-markdown ol {
    margin: 0.5em 0;
  }

  /* Ensure blockquotes have enough padding */
  .prompt-markdown blockquote {
    padding: 6px 10px;
    font-size: 14px;
  }

  /* Reduce margin around images */
  .prompt-markdown img {
    display: none;
  }
}

