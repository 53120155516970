/* Container for the entire workspace */
.template-workspace-container {
  display: flex;
  background-color: #161616;
  min-height: 100vh;
  position: relative;
}

/* Main content area */
.template-workspace-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  position: relative; /* Establish positioning context for absolute children */
}

.template-workspace-main-new-one {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0px 0px 0px;
  position: relative; /* Establish positioning context for absolute children */
}

/* Wrapper for the content to be overlaid */
.workspace-content-wrapper {
  position: relative; /* Establishes positioning context for the overlay */
}

/* Main Row Container */
.template-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the top */
  width: 100%;
  max-width: 1200px; /* Define max-width to match header-container */
  margin: 0 auto; /* Center the container */
}

.template-upload-container {
  flex: 1 1; /* Corrected syntax: flex-grow and flex-shrink */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  color: white;
  border: 0.5px solid #3e3e3e;
  padding: 30px; /* Reduced horizontal padding */
  border-radius: 5px;
  gap: 50px;
  margin: 0 auto; /* Center the container */
}

/* Container for Shortcuts */
.modify-shortcuts-container {
  display: flex;
  flex-direction: column;
}

/* Template Prompts Container */
.template-prompts-container {
  font-weight: bold;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Add spacing if needed */
}

/* Existing Styles for Overlay */
.newworkspace-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent dark overlay */
  z-index: 2; /* Higher z-index to overlay content */
  width: 100%;
  height: 100%;
  border-top: 1px solid #3e3e3e;
  border-bottom: 1px solid #3e3e3e;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3); */
}

/* Specific styles for the global overlay */
.global-overlay {
  /* Ensure the global overlay covers the entire wrapper */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Styles for the overlay message */
.newworkspace-overlay-message {
  background-color: #1e1e1e; /* Dark background for the message box */
  color: #FFFFFF; /* White text */
  padding: 24px;
  text-align: center;
  max-width: 450px; /* Max width to ensure readability */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Depth effect */
  border: 1px solid #3e3e3e;
  margin: 0 auto; /* Center the message box horizontally */
  border-radius: 4px;
}

.newworkspace-header-container {
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center items */
}

.newworkspace-draft-icon {
  width: 35px; /* Adjust size of the icon */
  height: 35px; /* Adjust size of the icon */
  object-fit: contain; /* Ensure the icon scales properly */
  margin-right: 5px; /* Add some space between the icon and the text */
}

.newworkspace-overlay-header {
  font-size: 20px;
  font-weight: 600;
}

.newworkspace-overlay-subtitle {
  font-size: 16px;
  color: #b3b3b3; /* Lighter subtitle color */
  margin-bottom: 10px;
}

/* Hover effect for overlay message */
.newworkspace-overlay-message:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2); /* Hover effect */
  transform: translateY(-7px);
  transition: all 0.3s ease;
}

/* Media Queries */

/* Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  /* Adjust the main row container to stack vertically */
  .template-row-container {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the row container */
    flex-direction: column; /* Stack elements vertically */
  }

  .template-upload-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center the content */
    align-items: center; /* Center items horizontally */
    color: white;
    border: 0px solid #3e3e3e;
    padding: 0px;
    border-radius: 5px;
    width: 100%; /* Make sure it takes the full width */
    margin-bottom: 20px;
    gap: 0px;
  }

  .template-prompts-container {
    font-size: 14px; /* Reduce font size for prompts */
  }

  .newworkspace-upload-workspace-wrapper {
    max-width: 100%;
    padding: 10px; /* Reduce padding for mobile */
  }



  /* Header Container Adjustments */
  .newworkspace-header-container {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .newworkspace-overlay-header {
    font-size: 16px;
  }

  .template-workspace-main-new-one {
    padding: 30px 20px 0px 20px;
  }

  .newworkspace-overlay {
    border-radius: 10px;
  }
}

/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .template-row-container {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the row container */
    flex-direction: column; /* Stack elements vertically */
  }

  .template-upload-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center the content */
    align-items: center; /* Center items horizontally */
    color: white;
    border: 0px solid #3e3e3e;
    padding: 0px;
    border-radius: 5px;
    width: 100%; /* Make sure it takes the full width */
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
  }


  .template-prompts-container {
    font-size: 14px; /* Reduce font size for prompts */
  }

  .template-workspace-main-new-one {
    padding: 30px 20px 0px 20px;
  }

  .newworkspace-overlay {
    border-radius: 10px;
  }

}


/* Medium-Large Screens Optimization */
@media (min-width: 1281px) and (max-width: 1800px) {

  .template-workspace-main-new-one {
    padding: 30px 20px 0px 20px;
  }

  .newworkspace-overlay {
    border-radius: 10px;
  }
  

}