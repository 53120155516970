.listbump-video-container {
    display: flex;
    flex-direction: column;
    background-color: #161616;
    margin-bottom: 60px;
}

.listbump-header-row,
.listbump-video-row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr; /* Adjust the columns as needed */
    gap: 16px;
    padding: 8px 24px;
    align-items: center;
    border-bottom: 0.5px solid #333;
}

.listbump-header-row {
    height: 50px;
    background-color: #161616;
    color: #fff;
    font-weight: bold;
}

.listbump-video-row {
    height: 100px;
    color: #ccc;
    font-size: 14px; /* Increased font size */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    position: relative;
}

.listbump-video-row:hover {
    background-color: #0e0e0e; /* Slightly darker background color on hover */
}



.listbump-header-item,
.listbump-video-duration,
.listbump-video-meta {
    display: flex;
    align-items: center;
    justify-content: left; /* Align items to the left */
    font-size: 18px; /* Increased font size */
    color: #ccc;
}

.listbump-video-item {
    display: flex;
    flex-direction: row;
    gap: 16px; /* Increased gap */
    align-items: center;
    overflow: hidden;
    margin-right: 16px; /* Add spacing between video details and duration column */
}

.listbump-thumbnail {
    width: 160px; /* Set consistent width */
    height: 90px; /* Set consistent height */
    object-fit: cover;
    border-radius: 5px;
    transition: none; /* Remove transition effects */
    cursor: pointer;
}

.listbump-video-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Ensure left alignment */
    margin-left: 10px;
    position: relative; /* Needed for absolute positioning of button */
    gap: 8px; /* Add spacing between title and button/description */
}


.listbump-video-title {
    font-size: 16px; /* Increased font size */
    font-weight: bold;
    margin: 0;
    color: #fff;
    white-space: normal; /* Allow text to wrap */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

  .clickable-title:hover {
    text-decoration: underline; /* Underlines the text on hover */
    cursor: pointer;
  }
  

.listbump-video-distribution {
    display: flex;
    align-items: center;
    justify-content: left; /* Center the icon horizontally */
}

.listbump-distribution-logo {
    width: 24px; /* Adjust size as needed */
    height: auto;
    opacity: 0.8;
}

.listbump-bumped-logo {
    width: 34px; /* Adjust size as needed */
    height: auto;
    opacity: 0.8;
}

.listbump-title-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    position: relative;
}

.view-video-button {
    cursor: pointer; /* Make sure the cursor changes to pointer for the button */
    border-radius: 5px;
    background-color: #161616;
    color: white;
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    border: 0.5px solid #3e3e3e;
    right: 0;
    top: 0;
}

.view-video-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.view-video-icon {
    width: 30px; /* Adjust size as needed */
    height: auto;
}

.listbump-video-description {
    font-size: 16px; /* Smaller font size */
    margin: 0;
    color: #aaa;
    white-space: normal; /* Allow text to wrap */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left; /* Align text to the left */
}

.listbump-description-button-wrapper {
    width: 100%; /* Ensure it takes up full width */
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    min-height: 37px; /* Set a fixed height based on the height of the button or description */
    margin-top: 10px;
  }
  

.no-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-content-image {
    margin-top: 150px;
    width: 350px; /* Adjust the size as needed */
    height: auto;
    border-radius: 12px;
    margin-bottom: 30px; /* Adjust the spacing as needed */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    opacity: 0.9;
}

.no-content-message {
    font-weight: bold;
    color: #ccc;
    text-align: center;
    font-size: 22px;
}



/* Pagination controls */
.listbump-pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.listbump-pagination-info {
  color: #fff;
  font-size: 18px;
  margin-right: 16px;
}

.listbump-pagination-button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 8px;
  margin: 0 4px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.listbump-pagination-button:disabled {
  color: #555;
  opacity: 0.5;
  cursor: default;
}

.listbump-pagination-button:hover:not(:disabled) {
  color: #ccc;
}

.listbump-arrow {
  border: solid #fff;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 3px;
}

.listbump-outside-arrow {
  border: solid #fff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
}

/* Arrow pointing left */
.listbump-arrow.listbump-left,
.listbump-outside-arrow.listbump-double-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/* Arrow pointing right */
.listbump-arrow.listbump-right,
.listbump-outside-arrow.listbump-double-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}





/* Mobile Optimization */
@media (max-width: 768px) {
  
    .listbump-video-container {
        margin-bottom: 100px;
    }

    .listbump-video-title {
      font-size: 12px; /* Smaller font size for mobile */
    }
  
    .listbump-video-description {
      font-size: 12px; /* Smaller font size for mobile */
    }
  
    .view-video-button {
      font-size: 12px; /* Smaller font size for mobile */
      padding: 4px 8px; /* Adjust padding for mobile */
    }
  
    .view-video-icon {
      width: 20px; /* Smaller icon size for mobile */
    }
  
    .listbump-video-row {
      grid-template-columns: 1fr; /* Single column for mobile */
    }

    .no-content-image {
        margin-top: 50px;
        width: 250px; /* Adjust width for mobile */
    }


  }
  
  /* Tablet Optimization */
  @media (max-width: 1280px) and (min-width: 769px) {

    .listbump-video-container {
        margin-bottom: 100px;
    }


    .listbump-video-title {
      font-size: 14px; /* Adjust font size for tablet */
    }
  
    .listbump-video-description {
      font-size: 14px; /* Adjust font size for tablet */
    }
  
    .view-video-button {
      font-size: 14px; /* Adjust font size for tablet */
      padding: 6px 10px; /* Adjust padding for tablet */
    }
  
    .view-video-icon {
      width: 25px; /* Adjust icon size for tablet */
    }
  
    .listbump-header-row {
      grid-template-columns: 3.2fr 1fr 1fr 1fr; /* Remove last column for tablet */
    }
  
    .listbump-video-row {
      grid-template-columns: 3.2fr 1fr 1fr 1fr; /* Remove last column for tablet */
    }

    .no-content-image {
        margin-top: 100px;
        width: 300px; /* Adjust width for mobile */
    }



  }
  

  @media (min-width: 1281px) and (max-width: 1800px) {

    .listbump-video-title {
        font-size: 14px; /* Adjust font size for tablet */
      }

  }