.thumbnail-layout {
  margin-top: 10px;
    padding: 25px;
  }
  
  .thumbnail-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  /* Center-align items horizontally */
    gap: 50px;
    max-width: 1360px;  /* Adjusted to accommodate 4 thumbnails */
    margin: auto;  /* Center the grid */
  }
  
  .fade-in {
    opacity: 0.4;
    animation: fadeInEffect 1s forwards;
  }
  
  @keyframes fadeInEffect {
    to { opacity: 1; }
  }
  
  
  
  .thumbnail-item {
    position: relative;
    border-radius: 10px 0 0 0;
    overflow: hidden;
    width: 320px;  /* 1/4 of 1280 pixels */
    height: 180px;  /* 1/4 of 720 pixels */
    margin: auto;  /* Center each thumbnail item */

  }
  
  .thumbnail-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .creator-loading-indicator {
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    height: 30vh; /* Adjust as needed */
  }
  
  .loading-content-wrapper {
    display: flex;
    align-items: center; /* Centers vertically */
  }

  .creator-processing-image {
    width: 200px;
    height: auto;
    opacity: .9;
  }
  
  .creator-loading-text {
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  .youtube-link-button {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    left: 10px;   /* Adjust as needed */
    background-color: rgba(38, 38, 38, 0.9);
    border-radius: 20%;
    padding: 2px;
    cursor: pointer;
    z-index: 2;
    width: 27px; /* Adjust as needed */
    height: 27px; /* Adjust as needed */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.);
}

  
  .youtube-link-icon {
    opacity: 0.9;
  }
  
  .non-clickable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1;
  }
  
  .disabled-link {
    pointer-events: none;
  }
  
  .undone-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent; /* Transparent background */
    border-radius: inherit; /* Inherit border-radius from the parent */
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity as needed */

  }

/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */

.duration-badge-creator {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #2b2f3b;
  color: #ffffff;  /* Changed to white */
  font-weight: bold;  /* Added */
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 14px;
  z-index: 1;  /* Added to ensure it's above the thumbnail */

}

.duration-badge-creator:hover {
  cursor: default;  /* Pointer cursor on hover */

}

  .duration-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #2b2f3b;
    color: #ffffff;  /* Changed to white */
    font-weight: bold;  /* Added */
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 14px;
    z-index: 1;  /* Added to ensure it's above the thumbnail */
  }

  .duration-badge:hover {
    background-color: #3b3f4b;  /* Lighter background color on hover */
    cursor: pointer;  /* Pointer cursor on hover */
  }


  .archive-active {
    background-color: #ffffff; /* White background */
    color: #2b2f3b; /* Dark text color for contrast */
  }
  
  .archive-active:hover {
    background-color: #f0f0f0; /* Slightly darker white on hover */
  }
  




/* TIMESTAMP CSS */
/* TIMESTAMP CSS */
/* TIMESTAMP CSS */
/* TIMESTAMP CSS */

  .timestamp-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #DC143C;
    color: #ffffff;  /* Changed to white */
    font-weight: bold;  /* Added */
    border-radius: 20%;
    padding: 2px;
    font-size: 14px;
    z-index: 1;  /* Added to ensure it's above the thumbnail */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    opacity: 0.9;
  }


  .timestamp-badge.in-queue {
    background-color: #ff8c00;
  }
  
  .timestamp-badge.done {
    background-color: #3cb371 !important;
  }
  
  .timestamp-badge img {
    /* Adjust the width and height as needed */
    width: 30px;  /* Example width */
    height: 30px;  /* Example height */
    /* You might want to add additional styles such as object-fit */
}



  .videocontent-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; /* Adjust if needed */
    margin: auto;
    width: 420px; /* Adjust width as needed */
  }
  

  
  .thumbnail-wrapper {
    position: relative;
    overflow: hidden;
    width: 320px; /* Thumbnail width */
    
  }
  .thumbnail-wrapper a {
    display: block;
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit text color */
  }
  
  /* background-color: rgba(85, 255, 110, 0.2); */

  .error-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 85, 85, 0.2);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .error-message-box {
    background-color: #0e0e0e;
    border-radius: 5px;  /* Rounded corners */
    padding: 17px;  /* Internal padding */
    border: 2px solid #3e3e3e;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);

  }
  
  .error-message {
    font-size: 16px;
    font-weight: bold;
    margin: 5px;  /* Adjust the margin as needed */
  }

  .retry-message {
    font-size: 14px;
  }
  
  .retry-message a{
    color: #4add80;
    text-decoration: underline;

  }
  .video-title {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 5px;
    margin-left: 10px;
    text-align: left;
    font-weight: bold;
  }

  .action-icon {
    color: #9b9ea3;
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  


  

  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */
  .info-action-wrapper {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .action-buttons {
    display: flex;
    gap: 5px; /* Reduce gap between buttons */
  }


  .icon-button {
    position: relative; /* Needed for the tooltip */
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .icon-button img {
    width: 30px; /* Icon size */
    height: 30px; /* Icon size */
  }
  
  .icon-button:hover img {
    opacity: 1.0; /* Hover effect for all buttons */
  }
  
  .icon-button.not-done img {
    opacity: 0.8; /* Less opacity for not done videos */
  }
  
  .icon-button.not-done:hover img {
    opacity: 1; /* Full opacity on hover for not done videos */
  }
  
  .icon-button.not-done:hover {
    transform: translateY(-5px); /* Move up slightly */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }
  
  .icon-button.done:hover img {
    transform: scale(1.1); /* Slightly enlarge the icon */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }
  
  
  /* Tooltip CSS */
  .process-tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    bottom: 125%; /* Position above the button */
    left: 0%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
    width: 90px; /* Adjust the width if necessary */
  }
  
  .icon-button.not-done:hover .process-tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .icon-button.processing {
    cursor: default; /* Disable pointer cursor */
    background-color: transparent; /* Transparent background for processing state */
  }
  
  .icon-button.processing img {
    opacity: 1; /* Ensure full opacity */
  }
  
  .videolayout {
    display: flex;
    gap: 5px; /* Space between buttons */
  }
  
  /* Processing overlay */
  .processing-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(25, 200, 148, 0.1); /* Green color with 40% opacity */
    border-radius: inherit; /* Inherit border-radius from the parent */
  }

  /* Done video overlay */
.done-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; /* Transparent background */
  border-radius: inherit; /* Inherit border-radius from the parent */
}
  
  
  
  .active-process-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .active-process-button img {
    width: 35px; /* Icon size */
    height: 35px; /* Icon size */
    opacity: 0.7;
  }
  
  
  .video-title {
    flex: 1;
    color: #ffffff;
    font-size: 16px; /* Increased font size */
    text-align: left;
    padding: 5px;
    font-weight: bold;
    white-space: normal; /* Allow wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px; /* Add some margin to separate from buttons */
    line-height: 1.2; /* Adjust line height for better spacing */
  }
  .info-rectangle {
    background-color: #262626;
    border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
    border-bottom-right-radius: 10px; /* Increased radius for bottom-right corner */
    width: 100%; /* Ensure it matches the width of the thumbnail */
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    box-sizing: border-box; /* Include padding and borders in the element's total width and height */
    
  }
  
  .thumbnail-wrapper:hover .info-rectangle {
    background-color: #2e2e2e; /* Slightly lighter version of #0e0e0e */
  }
  
  

/* New CSS for the side panel */
/* New CSS for the side panel */
/* New CSS for the side panel */
/* New CSS for the side panel */
.video-stats-container {
  display: flex;
  align-items: center;
  width: 100px; /* Adjust the width as needed */
}

.video-stats {
  background-color: #181818;
  border-radius: 0px 8px 8px 0px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #3e3e3e;
  border-top: 1px solid #3e3e3e;
  border-right: 1px solid #3e3e3e;
}
.video-stats.dimmed {
  opacity: 0.5; /* Adjust the value as needed */
}


.video-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-box {
  background-color: #2b2f3b;
  color: #ffffff;
  font-weight: bold;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 16px;
}

  .stat-icon {
    margin-bottom: 6px;
    color: #fff;
    opacity: 0.7;
    font-size: 12px;
  }


.stat-label {
  display: none; /* Hide the original labels */
}


/* Guideance TEXT */
/* Guideance TEXT */
/* Guideance TEXT */
/* Guideance TEXT */


.guidance-text {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 80px;
  font-size: 16px;
  color: #cfd0d0;
  opacity: 0; /* Start fully transparent */
  animation: fadeIn 5s ease forwards; /* 3s fade in, 'forwards' keeps the final state */
}

.guidance-text a {
  color: #4add80;
  text-decoration: underline;
}


.guidance-text-unauth {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #cfd0d0;
}

.guidance-text-unauth a {
  color: #4add80;
  text-decoration: underline;
}

@keyframes fadeIn {
  to { opacity: 1; } /* Fade to fully visible */
}


/* No Vids For Month */
/* No Vids For Month */
/* No Vids For Month */
/* No Vids For Month */



.no-videos-container {
  display: flex;
  align-items: center; /* Vertically center the items inside the container */
  justify-content: center; /* Horizontally center the items inside the container */
  gap: 20px; /* Add some space between the image and the text */
  width: 100%; /* Take up the full width of the parent */
  flex-direction: column; /* Stack items vertically */
  height: 20vh; /* Adjust as needed */


}

.no-videos-image {
  width: 60px; /* Adjust the size of the image as needed */
  height: auto;
}
.no-videos-message {
color: white;
font-weight: bold;
}


/* Grab Catalog Button */
/* Grab Catalog Button */
/* Grab Catalog Button */
/* Grab Catalog Button */


.needcatalogwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* Adjust as needed */
}

.fetchCatalogButton {
  background-color: #161616; /* Green */
  border: none;
  color: #ffffff;
  padding: 20px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-flex; /* Use flexbox to align image and text */
  align-items: center; /* Vertically center image and text */
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  border: 1px solid #3e3e3e;
  transition: transform 0.3s ease; /* Add transition for smooth effect */

}

.fetchCatalogButton:hover {
  transform: scale(1.05);

}


.buttonImage {
  width: 40px;
  height: auto;
  margin-right: 10px; /* Adjust spacing between image and text */
}


    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */

    .creator-noaccess-preview {
      display: flex;
      align-items: center;
      justify-content: center; /* Center the new wrapper */
      margin-top: 30px;
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around; /* Adjust the space between the button and image */
      gap: 30px;
    }
    

    .creator-noaccess-button {
        background-color: #1e1e1e;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        font-size: 20px;
        padding: 20px 20px;
        color: white;
        cursor: pointer;
        display: flex; /* To align the image and text inline */
        align-items: center; /* Center the items vertically */
        justify-content: center; /* Center the items horizontally */
        border: 1px solid #3e3e3e;
        }

        .creator-noaccess-button:hover {
          transform: scale(1.05); /* Slightly larger on hover */

        }
    
        .creator-noaccess-image img {
          border-radius: 15px;
          max-width: 380px;
          width: 100%;
          position: relative;
          overflow: hidden;
          transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for opacity and transform */
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

    






    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */

    /* Pagination controls */
.unauth-pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.unauth-pagination-info {
  color: #fff;
  font-size: 16px;
  margin-right: 12px;
}

.unauth-pagination-button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 8px;
  margin: 0 4px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.unauth-pagination-button:disabled {
  color: #555;
  opacity: 0.5;
  cursor: default;
}

.unauth-pagination-button:hover:not(:disabled) {
  color: #ccc;
}

.unauth-pagination-arrow {
  border: solid #fff;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 3px;
}

.unauth-double-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.unauth-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.unauth-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.unauth-double-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}


    .unauth-thumbnail-layout {
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers the content horizontally */
    }
    
    .tabs-container {
      display: flex;
      justify-content: center; /* Center the tabs inside the container */
      padding: 20px; /* Adjust padding as needed */
      width: auto; /* Adjust width to content */
      margin-bottom: 20px;
      gap: 10px;
    }
    
    
    .tab {
      padding: 10px 20px; /* Padding around the text */
      margin: 0px; /* Margin between tabs */
      cursor: pointer; /* Changes cursor to pointer on hover */
      border-radius: 5px; /* Rounded corners */
      transition: background-color 0.3s ease; /* Smooth transition for background color */
      color: #fff; /* Active tab text color */
      border: 1px solid #3e3e3e;
      opacity: 0.7;      
      /* More styling can be added here depending on your design requirements */
    }
    
    .tab.active {
      color: #fff; /* Active tab text color */
      border: 1px solid #3e3e3e;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
      opacity: 1.0;      
    }
    
    .unauth-exit-button {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      background: none;
      border: none;
      font-size: 16px;
      cursor: pointer;
      color: white;
    }

    .badge-orange {
      background-color: orange; /* Adjust the shade as needed */
      cursor: not-allowed; /* Indicate non-interactive state */
    }
    
    /* **UnauthProcessing Badge** */
    .unauthprocessing-badge {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #ffffff;  /* Changed to white */
      font-weight: bold;  /* Added */
      border-radius: 20%;
      padding: 2px;
      font-size: 14px;
      z-index: 1;  /* Added to ensure it's above the thumbnail */
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      opacity: 0.9;
    }
    
    .unauthprocessing-badge.done {
      /* Styles when processing is done */
      background-color: #3cb371; /* Example color */
    }
    
    .unauthprocessing-badge.in-queue {
      /* Styles when processing is in queue */
      background-color: #ff8c00;    
    }
    
    .unauthprocessing-badge.orange {
      /* Styles when data is missing */
      background-color: #ff8c00;    
    
    }
    
    /* **Overlay with Orange Coloring** */
    .overlay-orange {
      background-color: rgba(255, 165, 0, 0.2); /* Semi-transparent orange */
      cursor: not-allowed; /* Indicate non-interactive state */
      pointer-events: none; /* Prevent any interaction */
    }

    .unauthprocessing-badge img {
      /* Adjust the width and height as needed */
      width: 30px;  /* Example width */
      height: 30px;  /* Example height */
      /* You might want to add additional styles such as object-fit */
  }


  .duration-badge.disabled {
    opacity: 0.8;              /* Makes the element look disabled */
    cursor: default;       /* Changes the cursor to indicate non-interactivity */
    pointer-events: none;      /* Prevents any mouse events */
  }
  


/* Button base styles */
.reload-button-unauth {
  background-color: #161616; /* Same as .search-button */
  border: none;
  color: #ffffff;
  padding: 7px 7px; /* Adjust padding as needed to accommodate the image */
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color to the transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border: 1px solid rgba(62, 62, 62, 1.0); /* Border with 0.5 opacity */
}

.reload-button-unauth img {
  width: 25px;
  height: 25px;
  object-fit: contain; /* This ensures the image maintains its aspect ratio without being cropped. */
}

/* Hover styles */
.reload-button-unauth:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  background-color: rgba(74, 221, 128, 0.2) !important; /* Slight green with transparency, added !important */
}

/* Tooltip styles */
.reload-button-unauth:hover::after {
  content: attr(data-hover-text);
  position: absolute;
  top: -30px; /* Adjust this value to position the tooltip above the button */
  left: 0%; /* Align the tooltip to the left side of the button */
  transform: translateX(0%); /* No horizontal offset */
  background-color: #fff; /* Background of the tooltip */
  color: #000; /* Text color of the tooltip */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10; /* Ensure it's above other items */
  opacity: 0.7;
  pointer-events: none; /* Prevent tooltip from being interactive */
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);  /* Optional: add shadow for better visibility */
}

  
  .stat-box {
    margin-top: 4px;
    position: relative;
    font-size: 1.1em; /* Adjust main number size as needed */
    display: inline-block;
  }
  
  .stat-box.number {
    color: #4add80; /* Green color */
  }
  
  .stat-box.default {
    color: #ffffff; /* White color */
  }

  .stat-box .increase {
    position: absolute;
    top: -10px; /* Adjust vertical position as needed */
    right: -7px; /* Adjust horizontal position as needed */
    font-size: 0.6em; /* Adjust increase number size as needed */
    color: #4add80; /* Adjust text color as needed */
    background-color: #1e1e1e; /* Adjust background color as needed */
    padding: 2px 5px; /* Adjust padding as needed */
    border-radius: 5px; /* Adjust border radius as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow as needed */
  }



  


  @media (max-width: 768px) {

    .thumbnail-grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 0;
      margin: 0 auto;
    }
    .thumbnail-layout {
        margin-right: 10px;
        width: 90%;
      }
    .calendar-search-wrapper {
      flex-direction: column;
    }
    .unauth-thumbnail-layout {
      margin-top: 30px;
        
    }
    .videocontent-container {
      max-width: 100%;
    }

    .videocontent-container {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .video-stats-container {
      display: none; /* Hide the video stats container */
    }
    

    .tabs-container {
      display: flex;
      justify-content: start; /* Align items to the start of the container */
      padding: 5px; /* Adjust padding as needed */
      width: 80%; /* Ensure the container takes full width */
    }


    .creator-settings-container {
      margin-top: -40px;

    }

    .creator-noaccess-preview {
      margin-top: 30px; /* Reduce margin for smaller screens */
    }
  
    .content-wrapper {
      flex-direction: column; /* Stack image and button vertically */
      gap: 20px; /* Reduce gap for a more compact layout */
      padding: 10px; /* Add some padding for breathing space */
    }
  
    .creator-noaccess-image img {
      max-width: 280px; /* Adjust image size for smaller screens */
      transition: opacity 0.3s ease, transform 0.3s ease; /* Ensure smooth transitions */
    }
  
    .creator-noaccess-button {
      font-size: 16px; /* Reduce font size for smaller screens */
      padding: 12px 12px; /* Adjust padding for a more compact button */
    }
    .guidance-text{
      font-size: 13px; /* Reduce font size for readability */
    }
    .guidance-text-unauth {
      font-size: 13px; /* Reduce font size for readability */
      text-align: center; /* Center-align text for a better layout */
      margin-bottom: 40px; /* Add some margin for separation from other elements */
    }
  }


  /* Tablet optimization */
@media (min-width: 769px) and (max-width: 1280px) {
  .creator-noaccess-preview {
    margin-top: 40px; /* Slightly more margin than on mobile */
  }

  .content-wrapper {
    flex-direction: row; /* Maintain horizontal layout but adjust spacing */
    gap: 25px; /* Adjust gap for a more balanced layout */
  }

  .creator-noaccess-image img {
    max-width: 300px; /* Adjust image size for larger screens */
  }

  .creator-noaccess-button {
    font-size: 17px; /* Slightly larger font size than on mobile */
    padding: 14px 14px; /* Slightly larger padding for a more tactile button */
  }

  .guidance-text-unauth {
    font-size: 16px; /* Adjust font size for better readability */
    text-align: left; /* Align text to the left for a more natural flow */
    margin: 25px 0; /* Adjust margin for better spacing */
  }
}