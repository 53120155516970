/* src/HomePage.css */

/* General styles */
body {
  background-color: #161616;
  color: #fff;
}

/* Homepage container */
.hp-homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Top fold section */
.hp-topfold-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #161616; /* Dark background for content */
  color: #fff;
  max-width: 1200px;
}

.hp-topfold-content {
  flex: 1;
  text-align: left; /* Left-align the text */
}

.hp-main-header {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hp-main-header .highlight {
  color: #4add80; /* Highlight color */
}

.hp-sub-header {
  font-size: 1.5rem;
  color: #bbb;
  margin-bottom: 40px;
}

/* CTA buttons (Google and Email) */
.hp-cta-buttons {
  display: flex;
}

/* Keep original styling for the CTA buttons */
.hp-cta-button {
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease, border 0.3s ease;
  background: #161616;
}

.hp-cta-button.email-button {
  background-color: #3da86b;
  color: #fff;
}

.hp-cta-button.email-button:hover {
  background-color: #32975c;
}

.hp-cta-button.hp-google-button img {
  height: 75px;
  margin-left: -10px;
}

/* Topfold media container (video) */
.hp-topfold-media-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide any overflow */
  border-radius: 10%; /* Optional: Rounded corners */
  width: 100%;
  max-width: 600px;
}

/* Gradient overlays on left and right */
.hp-topfold-media-container::before,
.hp-topfold-media-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%; /* Adjust the width as needed */
  pointer-events: none; /* Allow clicks to pass through */
  z-index: 2; /* Ensure the gradients are above the video */
}

.hp-topfold-media-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0));
}

.hp-topfold-media-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0));
}

.hp-topfold-video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the video covers the container without distortion */
  border-radius: 10%; /* Optional: Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Highlighted text with transition */
.highlight {
  position: relative;
  display: inline-block;
  overflow: hidden; /* Ensure content doesn't overflow */
}

/* Fade-in and slide-up animation */
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-out and slide-up animation */
@keyframes fadeOutSlideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.highlight.fade-in {
  animation: fadeInSlideUp 0.5s forwards;
}

.highlight.fade-out {
  animation: fadeOutSlideUp 0.5s forwards;
}

.hp-main-header .highlight {
  color: #4add80; /* Highlight color */
  transition: color 0.5s ease-in-out;
}

/* Get Started Pill */
.hp-get-started-pill {
  display: flex;
  align-items: center;
  background-color: #3da86b; /* Updated base color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px; /* Space between pill and header */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hp-get-started-pill:hover {
  background-color: #32975c; /* Darker shade on hover */
  transform: translateY(-2px);
}

.hp-pill-badge {
  background-color: #fff;
  color: #3da86b; /* Updated badge color */
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  margin-right: 10px;
  font-weight: bold;
}

.hp-pill-text {
  flex-grow: 1;
}

.hp-pill-arrow {
  margin-left: 7px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  margin-bottom: 3px;
}

.hp-get-started-pill:hover .hp-pill-arrow {
  transform: translateX(5px);
}

/* Adjusting the main header margin if necessary */
.hp-main-header {
  margin-top: 10px; /* Adjust if needed */
}


/* Prompt Workspace Image Container */
.hp-prompt-workspace-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0; /* Adjust the top and bottom margin as needed */
}

/* Prompt Workspace Image */
.hp-prompt-workspace-image {
  max-width: 100%;
  height: auto;
  width: 1200px;
  border-radius: 10px; /* Optional: Adds rounded corners */
}


/* Wrapper for prompt workspace */
.hp-prompt-workspace-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0; /* Adjust top and bottom margin as needed */
  text-align: center; /* Center-align text elements */
}

.hp-prompt-workspace-subtitle {
  font-size: 1.2rem;
  color: #bbb;
  margin-bottom: 0px; /* Space between subtitle and button */
  max-width: 800px; /* Optional: limit width */
  margin-top: 5px;
  
}


/* Processing CSS */
/* Processing CSS */
/* Processing CSS */

.processing-container {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Optional */
}

.processing-text {
    color: white;
    text-align: center;
    font-size: 20px;
}
.processing-image-logo {
  width: 200px;
  height: auto;
}
.process-fixed-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}


/* Responsiveness */
/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
  .hp-topfold-section {
    flex-direction: column;
    padding: 40px;
    align-items: center; /* Center elements horizontally */
    text-align: center;  /* Ensure text is centered */
  }

  .hp-topfold-content {
    width: 100%;
    max-width: 800px;  /* Optional: limit width to avoid stretching */
  }

  .hp-main-header {
    font-size: 2.3rem;
    text-align: center;  /* Center the main header */
    margin-bottom: 10px;
  }

  .hp-sub-header {
    font-size: 1rem;
    max-width: 600px;  /* Limit the width of the sub-header */
    margin: 0 auto;    /* Center text */
    text-align: center;  /* Center the main header */
    margin-bottom: 20px;

  }

  .hp-prompt-workspace-subtitle {

    padding: 0px 30px 0px 30px;
  }

  /* Center Get Started Pill button */
  .hp-get-started-pill {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 0 auto; /* Ensure it is centered */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .hp-pill-badge {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

  .hp-pill-arrow {
    font-size: 1rem;
  }

  .hp-cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons */
    justify-content: center;
    gap: 10px;
  }

  .hp-cta-button {
    font-size: 18px;
    height: 50px;
    width: 220px;
  }

  .hp-cta-button.hp-google-button img {
    height: 65px;
    margin-left: 0px;
  }

  /* Adjust the media container width and center it */
  .hp-topfold-media-container {
    width: 100%;  /* Full width on smaller screens */
    max-width: 600px; /* Limit width for medium screens */
    display: flex;
    justify-content: center; /* Center the video */
    margin: 0 auto;  /* Ensure it is centered */
    margin-top: 40px;
  }

  .hp-prompt-workspace-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  /* Adjust the image size to be responsive */
  .hp-prompt-workspace-image {
    margin-top: 20px;
    width: 100%;   /* Make the image responsive */
    max-width: 800px;  /* Limit the maximum size */
    height: auto;  /* Maintain the aspect ratio */
  }

  .video-carousel-container h2,
  .podcast-usecase-container h2,
  .faq-container h2 {
    font-size: 2rem;
  }

  .footer-container p,
  .cookie-bar-container p {
    font-size: 0.9rem;
  }

  .processing-text {
    font-size: 18px;
  }

  .process-fixed-text {
    font-size: 14px;
  }
}


/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .hp-topfold-section {
    flex-direction: column;
    padding: 40px;
    align-items: center; /* Center elements horizontally */
    text-align: center;  /* Ensure text is centered */
  }

  .hp-topfold-content {
    width: 100%;
    max-width: 800px;  /* Optional: limit width to avoid stretching */
  }

  .hp-main-header {
    font-size: 3.5rem;
    text-align: center;  /* Center the main header */
    margin-bottom: 10px;
  }

  .hp-sub-header {
    font-size: 1.3rem;
    max-width: 600px;  /* Limit the width of the sub-header */
    margin: 0 auto;    /* Center text */
    text-align: center;  /* Center the main header */
  }

  .hp-prompt-workspace-subtitle {
    margin-bottom: 12px; /* Space between subtitle and button */

  }

  /* Center Get Started Pill button */
  .hp-get-started-pill {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 0 auto; /* Ensure it is centered */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .hp-pill-badge {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

  .hp-pill-arrow {
    font-size: 1rem;
  }

  .hp-cta-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center; /* Center buttons */
    justify-content: center;
  }

  .hp-cta-button {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 18px;
    height: 50px;
    width: 220px;
  }

  .hp-cta-button.hp-google-button img {
    height: 65px;
    margin-left: 0px;
  }

  /* Adjust the media container width and center it */
  .hp-topfold-media-container {
    width: 100%;  /* Full width on smaller screens */
    max-width: 700px; /* Limit width for medium screens */
    display: flex;
    justify-content: center; /* Center the video */
    margin: 0 auto;  /* Ensure it is centered */
  }

  .hp-prompt-workspace-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  /* Adjust the image size to be responsive */
  .hp-prompt-workspace-image {
    width: 100%;   /* Make the image responsive */
    max-width: 800px;  /* Limit the maximum size */
    height: auto;  /* Maintain the aspect ratio */
  }

  .video-carousel-container h2,
  .podcast-usecase-container h2,
  .faq-container h2 {
    font-size: 2rem;
  }

  .footer-container p,
  .cookie-bar-container p {
    font-size: 0.9rem;
  }

  .processing-text {
    font-size: 18px;
  }

  .process-fixed-text {
    font-size: 14px;
  }
}
