/* src/components/SubPlanSection.css */

/* Combined Plan Box Styles */
.combined-plan-box {
    border-radius: 5px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
    border: 1px solid #3e3e3e;
    background-color: #1e1e1e;
}

/* Separator Line */
.separator {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1); /* Faint white line */
    margin: 30px 0; /* Optional: Adjust spacing around the separator */
}

/* Subscription Details Section */
.subscription-details {
    width: 100%;
    display: flex; /* Enable Flexbox */
    justify-content: space-between; /* Space between elements */
    align-items: center; /* Vertically center the items */
    position: relative; /* Ensure positioning context if needed */
    margin-bottom: 30px; /* Optional: Add spacing below */
}

/* Active Badge Styles */
.active-badge {
    /* Removed absolute positioning */
    background-color: #181818;
    color: #4add80;
    padding: 6px 12px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.85rem;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
}

/* Active Badge - Canceled Styles */
.active-badge-canceled {
    background-color: #FFC1C1; 
    color: #333333; 
}

/* Plan Title Styles */
.plan-title {
    font-size: 1.8rem;
    color: #ffffff;
    display: flex;
    align-items: center; /* Vertically center content */
    font-weight: bold;
}

/* Plan Logo Styles */
.bumpplan-logo {
    vertical-align: middle;
    margin-right: 7px; /* Space between text and image */
    height: 26px; /* Adjust as needed */
    width: auto; /* Maintain aspect ratio */
    opacity: 0.95;
}

/* Upload Credits Label with Logo */
.upload-credits-label-with-logo {
    display: flex;
    align-items: center;
}

/* Plan Logo within Credits */
.plan-logo {
    width: 18px; /* Adjust size as needed */
    height: 18px;
    margin-right: 8px; /* Space between logo and label */
    vertical-align: middle;
    opacity: 0.7;
}

/* Upload Credits Label */
.upload-credits-label {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}

/* Upload Credits Time */
.upload-credits-time {
    font-weight: 600;
    font-size: 16px;
    text-align: right; /* Align time to the right */
    flex-shrink: 0; /* Prevent the time from shrinking */
    color: #ffffff;
}

/* Specific Time Colors */
.base-plan-time .time-value {
    color: #ffbf00;
}

.bump-plan-time .time-value {
    color: #4add80; /* Green color for Bump plan time */
    font-weight: bold;
}

/* Plan Details Container */
.plan-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Upload Time Container */
.upload-time-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start of the container */
    width: 100%;
    margin-top: 10px;
}

/* Upload Time Wrapper */
.upload-time-wrapper {
    display: flex;
    justify-content: space-between; /* Space between label and time */
    align-items: center; /* Vertically align label and time */
    width: 100%; /* Full width */
    margin-bottom: 14px; /* Space between each label-time pair */
}

/* Rebuy Text */
.rebuy-text-spacing {
    margin-top: 30px; 
    color: #bbbbbb;
    font-size: 14px;
    line-height: 1.5;
}

.rebuy-text {
    text-align: left;
}

/* Add More Button */
.add-more-btn {
    padding: 14px 20px;
    background-color: #0e0e0e; /* Slightly lighter for contrast */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: 100%; /* Full-width for better responsiveness */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    text-decoration: none; /* Avoid text underline on hover */
    border: 1px solid #3e3e3e;
}

.add-more-btn:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* More pronounced shadow on hover */
}

/* Billing Label */
.billing-label {
    margin-right: 10px;
    font-weight: 600;
    color: #ffffff;
}

/* Billing Info */
.billing-info, .billing-info-email {
    font-weight: 600;
    color: #ffffff;
}

.billing-info-email {
    font-size: 14px;
}

/* Invoice & Update Button */
.invoice-update-btn {
    padding: 14px 20px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 1rem;
    width: 100%; /* Ensure responsiveness */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-decoration: none; /* Override any global styles */
    border: 1px solid #3e3e3e;
}

.invoice-update-btn:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Subtle change on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* More pronounced shadow on hover */
}

.invoice-update-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); /* Soft focus ring */
}

.invoice-update-btn:active {
    background-color: rgba(255, 255, 255, 0.12); /* Even lighter on active */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Reset shadow on active */
}

.bulling-plan-title {
    font-size: 1.8rem;
    color: #ffffff;
    display: flex;
    align-items: center; /* Vertically center content */
    font-weight: bold;
    margin-top: 0px;

}
/* Billing Box Specific Styles */
.billing-details .plan-details {
    margin-top: 15px;  /* Add more top margin */
    margin-bottom: 15px;  /* Add more bottom margin */
}

.billing-details .invoice-update-btn {
    margin-top: 20px;  /* Add more top margin */
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .combined-plan-box {
        width: 90%;  
        padding: 20px;
    }

    .subscription-details, .billing-details {
        width: 100%;
    }

    .subscription-details {
        flex-direction: row; /* Stack elements vertically on small screens */
        align-items: flex-start; /* Align items to the start */
    }

 

    .plan-title {
        justify-content: center; /* Center title when stacked */
    }

    .rebuy-text-spacing {
        margin-top: 30px; 
    }
}
