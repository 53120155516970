
/* MAIN NAVBAR MENU CSS */
.sticky-header-wrapper {
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 4; /* Ensure it is above other elements */
}

.announcement-bar {
  background-color: #e0f7e9; /* Mint green background */
  text-align: center;
  padding: 12px;
  top: 0;
  z-index: 4; /* Ensure it's above the navbar */
}

.announcement-bar-text {
  font-family: 'Arial', sans-serif; /* Choose a clean, readable font */
  font-size: 15px; /* Adjust size as needed */
  color: #006400; /* Dark green text color */
  margin: 0; /* Remove default margins */
  line-height: 1.5; /* Adjust line height for readability */
}


.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #161616;
  z-index: 3;
  border-bottom: 0.5px solid #3e3e3e;
}

.navbar-sticky {
  top: 0; /* Offset by the height of the announcement bar */
  z-index: 3; /* Lower than the announcement bar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #161616;
  border-bottom: 0.5px solid #3e3e3e;
}


.navbar-brand {
  font-size: 24px; /* Adjust as needed */
  font-weight: bold;
  margin-left: 0px; /* Adjust as needed */
  text-decoration: none;
  color: #FFF;
  flex-grow: 1;
}
.navbar-links {
display: flex;
gap: 40px;
align-items: center;
}


.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  transition: color 0.3s;
  text-decoration: none;
  vertical-align: middle;
  color: #FFF;
  font-weight: 800;
  cursor: pointer;
}

.divider {
width: 100%;
height: 1px;
background-color: #444;
padding: 1px;
}

.brand-and-toggle-container {
display: flex;
align-items: center;
justify-content: flex-start;
width: calc(100% - [Right Content Width]); /* Calculate the width based on the right content */
flex-shrink: 0; /* Prevents the container from shrinking */
}

.sidebar-toggle,
.navbar-brand {
line-height: 1; /* Adjust to ensure vertical alignment */
/* ... other styles ... */
}

/* Sidebar TOGGLE CSS */
/* Sidebar TOGGLE CSS */
/* Sidebar TOGGLE CSS */
/* Sidebar TOGGLE CSS */

.sidebar-toggle {
background: none; /* Removes any background */
border: none; /* Removes the border */
color: white; /* Sets the text color to white */
font-weight: bold; /* Makes the text bold */
font-size: 1.5rem; /* Adjusts the size of the icon text */
cursor: pointer; /* Ensures the cursor changes to a pointer on hover */
padding: 0; /* Removes padding */
margin-right: 20px; /* Keeps the 20px margin to the right */
position: relative;
width: 30px;
text-align: center;
box-sizing: border-box;
}

.sidebar-toggle::after {
content: attr(title); /* Use the title attribute as the content for the tooltip */
position: absolute;
top: 50%; /* Align the top of the tooltip with the center of the button */
left: 100%; /* Position the tooltip to the left side of the button */
transform: translateY(-50%) translateX(5px); /* Adjust vertical position and add 5px horizontal space */
white-space: nowrap; /* Prevent the tooltip text from wrapping */
background-color: #333;
color: #fff;
padding: 5px 8px;
border-radius: 4px;
font-size: 0.75rem;
box-shadow: 0 2px 5px rgba(0,0,0,0.2);
pointer-events: none; /* Ignore this element when it comes to mouse events */
opacity: 0; /* Start with the tooltip invisible */
transition: opacity 0.3s ease-in-out;
z-index: 1000; /* Ensure the tooltip is above other elements */
}

.sidebar-toggle:hover::after {
opacity: 1; /* Show the tooltip text on hover */
}

.sidebar-toggle:focus {
outline: none;
}

/* END Sidebar TOGGLE CSS */
/* END Sidebar TOGGLE CSS */
/* END Sidebar TOGGLE CSS */
/* END Sidebar TOGGLE CSS */
/* END Sidebar TOGGLE CSS */




/* DROPDOWN MENU CSS */

.profile-dropdown {
position: absolute;
top: 100px;
right: 65px;
background-color: #1e1e1e;
border-radius: 5px;
padding: 12px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
z-index: 3;
opacity: 0;
transition: opacity 0.8s ease;
font-weight: bold;
border: 0.5px solid #3e3e3e;
}

.profile-dropdown.active {
opacity: 1;
}


.profile-dropdown .nav-button {
padding: 10px 30px 5px 5px;
width: 100%;
text-align: left;
text-decoration: none;
color: #FFF;
}

.email-display {
padding: 12px;
font-weight: bold;
color: #fff;
border-bottom: 1px solid #444;
}


.planNavIcon {
width: 22px;
height: 22px;
margin-right: 7px;
vertical-align: middle;
}

.navbar-tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 110%;
  left: 50%; /* Align to the center of the icon */
  transform: translateX(-50%);
  background-color: #0e0e0e;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold; /* Make the text bold */
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  opacity: 0; /* Start with opacity 0 for smooth transition */
  width: 100%;
}

.info-icon:hover + .navbar-tooltip-text,
.base-time-text:hover + .navbar-tooltip-text,
.bump-time-text:hover + .navbar-tooltip-text {
  visibility: visible;
  opacity: 1; /* Fade in the tooltip */
}



.iconmenu {
width: 35px;
height: 35px;
margin-right: 11px;
vertical-align: middle;
}



/* DROPDOWN MENU CSS END */


.utubeicon {
width: 27px;
height: 18px;
margin-right: 6px;
padding: 9px;
vertical-align: middle;

}


.nav-button:hover {
  color: #4add80;
}

.nav-button.signup {
  background-color: #ffffff;
  color: #161616;
  padding: 10px 30px;
  border-radius: 30px;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
  cursor: pointer;

}

.nav-button.signup:hover {
transform: scale(1.02);
}


.menu-toggle {
  font-size: 1.5em;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 65px;
  right: 25px;
  background-color: #1e1e1e;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 3;
  opacity: 0;
  transform: scale(0.8); 
  transition: opacity 0.8s ease, transform 0.5s ease; /* Longer duration for opacity */
  font-weight: bold;
  border: 0.5px solid #3e3e3e;
  width: 150px;

}

.menu-dropdown.active {
  opacity: 1;
  transform: scale(1);
}

.menu-dropdown .nav-button {
  width: 100%;
  margin-bottom: 30px;

    /* Added to stretch the button */
}

.menu-dropdown .nav-button.signup {
  width: 100%;
  margin-bottom: 0;
  /* Added to stretch the button */
}


.pricing-button {
  font-size: 1.2em;
}

.profile-icon {
  width: 60px;  /* Set the width */
  height: 60px;  /* Set the height */
  border-radius: 50%;  /* Make it circular */
  object-fit: cover;  /* Maintain aspect ratio */
  margin-right: 10px;  /* Add some margin */
  border: 2px solid transparent;  /* Invisible border */
  transition: border-color 0.3s; /* Smooth transition for the border color */
}

.profile-icon:hover {
  border-color: white;  /* Change border color to white on hover */
}




/* ADD MORE CREDITS CSS */
/* ADD MORE CREDITS & TIME CSS */
/* ADD MORE CREDITS & TIME CSS */
/* ADD MORE CREDITS & TIME CSS */

.add-credits-button {
border-radius: 8px;
padding: 15px 20px;
background-color: #272727;
color: white;
border: none;
cursor: pointer;
font-weight: bold;
font-size: 15px;
transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); /* subtle shadow */
border: 0.5px solid #3e3e3e;

}

.add-credits-button:hover {
background-color: rgba(255, 255, 255, 0.2); /* Lighter shade on hover */
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* slightly more pronounced shadow on hover */
}

.add-credits-button:focus {
outline: none;
box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); /* Focus ring with softer color */
}

.add-credits-button:active {
transform: scale(0.98); /* Slightly smaller on click */
}

.base-time-text {
font-size: 17px;
font-weight: bold;
color: #ffbf00; /* Adjust color as needed */
cursor: pointer;

}

.bump-time-text {
  font-size: 17px;
  font-weight: bold;
  color: #4add80; /* Adjust color as needed */
  cursor: pointer;

  }



.time-container {
position: relative;
display: flex;  /* Flexbox for horizontal alignment */
align-items: center;  /* Center items vertically */
}


.time-tooltip {
position: absolute;
bottom: -90px;
left: 0;
background-color: #212121;
color: #fff;
border-radius: 12px;  /* Rounded corners */
padding: 16px;  /* Increased padding */
width: 170px;  /* Slightly wider */
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
opacity: 0;
transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
transform: translateY(10px) scale(0.9);   /* Initial position for fade-in effect */
left: -120px;
text-align: left;
}


.time-container:hover .time-tooltip {
opacity: 1;
transform: translateY(0) scale(1);  /* Final position and scale for effect */
}

.tooltip-time {
font-weight: 600;  /* Bold text for time */
margin-right: 8px;  /* Spacing between text */
color: #4add80;

}

.tooltip-expiry {
margin-top: 10px;
font-size: 0.9em;  /* Slightly smaller font for expiry text */
color: #ccc;  /* Lighter color for expiry text */
}

.tooltip-content {
display: flex;
justify-content: space-between;
}



.nav-bumpups-brand {
color: #ffffff; /* Tube color */
font-size: 26px; /* Adjust size as necessary for navbar */
font-weight: bold;
text-decoration: none; /* Remove underline from links */
}

.bumpups-brand-color {
color: #16c391; /* Stamp color */
font-size: 26px; /* Adjust size as necessary for navbar */
font-weight: bold;
}

.navbar-logo {
height: 30px; /* Adjust the height as needed */
width: auto;
margin-right: 5px; /* Space between the logo and the text */
vertical-align: middle; /* Aligns the logo vertically with the text */
}



/* For Mobile */
@media screen and (max-width: 767px) {
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-toggle {
  display: block;
  margin-right: -5vw;  /* Use negative viewport width */
}

.navbar-brand {
  margin-left: -3vw;  /* Use negative viewport width */
}

.profile-icon {
  margin-right: -5vw; /* Adjust as needed */
}

.profile-dropdown {

margin-right: -5vw;

}
.sidebar-toggle {
display: none;

}

.announcement-bar-text {
  font-size: 14px; /* Adjust size as needed */
  padding: 0px 5px 0px 5px;

}


}



/* For Tablet */
@media screen and (min-width: 768px) and (max-width: 1280px) {
.navbar-links {
  flex-direction: row;
}
.menu-toggle {
  display: block;
}

.navbar-brand {
    margin-left: 0px; /* Adjust as needed */
}

.sidebar-toggle {
  display: none;

}


}