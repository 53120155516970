.footer-container {
    display: flex;
    justify-content: space-between;
    background-color: #161616;
    padding: 20px 40px;
    font-size: 14px;
    color: #606060;
    margin-top: 50px;
    flex-wrap: wrap; /* Allow content to wrap to the next line */
    max-width: 1300px;
    margin: 0 auto;  
    margin-top: 50px;
}

.footer-branding {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.footer-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 30px;
}

.brand-description {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
    max-width: 290px;
    font-size: 15px;
    color: #cccccc;
}

.bumpups-brand {
    color: #ffffff; /* Tube color */
    font-size: 26px; /* Adjust size as necessary for navbar */
    font-weight: bold;
    text-decoration: none; /* Remove underline from links */
    margin-left: 20px;
  }
  
  .bumpups-brand-color {
    color: #16c391; /* Stamp color */
    font-size: 26px; /* Adjust size as necessary for navbar */
    font-weight: bold;
  }

.footer-section {
    flex: 1;
    margin-right: 20px;
    color: #cccccc;
    text-align: left;

}

.footer-section h4 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 17px;
    color: #FFF;
    margin-bottom: 30px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 6px;
    cursor: pointer;
    transition: color 0.2s;
}

.footer-section li:hover {
    color: #4add80; 
}

.timestamp-highlight {
    color: #14c491;
    margin-bottom: 10px;
}

.footer-bottom {
    
    margin-top: 5px;
    font-size: 15px;
    color: #cccccc;
    text-align: center;
    width: 100%; /* Ensure it takes the full width */
    font-weight: bold;
    text-align: left; 
    width: 100%;
    padding-left: 50px;
    padding-top: 80px;
    padding-bottom: 20px;
    
}

.footer-bottom a {
    margin-left: 10px;
    margin-right: 10px;
    color: #cccccc;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
    color: #3da6fe; 
}



.ai-nexus-badge {
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    background-color: #0e0e0e; /* Adjust color as needed */
    color: #FFF; /* Text color */
    padding: 7px 15px; /* Vertical and horizontal padding */
    border-radius:7px; /* Rounded corners */
    margin-top: 20px; /* Space above the badge */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Change cursor on hover */
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    font-size: 12px;
   
}


.ai-nexus-logo {
    width: 30px; /* Adjust as needed */
    height: 30px; /* Adjust as needed */
    margin-right: 10px; /* Space between the logo and text */
}


.footer-links li {
    text-align: left;
    margin-bottom: 20px;
    font-size: 15px;
}

.social-media-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

}

.social-media-icons a:nth-child(1) img {
    width: 38px;
    height: 38px;
}
.social-media-icons a:nth-child(2) img {
    width: 36px;
    height: 36px;
    margin-left: 8px; /* Adds spacing between Twitter and LinkedIn */
}



.contact-link {
    text-decoration: none;
    color: #CCCCCC !important;
    font-weight: bold;
  }
  .contact-link:hover {
    text-decoration: none;
    color: #4add80 !important;
  }
  
  @media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
      }
      .footer-section {
        flex-direction: row;
    
      }
      .mobile-footer {
        margin-top: 30px;  
      }

      .footer-bottom {
        font-size: 12px;



      }
  
  }