/* Dashboard Layout CSS */

.dashboard-videoid {
  display: flex;
  justify-content: center; /* Center content horizontally */
  background-color: #161616;
  min-height: 100vh;
  color: white;
}

.dashboard-main-videoid {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 0; /* Remove or reduce the right margin */
}

.dashboard-content-wrapper-videoid {
  display: flex;
  justify-content: space-between; /* Distribute space between columns */
  width: 100%;
  max-width: 1800px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the content wrapper */
  gap: 15px; /* Add more space between columns */
}

.dashboard-left-videoid, .dashboard-center-videoid, .dashboard-right-videoid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
  padding: 25px;
  
}

.dashboard-left-videoid {
  flex: 1.5; /* Adjust the flex property to allocate more space */
}

.dashboard-center-videoid {
  flex: 1.3; /* Adjust the flex property to allocate less space */
}

.dashboard-right-videoid {
  flex: 1; /* Adjust the flex property to allocate more space */
}

/* END Dashboard Layout CSS */


/* valueaddBUMP Layout CSS */
.addvalueBump-dashboard-main-videoid {
  margin-top: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 0; /* Remove or reduce the right margin */
  position: relative;
}
.addvalueBump-videoid {
  display: flex;
  justify-content: center; /* Center content horizontally */
  background-color: #161616;
  color: white;
}

.addvalueBump-left-videoid, .addvalueBump-center-videoid, .addvalueBump-right-videoid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
}

.addvalueBump-left-videoid {
  flex: 1.5; /* Adjust the flex property to allocate more space */
}

.addvalueBump-center-videoid {
  flex: 1.3; /* Adjust the flex property to allocate less space */
}

.addvalueBump-right-videoid {
  flex: 1; /* Adjust the flex property to allocate more space */
}

.bumpvalue-blur-overlay {
  filter: blur(5px); /* Adjust the blur intensity as needed */
  pointer-events: none; /* Prevent interaction with blurred content */
}




/* END valueaddBUMP Layout CSS */


/*  RELOAD Button CSS */

.reload-data {
  width: 100%;
}

.reload-data-button {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  background-color: #ffffff; /* Adjust as per your platform's color scheme */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 21px;
  color: #161616; /* Updated text color */
  font-weight: bold; /* Make the text bold */
  transition: background-color 0.3s ease;
  box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.1); /* More pronounced shadow on hover */
  width: 100%;
  justify-content: center;
}

.reload-data-button:hover {
  background-color: #d0d0d0; /* Adjust as per your platform's color scheme */
}

.reload-logo {
  width: 35px; /* Adjust the size of the logo */
  height: 35px;
  margin-right: 6px;
}

.reload-data-container {
  display: flex;
  align-items: center;
  justify-content: center;

}


.help-icon-container-reload-videoid {
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  position: relative;
  display: block;
  margin-left: 10px;
  z-index: 2;
  border: 0.5px solid #3e3e3e;

}

.help-tooltip-reprocess-videoid {
  visibility: hidden;
  width: 250px;
  background-color: #1f1f1f;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
  position: absolute;
  z-index: 15; /* Set a higher z-index to ensure it appears above the navbar */
  top: 100%; /* Adjust positioning to ensure it's below the help icon */
  left: 50%;
  transform: translateX(-90%); /* Center the tooltip horizontally */
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}


.help-icon-rload-videoid {
  width: 35px;
  height: 35px;
  opacity: 0.9;
  cursor: pointer;
  z-index: 10;
}
.help-icon-container-reload-videoid:hover .help-tooltip-reprocess-videoid {
  visibility: visible;
  opacity: 1;
}


.reload-data.hidden {
  display: none;
}


/* END RELOAD Button CSS */





/* Done Video Details CSS */


.done-videodetails-container {
  background-color: #161616;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  position: relative;
  border-bottom: 0.5px solid #3e3e3e;
}

.done-videodetails-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
}

.done-videodetails-catalog-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
  text-decoration: none; /* Remove underline */
  padding-left: 10px;
}

.done-videodetails-catalog-button:hover {
  color: #bbbbbb; /* Change color to a subtle grey on hover */
}

.done-videodetails-duration-badge {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 18px;
  z-index: 1;
  opacity: 0.9;
}

.done-videodetails-thumbnail-container {
  width: 100%;
}

.done-videodetails-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #0e0e0e;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}

.done-videodetails-content {
  text-align: left;
  width: 100%;
  padding: 20px;
}

.done-videodetails-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 10px 0;
  padding-right: 40px;
}

.done-videodetails-action {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.done-videodetails-action-btn {
  background-color: transparent;
  color: #4add80;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  display: block;
}

.done-videodetails-action-btn:hover {
  text-decoration: underline;
}

.done-videodetails-copy-btn {
  background-color: #0e0e0e;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.done-videodetails-script {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #ccc;
}

.done-videodetails-quality {
  margin-top: 10px;
  display: flex;
}

.done-videodetails-quality-btn {
  background-color: #2b2f3b;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.done-videodetails-quality-btn:hover {
  background-color: #4add80;
  color: #0e0e0e;
}





  /* VideoSummary CSS */
    /* VideoSummary CSS */
  /* VideoSummary CSS */
/* VideoSummary CSS */

.video-summary-box-videoid {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  border: 0.5px solid #3e3e3e;
  max-height: 250px; /* Set a maximum height for the summary box */
  overflow-y: auto; /* Enable vertical scrollbar */
  overflow-x: hidden; /* Disable horizontal scrollbar */
}

/* Custom Scrollbar Styling */
.video-summary-box-videoid::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.video-summary-box-videoid::-webkit-scrollbar-track {
  background: #2c2c2c; /* Background color of the scrollbar track */
  border-radius: 5px; /* Rounded corners for the track */
}

.video-summary-box-videoid::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.5); /* Dark gray with reduced opacity */
  border-radius: 5px; /* Rounded corners for the thumb */
}

.video-summary-box-videoid::-webkit-scrollbar-thumb:hover {
  background-color: rgba(119, 119, 119, 0.5); /* Lighter gray with reduced opacity on hover */
}

.video-summary-header-videoid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.video-summary-title-container-videoid {
  display: flex;
  align-items: center;
  
  
}

.video-summary-title-videoid {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.help-icon-container-videoid {
  position: relative;
  display: inline-block;
  z-index: 1; /* Ensure the container has a higher z-index */
}

.help-icon-videoid {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  opacity: 0.7;
  cursor: pointer;
}

.help-tooltip-videoid {
  visibility: hidden;
  width: 250px;
  background-color: #1f1f1f;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
  position: absolute;
  z-index: 10; /* Set a higher z-index to ensure it appears above the navbar */
  top: 100%; /* Adjust positioning to ensure it's below the help icon */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip horizontally */
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}

.help-icon-container-videoid:hover .help-tooltip-videoid {
  visibility: visible;
  opacity: 1;
}

.learn-more-videoid {
  color: #4add80;
  text-decoration: none;
  display: block;
  font-size: 14px;
  margin-top: 16px;
}

.learn-more-videoid:hover {
  text-decoration: underline;
}

.action-buttons-videoid {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  align-items: center;
}

.copy-summary-btn-videoid {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 5px;
  border-radius: 8px;
}

.copy-summary-btn-videoid:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.copy-summary-btn-videoid:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.copy-icon-videoid {
  width: 20px;
  height: 20px;
}

.tooltip-videoid {
  position: relative;
  display: inline-block;
}

.tooltiptext-videoid {
  position: absolute;
  top: -27px; /* Adjust the top positioning if necessary */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  white-space: nowrap; /* Prevent text wrapping */
  z-index: 10; /* Ensure it appears above other elements */
  background-color: #000; /* Background color */
  color: #fff; /* Text color */
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center the text */
  border-radius: 5px; /* Rounded corners */
  padding: 5px; /* Padding inside the tooltip */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s; /* Smooth transition */
}

.tooltiptext-videoid::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.tooltip-videoid:hover .tooltiptext-videoid {
  visibility: visible;
  opacity: 0.7;
}

.video-summary-content-videoid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgba(44, 44, 44, 0.4);
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  min-height: 100px;
  position: relative;
}


.video-summary-videoid {
  color: #fff;
  line-height: 1.5;
  text-align: left;
  transition: filter 0.3s;
  font-size: 16px;
  word-wrap: break-word; /* Ensure long words break to fit within the container */
}

.reload-logo-videoid {
  opacity: 0.8;
  width: 45px;
  height: 45px;
}


/* END VideoSummary CSS */



/* END VideoSummary CSS */
/* END VideoSummary CSS */
/* END VideoSummary CSS */
/* END VideoSummary CSS */

.video-stats-container-videoid {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  gap: 20px;
  border: 0.5px solid #3e3e3e;
}

.video-stat-title-videoid {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 10px;
}

.video-stats-icon-videoid {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-bottom: 10px; /* Adjust the spacing between image and header */
}

.video-stats-videoid {
  background-color: #161616;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the top */
  gap: 50px;
  justify-content: center;
  width: 100%;
  /* border: 0.5px solid #3e3e3e; */
}

.video-stats-videoid.dimmed {
  opacity: 0.5; /* Adjust the value as needed */
}

.video-stat-videoid {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the text labels and pills */
  position: relative; /* Ensure relative positioning for absolute positioning of .increase */
}

.stat-box-videoid {
  background-color: #0e0e0e;
  color: #ffffff;
  font-weight: bold;
  border-radius: 12px;
  padding: 10px 14px;
  font-size: 18px;
  position: relative; /* Ensure relative positioning for absolute positioning of .increase */
  text-align: center; /* Center the number inside the pill */
}

.stat-box-videoid .increase-videoid {
  position: absolute;
  top: -10px; /* Adjust vertical position as needed */
  right: -7px; /* Adjust horizontal position as needed */
  font-size: 0.7em; /* Adjust increase number size as needed */
  color: #4add80; /* Adjust text color as needed */
  background-color: #1e1e1e; /* Adjust background color as needed */
  padding: 3px 6px; /* Adjust padding as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow as needed */
}

.stat-icon-videoid {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center; /* Center the text labels */
}

.stat-label-videoid {
  display: none; /* Hide the original labels */
}

.stat-box-videoid.number {
  color: #4add80; /* Green color */
}

.stat-box-videoid.default {
  color: #ffffff; /* White color */
}



  
/* KeyTakeaways CSS */

.key-takeaways-box-videoid {
    background-color: #161616;
    border-radius: 5px;
    padding: 20px;
    border: 0.5px solid #3e3e3e;
  }

.key-takeaways-header-videoid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.key-takeaways-title-videoid {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.copy-takeaways-btn-videoid {
    background: none;
    border: none;
    cursor: pointer;
}

.copy-icon-videoid {
    width: 20px;
    height: 20px;
}

.key-takeaways-content-videoid {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
}

.key-takeaway-videoid {
  display: flex;
  align-items: center;
  background-color: rgba(44, 44, 44, 0.4); /* Less opaque background for the content */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the content box */
}

.key-takeaway-emoji-videoid {
  font-size: 2.0em; /* Increase emoji size */
  margin-right: 18px; /* Space between emoji and text */
}

.key-takeaway-text-videoid {
  color: #fff;
  line-height: 1.5;
  text-align: left; /* Left align text */
}



/* END KeyTakeaways CSS */



/* Timestamp Section CSS */
.video-timestamps-box-videoid::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.video-timestamps-box-videoid::-webkit-scrollbar-track {
  background: #2c2c2c; /* Background color of the scrollbar track */
  border-radius: 5px; /* Rounded corners for the track */
}

.video-timestamps-box-videoid::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.5); /* Dark gray with reduced opacity */
  border-radius: 5px; /* Rounded corners for the thumb */
}

.video-timestamps-box-videoid::-webkit-scrollbar-thumb:hover {
  background-color: rgba(119, 119, 119, 0.5); /* Lighter gray with reduced opacity on hover */
}

/* Additional styles */
.video-timestamps-box-videoid {
  background-color: #161616;
  border-radius: 5px;
  padding: 20px;
  max-width: 100%; /* Adjust to take full width */
  max-height: 400px; /* Set the maximum height */
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  border: 0.5px solid #3e3e3e;

}

.video-timestamps-header-videoid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
}

.video-timestamps-title-videoid {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 5px;
}

.copy-timestamps-btn-videoid {
  background: none;
  border: none;
  cursor: pointer;
}

.copy-icon-videoid {
  width: 25px;
  height: 25px;
  opacity: 0.8;
}

.video-timestamps-content-videoid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.video-timestamp-videoid {
  background-color: rgba(51, 51, 51, 0.2); /* Less opacity */
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: left; /* Ensure left alignment */
  transition: transform 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
}

.video-timestamp-number-videoid {
  font-weight: bold;
  color: #4add80; /* Green color */
  margin-right: 5px;
  text-decoration: none;
}

.video-timestamp-description-videoid {
  color: #fff;
  flex: 1;
  margin-left: 5px;
  max-width: calc(100% - 50px); /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hover effect for timestamps */
.video-timestamp-videoid:hover {
  transform: scale(1.05); /* Slightly enlarge the timestamp */
  background-color: rgba(51, 51, 51, 0.4); /* Darker background on hover */
}

.branding-btn-videoid {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 5px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.branding-btn-videoid:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.branding-icon-videoid {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  opacity: 0.7; /* Set opacity */
}

.icon-buttons-videoid {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reload-icon-videoid {
  width: 35px; /* Adjust size as needed */
  height: 35px; /* Adjust size as needed */
  opacity: 0.8; /* Set opacity */
}

/* Tooltip CSS */
.tooltip-videoid {
  position: relative;
  display: inline-block;
}









/* VideoTags CSS */

.video-tags-box {
  background: #161616;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative; /* To position the copy button absolutely within the box */
  border: 0.5px solid #3e3e3e;
}

.video-tassg-content-videoid{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgba(44, 44, 44, 0.4);
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  min-height: 100px;
  position: relative;
}

.video-tags-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

}

.video-tags-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px; /* Add margin to the bottom */
  margin-bottom: 5px;

}

.copy-section {
  display: flex;
  align-items: center;
}

.video-tags-content {
  display: flex;
  flex-wrap: wrap; /* Allows tags to wrap to the next line */
  gap: 10px; /* Space between tags */
}

.video-tag {
  background: #2c2c2c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-tag:hover {
  background: #3e3e3e;
  cursor: pointer;
}

.copy-tags-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.copy-icon {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  opacity: 0.7; /* Set opacity */
}

.copy-tags-btn:hover .copy-icon {
  opacity: 1; /* Increase opacity on hover */
}

.highlighted {
  background-color: #0e0e0e;
  font-weight: bold; /* Make the font bold */
}

.star-icon {
  width: 15px; /* Adjust size as needed */
  height: 15px; /* Adjust size as needed */
  margin-right: 5px;
}

.star-icon-left {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  opacity: 0.7; /* Set opacity */
  margin-right: 5px;
}

.tooltip-videoid {
  position: relative;
  display: inline-block;
}

.tooltiptext-left {
  visibility: hidden;
  width: 70px; /* Adjust width as needed */
  background-color: #000;
  color: #fff;
  font-weight: bold; /* Make the text bold */
  font-size: 13px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -40px; /* Adjust margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext-left::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent; /* Arrow pointing up */
}


.tooltip-videoid:hover .tooltiptext-left {
  visibility: visible;
  opacity: 1;
}

/* END VideoTags CSS */













.video-suggestions-container {
    background-color: #161616;
    color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px; 
    max-width: 100%; 
    border: 0.5px solid #3e3e3e;
    position: relative;
  }
  .video-suggestions-content {
    text-align: left;
  }
  .video-suggestions-card {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .video-suggestions-content h3 {
    margin-top: 0;
    font-size: 1rem;
    color: #aaa;
  }
  
  .video-suggestions-content h2 {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .video-suggestions-content p {
    font-size: 0.9rem;
    color: #ddd;
  }
  
  .video-suggestions-icon img {
    margin-top: 40px;
    width: 90px; /* Adjust the size as needed */
    height: auto;
    padding: 0px; /* Adjust the padding to scale the circle size */
  }
  
  .video-suggestions-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .video-suggestions-start-btn {
    background-color: transparent; /* Removes the background color */
    color: #4add80;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold; /* Makes the text bold */
    flex-grow: 1; /* This will push the pagination to the right */
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  
  .video-suggestions-start-btn:hover {
    text-decoration: underline;
  }
  
  .video-suggestions-pagination {
    color: #aaa;
    font-size: 0.9rem;
  }
  
  .video-suggestions-pagination {
    display: flex;
    align-items: center;
    justify-content: center; /* Center pagination in the container */
    top: 20px; /* Adjust these values based on your layout */
    right: 20px;
    color: #aaa;
    font-size: 0.9rem;
    position: absolute;
    font-weight: bold;
  }
  
  .pagination-arrowbox {
    cursor: pointer;
    margin: 0 10px;
    user-select: none; /* Prevent text selection */
  }
  
  .pagination-arrowbox:hover {
    color: white; /* Highlight on hover */
  }
  

  /* AI Processing BUMP */
  /* AI Processing BUMP */
  /* AI Processing BUMP */
  /* AI Processing BUMP */
  /* AI Processing BUMP */
  /* AI Processing BUMP */

  .valueBump-blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 5; /* Ensure it's behind the overlay */
    pointer-events: none; /* Prevent interaction with blurred content */
}

.valueBump-processing-container {
    background-color: #161616;
    color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px;
    width: 100%;
    border: 0.5px solid #3e3e3e;
    position: absolute; /* Position absolute to overlay */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Higher z-index to appear on top */
}

.valueBump-processing-content {
    text-align: left;
}

.valueBump-processing-card {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.valueBump-processing-content h3 {
    margin-top: 0;
    font-size: 1rem;
    color: #aaa;
}

.valueBump-processing-content h2 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.valueBump-processing-content p {
    font-size: 0.9rem;
    color: #ddd;
}

.valueBump-processing-icon img {
    margin-top: 40px;
    width: 90px; /* Adjust the size as needed */
    height: auto;
    padding: 0px; /* Adjust the padding to scale the circle size */
}

.valueBump-processing-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.valueBump-processing-start-btn {
    background-color: transparent;
    color: #4add80;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
    flex-grow: 1;
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 20px;
}

.valueBump-processing-start-btn:hover {
  text-decoration: underline;

}


  
/* Mobile Optimization */
@media (max-width: 768px) {
  .dashboard-content-wrapper-videoid {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: -15px;
  }

  .dashboard-left-videoid,
  .dashboard-center-videoid,
  .dashboard-right-videoid {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .addvalueBump-left-videoid,
.addvalueBump-center-videoid,
.addvalueBump-right-videoid {
  width: 100%;
  box-sizing: border-box;
}




  .video-summary-box-videoid,
  .key-takeaways-box-videoid,
  .video-timestamps-box-videoid,
  .video-tags-box,
  .video-suggestions-container {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .done-videodetails-content {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .done-videodetails-title {
    font-size: 0.9rem;
  }

  .done-videodetails-action-btn {
    font-size: 0.8rem;
  }

  .video-summary-title-videoid,
  .key-takeaways-title-videoid,
  .video-tags-title,
  .video-timestamps-title-videoid,
  .video-stat-title-videoid {
    font-size: 1.2rem;
  }

  .video-summary-content-videoid,
  .key-takeaways-content-videoid,
  .video-timestamps-content-videoid,
  .video-tags-content {
    gap: 5px;
  }

  .key-takeaway-emoji-videoid {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .video-timestamp-number-videoid,
  .video-timestamp-description-videoid {
    font-size: 0.9rem;
  }

  .stat-icon-videoid,
  .stat-icon-top-videoid {
    width: 25px;
    height: 25px;
  }

  .video-suggestions-container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: none;
  }

  .video-suggestions-icon img {
    width: 70px;
  }

  .video-suggestions-start-btn {
    font-size: 0.8rem;
  }

  .video-suggestions-pagination {
    font-size: 0.8rem;
  }

  .pagination-arrowbox {
    margin: 0 5px;
  }

  .video-stats-container-videoid {
    display: none;
  }
  .help-tooltip-videoid {
    width: 230px;
    font-size: 12px;
  }


  .reload-data-button {
    padding: 10px 14px;
    font-size: 15px;
  }

  .reload-logo {
    width: 20px;
    height: 20px;
  }

  .reload-data-container {
    justify-content: center; /* Center the button */
    margin-bottom: 25px;
  }
  .help-icon-container-reload-videoid {
    padding: 6px;

  
  }
  .help-icon-rload-videoid {
    width: 25px;
    height: 25px;
  }


}



/* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1280px) {
  .dashboard-content-wrapper-videoid {
    flex-direction: column;
    gap: 30px;
    padding: 50px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: -50px;
  }

  .dashboard-left-videoid,
  .dashboard-center-videoid,
  .dashboard-right-videoid {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .addvalueBump-left-videoid,
  .addvalueBump-center-videoid,
  .addvalueBump-right-videoid {
    width: 100%;
    box-sizing: border-box;
  }
  

  .video-summary-box-videoid,
  .key-takeaways-box-videoid,
  .video-timestamps-box-videoid,
  .video-suggestions-container,
  .video-tags-box {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .video-tags-box {
    margin-top: -10px;
  }

  .done-videodetails-content {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .done-videodetails-header {
    margin-top: 0;
    padding-top: 0;
  }

  .done-videodetails-action-btn {
    font-size: 0.9rem;
  }

  .video-summary-title-videoid,
  .key-takeaways-title-videoid,
  .video-timestamps-title-videoid,
  .video-tags-title,
  .video-stat-title-videoid {
    font-size: 1.3rem;
  }

  .video-summary-content-videoid,
  .key-takeaways-content-videoid,
  .video-timestamps-content-videoid,
  .video-tags-content {
    gap: 10px;
  }

  .key-takeaway-emoji-videoid {
    font-size: 1.8em;
    margin-right: 12px;
  }

  .video-timestamp-number-videoid,
  .video-timestamp-description-videoid {
    font-size: 1rem;
  }

  .stat-icon-videoid,
  .stat-icon-top-videoid {
    width: 30px;
    height: 30px;
  }

  .video-suggestions-container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: none;
  }

  .video-suggestions-icon img {
    width: 80px;
  }

  .video-suggestions-start-btn {
    font-size: 0.9rem;
  }

  .video-suggestions-pagination {
    font-size: 0.9rem;
  }

  .pagination-arrowbox {
    margin: 0 7px;
  }

  .video-stats-container-videoid {
    display: none;
  }

  .reload-data-container {
    justify-content: center; /* Center the button */
    margin-bottom: 60px;
  }
  

  .help-icon-container-reload-videoid {
    padding: 8px;

  
  }

  .help-icon-rload-videoid {
    width: 30px;
    height: 30px;
  }
  .reload-data-button {
    padding: 10px 14px;
    font-size: 17px;
  }

}

/* Medium-Large Screens Optimization */
@media (min-width: 1281px) and (max-width: 1800px) {
  .dashboard-content-wrapper-videoid {
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .video-stats-container-videoid {
    display: none;
  }
  
  .dashboard-left-videoid,
  .dashboard-center-videoid,
  .dashboard-right-videoid {
    padding: 15px;
    width: 32%;
    box-sizing: border-box;
  }

  .addvalueBump-left-videoid,
  .addvalueBump-center-videoid,
  .addvalueBump-right-videoid {
    width: 100%;
    box-sizing: border-box;
  }
  

  .video-summary-box-videoid,
  .key-takeaways-box-videoid,
  .video-timestamps-box-videoid,
  .video-tags-box,
  .video-suggestions-container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .done-videodetails-content {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .done-videodetails-title {
    font-size: 1.1rem;
  }

  .done-videodetails-action-btn {
    font-size: 0.9rem;
  }

  .video-summary-title-videoid,
  .key-takeaways-title-videoid,
  .video-timestamps-title-videoid,
  .video-tags-title {
    font-size: 1.1rem;
  }

  .video-stat-title-videoid {
    font-size: 1.1rem;

    
  }

  .video-summary-content-videoid,
  .key-takeaways-content-videoid,
  .video-timestamps-content-videoid,
  .video-tags-content {
    gap: 8px;
  }

  .key-takeaway-emoji-videoid {
    font-size: 1.6em;
    margin-right: 10px;
  }

  .video-timestamp-number-videoid,
  .video-timestamp-description-videoid {
    font-size: 0.95rem;
  }

  .stat-icon-videoid,
  .stat-icon-top-videoid {
    width: 32px;
    height: 32px;
  }

  .stat-box-videoid {
    padding: 8px 10px;
    font-size: 14px;
  }
  

  .video-suggestions-container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .video-suggestions-icon img {
    width: 75px;
  }

  .video-suggestions-start-btn {
    font-size: 0.85rem;
  }

  .video-suggestions-pagination {
    font-size: 0.85rem;
  }

  .pagination-arrowbox {
    margin: 0 6px;
  }

  .key-takeaways-box-videoid .key-takeaway-text-videoid,
  .video-summary-box-videoid .video-summary-videoid {
    font-size: 0.75rem;
  }

  .help-tooltip-videoid {
    width: 230px;
    font-size: 12px;
  }

  .reload-data-button {
    font-size: 16px;
  }

  .reload-logo {
    width: 20px;
    height: 20px;
  }

  .video-stats-videoid {
    gap: 20px;
 
  }

  .help-icon-container-reload-videoid {
    padding: 8px;

  
  }

  .help-icon-rload-videoid {
    width: 25px;
    height: 25px;
  }

}

