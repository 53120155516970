.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.plan-popup-wrapper {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.outoftime-popup-container {
  background-color: #1e1e1e; /* Dark background similar to YouTube dark theme */
  color: #ffffff;
  border-radius: 10px;
  padding: 18px;
  width: 400px;
  border: 1px solid #3e3e3e;
}

.close-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.add-more-time-btn {
  padding: 15px 25px;
  background-color: #0e0e0e; /* Bright, noticeable green color */
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.0rem;
  width: 100%; /* Full-width for better mobile responsiveness */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-decoration: none; /* Avoids text underline on hover */
  border: 1px solid #3e3e3e;
}

.add-more-time-btn:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Lighter shade on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}

.popup-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff; /* White text */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the title and image */
  margin-top: 5px; /* Reduce the space above the title */
}

.clock-logo {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  margin-right: 4px; /* Space between the clock image and text */
}

.popup-subtitle {
  font-size: 16px;
  margin-bottom: 14px;
  color: #ffffff; /* White text */
  line-height: 1.6;
  text-align: center; /* Center the subtitle text */
}
